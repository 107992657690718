import { Outlet, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { RoutePath } from '../../services/route.service';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { useGetUserQuery } from '../../contexts/authorizarion.service';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../services/errors.service.types';

export const RequireAuth = ({ children }: { children?: JSX.Element }) => {
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);

  const { checkUserAccess } = useUserAccessContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { UserGroups } = { UserGroups: [] },
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGetUserQuery();

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        if (data && !isGroupsLoading) {
          if (groupsError) {
            !isSnackbarShown &&
              enqueueSnackbar(ErrorMessages.AuthenticationError, {
                variant: 'error',
                persist: true,
              });
            setIsSnackbarShown(true);
            setCheckingAuth(false);
          } else
            checkUserAccess(UserGroups).then(() => {
              setCheckingAuth(false);
            });
        }
      })
      .catch(() => {
        navigate(RoutePath.LOGIN);
      });
  }, [
    isSnackbarShown,
    navigate,
    checkUserAccess,
    UserGroups,
    isGroupsLoading,
    groupsError,
    enqueueSnackbar,
  ]);

  if (checkingAuth || isGroupsLoading) {
    return (
      <Backdrop sx={{ color: '#fff' }} open={true}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  } else {
    return children ? children : <Outlet />;
  }
};
