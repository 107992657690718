import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Grid, Modal, Tab, Tabs } from '@mui/material';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { useGetClauseDictionariesQuery } from '../../../store/files/upload/list.service';
import { useGetCommentsQuery } from '../../../store/files/comments/comments.service';
import { DocumentContextMenuButton } from '../../Documents/DocumentContextMenu/DocumentContextMenuButton';
import { Compare } from '../../Documents/DocumentViewModal/Compare';
import { ClauseMetadataPanel } from './ClauseMetadataPanel';
import { DocumentInfoHeader } from '../../StaticComponents/DocumentInfoHeader/DocumentInfoHeader';
import { LanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { ReactComponent as BookmarkIcon } from '../../../assets/icons/bookmark.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clauseSelector } from '../../../store/files/clauses/clauses.selectors';
import { closeClauseViewModal } from '../../../store/files/clauses/clauses.slice';
import { useLanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.hook';
import './ClauseMetadataPanel.scss';
import { MinimizeItem } from '../../StaticComponents/Modals/MinimizeItem/MinimizeItem';
import { handleCloseItemViewModal } from '../../../store/ui/ui.helpers';
import { ClauseViewer } from './ClauseViewer';
import { MINIMIZED_DATA } from '../../../store/ui/ui.types';
import { openBookmarkModal } from '../../../store/files/bookmarks/bookmarks.slice';
import { DocumentStatus } from '../../../store/files/documents/documents.list.types';
import { useContextMenu } from '../../Documents/DocumentContextMenu/DocumentContextMenu.hooks';
import { DocumentContextMenu } from '../../Documents/DocumentContextMenu/DocumentContextMenu';
import {
  MenuItemsClauseReportedViewModal,
  MenuItemsClauseViewModal,
} from '../../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { Clause } from '../../../store/files/clauses/clauses.list.types';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from '../../../services/route.service';
import { ClauseViewModalProps } from '../Clauses.types';
import { Comments as CommentsList } from '../../Comments/Comments';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { useDocumentVersionsData } from './ClauseViewModal.hooks';
import { useClauseRelatedDocuments } from '../../Documents/DocumentViewModal/DocumentRelatedClauses/ClauseRelatedDocuments.hooks';
import { getLastNotEmptyQuery } from '../../Documents/DocumentViewModal/DocumentViewModal.helpers';
import { useGetSimilarQuery } from '../../../store/files/documents/documents.list.service';
import { getColumnsVersions } from '../../Documents/DocumentViewModal/DocumentTabItems/DocumentTabItems.helpers';
import { DocumentTabItems } from '../../Documents/DocumentViewModal/DocumentTabItems/DocumentTabItems';

export const ClauseViewModal = ({ useGetDataListWithParams }: ClauseViewModalProps) => {
  const [minimize, setMinimize] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { control, handleContextMenu } = useContextMenu<Clause>();
  const clause = useAppSelector(clauseSelector.selectModalClauseView);
  const { filters } = useGetDataListWithParams();
  const { SearchQuery, OriginalLanguage, SearchMethod } = getLastNotEmptyQuery(filters.Queries);
  const [language, setLanguage] = useLanguageToggleSwitch(
    clause?.ClauseId,
    false,
    SearchQuery,
    OriginalLanguage
  );
  const { pathname } = useLocation();
  const reported = pathname.includes(RoutePath.REPORTED_CLAUSES);
  const clauseContextMenuItems = reported
    ? MenuItemsClauseReportedViewModal
    : MenuItemsClauseViewModal;

  const { data: dictionaries } = useGetClauseDictionariesQuery({ AccessMode: AccessMode.READ });
  const {
    data: { Comments = [] } = {},
    isFetching,
    refetch,
  } = useGetCommentsQuery(
    { itemType: 'clause', id: clause?.ClauseId ?? '' },
    {
      skip: !clause?.ClauseId,
    }
  );

  const { documents, readAccessDictionaries } = useDocumentVersionsData(clause?.ClauseId || '');

  const { data } = useGetSimilarQuery(clause?.ClauseId ?? '', { skip: !clause?.ClauseId });

  useEffect(() => {
    refetch();
  }, [clause?.ClauseId, refetch]);

  const handleClose = () => {
    id && navigate(RoutePath.CLAUSES);
    handleCloseItemViewModal(dispatch, clause);
  };

  const handleCloseModalOnly = () => {
    dispatch(closeClauseViewModal());
  };

  const onMinimize = () => {
    id && navigate(RoutePath.CLAUSES);
    dispatch(closeClauseViewModal());
  };

  const handleContextMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    handleContextMenu(event, clause!);
  };

  const handleBookmarkClick = () => {
    if (clause) {
      dispatch(openBookmarkModal({ bookmark: clause }));
    }
  };

  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  if (!dictionaries || !readAccessDictionaries || !clause) {
    if (tabValue !== 0) {
      setTabValue(0);
    }
    return null;
  }

  return (
    <Modal
      open={!!clause?.ClauseId}
      onClose={handleCloseModalOnly}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <div className={`metadata-edit-modal document-view-modal ${minimize ? 'minimize-box' : ''}`}>
        <Grid container>
          <Grid item xs className='document-view'>
            <div className='document-view-modal__top-left-bar'>
              <LanguageToggleSwitch value={language} onChange={setLanguage} />
              <div className='document-view-menu'>
                <Compare
                  documentId={clause.ClauseId}
                  name={clause.ClauseName}
                  dataType={MINIMIZED_DATA.MINIMIZED_CLAUSES}
                />
                <Button
                  disabled={reported}
                  onClick={handleBookmarkClick}
                  variant='text'
                  startIcon={<BookmarkIcon className='document-view-icons' />}
                >
                  Bookmark
                </Button>
                <DocumentContextMenuButton
                  disabled={
                    clause?.Status === DocumentStatus.DELETING || !clauseContextMenuItems.length
                  }
                  onClick={handleContextMenuOpen}
                />
              </div>
            </div>
            <div className='clause-view-modal-text'>
              <ClauseViewer
                clauseId={clause.ClauseId}
                language={language}
                keyword={SearchQuery}
                original={OriginalLanguage}
                method={SearchMethod}
              />
            </div>
          </Grid>
          <Grid item className='form-view'>
            <div className='document-view-modal__top-right-bar'>
              <div className='document-view-header'>
                <Tabs value={tabValue} onChange={handleChange} className='document-view-tabs'>
                  <Tab data-test-id={TEST_ID.CLAUSE_VIEW_MODAL.INFO_TAB} value={0} label='Info' />
                  <Tab
                    data-test-id={TEST_ID.CLAUSE_VIEW_MODAL.DOCUMENTS_TAB}
                    value={1}
                    label={`Documents (${documents?.TotalDocumentCount || 0})`}
                    disabled={!documents?.TotalDocumentCount}
                  />
                  <Tab
                    data-test-id={TEST_ID.CLAUSE_VIEW_MODAL.SIMILAR_TAB}
                    value={2}
                    label={`Similar (${data?.TotalDocumentCount! || 0})`}
                    disabled={!data?.TotalDocumentCount}
                  />
                  <Tab
                    data-test-id={TEST_ID.CLAUSE_VIEW_MODAL.COMMENTS_TAB}
                    value={3}
                    label={!Comments?.length ? 'Comments' : `Comments (${Comments?.length})`}
                  />
                </Tabs>
              </div>

              <MinimizeItem item={clause} onMinimize={onMinimize} setMinimize={setMinimize} />

              <ModalClose onClose={handleClose} />
            </div>
            <TabContext value={String(tabValue)}>
              <TabPanel value='0'>
                <DocumentInfoHeader clause={clause} />
                <ClauseMetadataPanel dictionaries={dictionaries} clause={clause} />
              </TabPanel>
              <TabPanel value='1'>
                <DocumentTabItems
                  items={documents?.Documents ?? []}
                  dictionaries={readAccessDictionaries}
                  documentHook={useClauseRelatedDocuments}
                  columnFn={getColumnsVersions}
                />
              </TabPanel>
              <TabPanel value='2'>
                <DocumentTabItems
                  items={data?.Documents ?? []}
                  dictionaries={readAccessDictionaries}
                  documentHook={useClauseRelatedDocuments}
                  columnFn={getColumnsVersions}
                />
              </TabPanel>
              <TabPanel value='3'>
                <CommentsList
                  comments={Comments ?? []}
                  itemId={clause?.ClauseId}
                  itemType='clause'
                  isDataFetching={isFetching}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
        <DocumentContextMenu control={control} documentContextMenuItems={clauseContextMenuItems} />
      </div>
    </Modal>
  );
};
