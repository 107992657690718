import { useDocumentReportedAccess, useDocumentWriteAccess } from '../../contexts/UserAccess';
import {
  useGetReportedDocumentsListWithParams,
  useGetUploadedDocumentsListWithParams,
} from '../../store/files/documents/documents.hooks';
import { RoutePath } from '../../services/route.service';
import {
  MenuItemsReportedDocuments,
  MenuItemsUploadedDocuments,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';

export const UPLOADED_DOCS_CONFIG = {
  useAccess: useDocumentWriteAccess,
  useGetDataListWithParams: useGetUploadedDocumentsListWithParams,
  title: 'Uploaded documents',
  basePath: RoutePath.UPLOADED_DOCUMENTS,
  menuOptions: MenuItemsUploadedDocuments,
  disablePending: false,
};

export const REPORTED_DOCS_CONFIG = {
  useAccess: useDocumentReportedAccess,
  useGetDataListWithParams: useGetReportedDocumentsListWithParams,
  title: 'Reported documents',
  basePath: RoutePath.REPORTED_DOCUMENTS,
  menuOptions: MenuItemsReportedDocuments,
  disablePending: true,
};
