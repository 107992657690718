import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import './ClauseCompareModal.scss';
import { Backdrop } from '@mui/material';
import { useGetClauseDictionariesQuery } from '../../../store/files/upload/list.service';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { closeCompareModal } from '../../../store/ui/ui.slice';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { isClauseResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import { CompareOptionsBar } from './CompareOptionsBar/CompareOptionsBar';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import { swapItems } from '../../Documents/DocumentCompareModal/DocumentCompareModal.helpers';
import { DroppableClauseComparePanel } from '../../Documents/DocumentCompareModal/DocumentComparePanel/DroppableClauseComparePanel';

export const ClauseCompareModal = () => {
  const dispatch = useAppDispatch();
  const { data: dictionaries } = useGetClauseDictionariesQuery({ AccessMode: AccessMode.READ });

  const comparisonItems = useAppSelector(uiSelectors.selectModalCompare);
  const [comparedClauses, setComparedClauses] = useState<ClauseResponse[]>([]);

  useEffect(() => {
    setComparedClauses(comparisonItems?.filter(isClauseResponse) ?? []);
  }, [comparisonItems]);

  const handleClose = () => {
    dispatch(closeCompareModal());
  };

  const handleDrop = useCallback(
    (from: number, to: number) => {
      setComparedClauses(swapItems(comparedClauses, from, to));
    },
    [comparedClauses]
  );

  if (!dictionaries || !comparisonItems?.length) {
    return null;
  }

  return (
    <Modal
      open={!!comparisonItems.length}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
      classes={{
        root: 'compare-modal-root',
      }}
    >
      <div className='metadata-edit-modal compare-modal compare-modal--caluse'>
        <ModalClose onClose={handleClose} />
        <Grid container className={`clause-compare-wrap-${comparisonItems.length}`}>
          {comparedClauses.map((item, index) => (
            <DroppableClauseComparePanel
              clauseDictionaries={dictionaries}
              clause={item}
              key={item.ClauseId}
              index={index}
              onDrop={handleDrop}
            />
          ))}
        </Grid>
        <CompareOptionsBar />
      </div>
    </Modal>
  );
};
