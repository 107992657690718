import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import './UploadClause.scss';
import { Controller, useForm } from 'react-hook-form';
import { CustomTextField, requiredRule } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  ClauseMetadata,
  ClauseMetadataFields,
  UploadClauseLocationState,
} from './UploadClause.types';
import { LoadingButton } from '@mui/lab';
import { MAX_WORDING_LENGTH } from '../../config/config';
import { useGetClauseDictionariesQuery } from '../../store/files/upload/list.service';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadSubmitClauses } from './UploadSubmitClauses/UploadSubmitClauses';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useCreateClauseMutation } from '../../store/files/clauses/clauses.list.service';
import { getFirstParamNameFromError, handleApiError } from '../../store/error.helpers';
import { useClauseWriteAccess } from '../../contexts/UserAccess';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { documentsSelectors } from '../../store/files/documents/documents.selectors';
import { clearSelectedText } from '../../store/files/documents/documents.slice';
import { ClauseMetadataForm } from '../UploadFiles/MetadataForm/ClauseMetadataForm';
import { CreateClauseRequest } from '../../store/files/clauses/clauses.list.types';
import { ClauseStatusIcon } from '../Clauses/ClauseStatusIcon/ClauseStatusIcon';

export const UploadClause = () => {
  const dispatch = useAppDispatch();
  useClauseWriteAccess();
  const { data: dictionaries } = useGetClauseDictionariesQuery({ AccessMode: AccessMode.WRITE });
  const [create, { isLoading }] = useCreateClauseMutation();
  const [inputNameError, setInputNameError] = useState<string | undefined>(undefined);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [localSelectedText, setLocalSelectedText] = useState('');

  const location = useLocation();
  const document = (location.state as UploadClauseLocationState)?.document;
  const shouldPrefillForm = !!document;
  const selectedTextFromStore: string = useAppSelector(documentsSelectors.selectSelectedText);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const {
    control,
    watch,
    trigger,
    formState: { isValid },
    setValue,
    getValues,
    getFieldState,
    reset,
  } = useForm<ClauseMetadata>({
    mode: 'onChange',
  });

  const formMethods = {
    control,
    watch,
    trigger,
    setValue,
    getValues,
    getFieldState,
    reset,
  };

  useEffect(() => {
    if (selectedTextFromStore && !localSelectedText) {
      setLocalSelectedText(selectedTextFromStore);
      dispatch(clearSelectedText());
    }
  }, [selectedTextFromStore, localSelectedText, dispatch]);

  useEffect(() => {
    if (shouldPrefillForm) {
      setValue(ClauseMetadataFields.Entities, [document.Entity]);
      setValue(ClauseMetadataFields.Lops, [document.Lop]);
      setValue(ClauseMetadataFields.Lobs, [document.Lob]);
      setValue(ClauseMetadataFields.BusinessTypes, [document.BusinessType]);
      setValue(ClauseMetadataFields.Classification, document.Classification);
      setValue(ClauseMetadataFields.Language, document.Language);
      setValue(ClauseMetadataFields.Content, localSelectedText);
    } else {
      setValue(ClauseMetadataFields.Lops, []);
      setValue(ClauseMetadataFields.Lobs, []);
      setValue(ClauseMetadataFields.BusinessTypes, []);
      setValue(ClauseMetadataFields.Classification, '');
      setValue(ClauseMetadataFields.Language, '');
      setValue(ClauseMetadataFields.Content, '');
      trigger();
    }
  }, [shouldPrefillForm, setValue, document, localSelectedText, trigger]);

  const handleSubmit = async (e: FormEvent) => {
    const body: CreateClauseRequest = { ...getValues(), RelatedDocumentId: document?.DocumentId };
    e?.preventDefault();
    setIsError(false);
    create(body)
      .unwrap()
      .catch((err) => {
        const paramName = getFirstParamNameFromError(err.response?.data);
        if (paramName) {
          setInputNameError(paramName);
        }
        setApiErrorMessage(handleApiError(err, true));
        setIsError(true);
      })
      .finally(() => {
        reset(body);
        trigger();
        setOpen(true);
      });
  };

  const { ClauseStatus } = getValues();

  if (!dictionaries)
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Paper elevation={0} className='upload-clause'>
        <div className='upload-clause___header'>Clause upload</div>
        <form className='metadata-form' onSubmit={handleSubmit}>
          <div className='upload-clause___wording'>
            <div className='upload-clause___title'>Clause wording</div>
            <div className='upload-clause___description'>Input the clause wording below</div>
            <Controller
              control={control}
              name={ClauseMetadataFields.Content}
              rules={requiredRule(!shouldPrefillForm, true)}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  inputProps={{ maxLength: MAX_WORDING_LENGTH }}
                  {...field}
                  multiline
                  helperWarning={error}
                  label=''
                  hasCounter
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder='Input text here'
                  disabled={shouldPrefillForm}
                />
              )}
            />
          </div>
          <div className='upload-clause___info'>
            <ClauseStatusIcon clauseStatus={ClauseStatus} />
            <div className='upload-clause___title'>Clause info</div>
            <div className='upload-clause___description'>Fill in the clause information below</div>
            <ClauseMetadataForm
              formMethods={formMethods}
              apiErrorMessage={apiErrorMessage}
              inputNameError={inputNameError}
              baseDocumentForClauseCreation={document}
              disableTags
            />
            <div className='upload-clause___buttons'>
              <Button className='cancel-button' onClick={goBack}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!isValid}
                variant='contained'
                size='large'
                type='submit'
                loading={isLoading}
              >
                Upload
              </LoadingButton>
            </div>
          </div>
        </form>
      </Paper>
      <UploadSubmitClauses
        open={open}
        setOpen={setOpen}
        isError={isError}
        error={apiErrorMessage}
      />
    </>
  );
};
