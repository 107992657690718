import { useDispatch } from 'react-redux';
import { clearSelectedText, setSelectedText } from '../../../store/files/documents/documents.slice';

export const useTextSelectionDispatch = () => {
  const dispatch = useDispatch();

  const handleDispatchTextSelection = (selectionObj: Selection | null) => {
    const text = selectionObj?.toString().replaceAll('-\n', '').replaceAll('\n', ' ');
    if (text) {
      dispatch(setSelectedText(text));
    } else {
      dispatch(clearSelectedText());
    }
  };

  return { handleTextSelectionDispatch: handleDispatchTextSelection };
};
