import { Header } from '../Header/Header';
import { ErrorModal } from '../StaticComponents/ErrorModal/ErrorModal';
import { Loader } from '../StaticComponents/Loader/Loader';
import { Container } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../Auth/RequireAuth';
import { getChildPath, getRoutePath, RoutePath } from '../../services/route.service';
import { UploadFiles } from '../UploadFiles/UploadFiles';
import { Documents } from '../Documents/Documents';
import { UploadedDocuments } from '../MyFiles/UploadedDocuments';
import React from 'react';
import { NotFound } from '../NotFound/NotFound';
import { DocumentBar } from '../DocumentBar/DocumentBar';
import { ClauseBar } from '../Clauses/ClauseBar';
import NoAccess from '../NoAccess/NoAccess';
import { UserAccessProvider } from '../../contexts/UserAccess';
import { MainPageRedirect } from './MainPageRedirect';
import { Settings } from '../Settings/Settings';
import { AdminPanel } from '../AdminPanel/AdminPanel';
import { UploadClause } from '../UploadClause/UploadClause';
import { UploadedClauses } from '../MyFiles/UploadedClauses';
import { Clauses } from '../Clauses/Clauses';
import { Bookmarks } from '../Bookmarks/Bookmarks';
import { BookmarkDetails } from '../Bookmarks/BookmarkDetails';
import { Reports } from '../Reports/Reports';
import { BookmarksModal } from '../Bookmarks/BookmarksModal';
import { ReportedItemModal } from '../DocumentsAndClauses/ReportFile/ReportItemModal';
import { REPORTED_CLAUSES_CONFIG } from '../MyFiles/UploadedClauses.helpers';
import { REPORTED_DOCS_CONFIG } from '../MyFiles/UploadedDocuments.helpers';

export const UserRouter = () => {
  return (
    <>
      <ErrorModal />
      <Loader />
      <UserAccessProvider>
        <Header />
        <Container maxWidth='xl'>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route index element={<MainPageRedirect />} />
              <Route path={RoutePath.DOCUMENTS} element={<Documents />} />
              <Route
                path={getRoutePath(RoutePath.DOCUMENTS, RoutePath.ID)}
                element={<Documents />}
              />
              <Route path={RoutePath.CLAUSES} element={<Clauses />} />
              <Route path={getRoutePath(RoutePath.CLAUSES, RoutePath.ID)} element={<Clauses />} />
              <Route path={RoutePath.BOOKMARKS} element={<Bookmarks />} />
              <Route
                path={getChildPath(
                  getRoutePath(RoutePath.BOOKMARKS, RoutePath.BOOKMARK_ID) as RoutePath
                )}
                element={<BookmarkDetails />}
              />
              <Route path={getChildPath(RoutePath.REPORTS)} element={<Reports />} />
              <Route
                path={getChildPath(RoutePath.UPLOADED_DOCUMENTS)}
                element={<UploadedDocuments />}
              />
              <Route
                path={getChildPath(RoutePath.UPLOADED_CLAUSES)}
                element={<UploadedClauses />}
              />
              <Route
                path={getChildPath(RoutePath.REPORTED_DOCUMENTS)}
                element={<UploadedDocuments config={REPORTED_DOCS_CONFIG} />}
              />
              <Route
                path={getChildPath(RoutePath.REPORTED_CLAUSES)}
                element={<UploadedClauses config={REPORTED_CLAUSES_CONFIG} />}
              />
              <Route path={getChildPath(RoutePath.UPLOAD_FILES)} element={<UploadFiles />} />
              <Route path={RoutePath.UPLOAD_CLAUSE} element={<UploadClause />} />
              <Route path={getChildPath(RoutePath.ADMIN_PANEL)} element={<AdminPanel />} />
              <Route path={getChildPath(RoutePath.SETTINGS)} element={<Settings />} />
              <Route path={RoutePath.NO_ACCESS} element={<NoAccess />} />
              <Route path={RoutePath.NO_WRITE_ACCESS} element={<NoAccess write />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
          <ClauseBar />
          <DocumentBar />
          <BookmarksModal />
          <ReportedItemModal />
        </Container>
      </UserAccessProvider>
    </>
  );
};
