import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UploadedFileAction,
  UploadedFile,
  UploadedFileStatus,
} from '../../../components/UploadFiles/UploadedFileList.types';
import UploadService from './upload.service';
import { listApi } from './list.service';
import { v4 as uuidv4 } from 'uuid';
import { mapUploadedFileEdit, mapUploadedFileAction, mapUploadedFileType } from './upload.helpers';
import { showLoader, showModal } from '../../ui/ui.slice';
import { handleResponseError } from '../../error.helpers';
import { LIST_API_TAGS } from './list.service.types';

export interface UploadState {
  docs: Record<UploadedFile['DocumentId'], UploadedFile>;
  failedFiles: string[];
  metadataEditModal?: UploadedFile['DocumentId'];
  selectedDocs: UploadedFile['DocumentId'][];
}

const initialState: UploadState = {
  docs: {},
  failedFiles: [],
  metadataEditModal: undefined,
  selectedDocs: [],
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    addFile: (
      state: UploadState,
      action: PayloadAction<{ name: string; id: UploadedFile['DocumentId'] }>
    ) => {
      state.docs[action.payload.id] = {
        fileType: mapUploadedFileType(action.payload.name),
        id: action.payload.id,
        DocumentId: action.payload.id,
        key: null,
        FileName: action.payload.name,
        action: UploadedFileAction.NONE,
        Status: UploadedFileStatus.UPLOADING,
        edit: false,
      };
    },
    removeFile: (state: UploadState, action: PayloadAction<{ id: UploadedFile['DocumentId'] }>) => {
      delete state.docs[action.payload.id];
    },
    setUploadProgress: (
      state: UploadState,
      action: PayloadAction<{ id: UploadedFile['DocumentId']; progress: number }>
    ) => {
      state.docs[action.payload.id].progress = action.payload.progress;
      state.docs[action.payload.id].action = UploadedFileAction.STOP;
    },
    setFileKey: (
      state: UploadState,
      action: PayloadAction<{
        id: UploadedFile['DocumentId'];
        key: UploadedFile['key'];
        pid: UploadedFile['DocumentId'];
      }>
    ) => {
      state.docs[action.payload.id].key = action.payload.key;
      state.docs[action.payload.id].DocumentId = action.payload.pid;
    },
    setStatus: (
      state: UploadState,
      action: PayloadAction<{ id: UploadedFile['DocumentId']; status: UploadedFileStatus }>
    ) => {
      state.docs[action.payload.id].Status = action.payload.status;
      state.docs[action.payload.id].edit = mapUploadedFileEdit(action.payload.status);
      state.docs[action.payload.id].action = mapUploadedFileAction(action.payload.status);
    },
    addFailed: (state: UploadState, action: PayloadAction<string>) => {
      state.failedFiles.push(action.payload);
    },
    clearFailed: (state: UploadState) => {
      state.failedFiles = [];
    },
    openModal: (state: UploadState, action: PayloadAction<{ id: UploadedFile['DocumentId'] }>) => {
      state.metadataEditModal = action.payload.id;
    },
    closeModal: (state: UploadState) => {
      state.metadataEditModal = undefined;
    },
    updateSelection: (
      state: UploadState,
      action: PayloadAction<{ selected: UploadedFile['DocumentId'][] }>
    ) => {
      state.selectedDocs = action.payload.selected;
    },
    removeSelectedDocs: (state: UploadState) => {
      state.selectedDocs = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(listApi.endpoints.getList.matchFulfilled, (state, { payload }) => {
      const docIds = payload?.map(({ DocumentId }) => DocumentId) || [];
      Object.values(state.docs).forEach(({ id, DocumentId }) => {
        if (docIds.includes(DocumentId)) {
          delete state.docs[id];
        }
      });
    });
  },
});

export const uploadFile = createAsyncThunk(
  'upload/uploadFile',
  async (file: File, { dispatch }) => {
    const fileId = uuidv4();
    dispatch(addFile({ id: fileId, name: file.name }));
    let documentId = null;
    try {
      const { data } = await UploadService.createSignedUrl(file.name);
      documentId = data.document_id;
      dispatch(setFileKey({ id: fileId, key: data.fields.key, pid: documentId }));

      const updateProgress = (progress: number) => {
        dispatch(setUploadProgress({ id: fileId, progress }));
      };
      await UploadService.uploadFile(fileId, data, file, updateProgress);
    } catch {
      dispatch(setStatus({ id: fileId, status: UploadedFileStatus.UPLOAD_FAILED }));
      dispatch(addFailed(file.name));

      if (documentId) {
        dispatch(listApi.endpoints.deleteFile.initiate(documentId));
      }
    }
  }
);

export const downloadMetadataCsv = createAsyncThunk('metadata/download/csv', async () => {
  await UploadService.downloadMetadataCsv();
});

export const uploadMetadataCsv = createAsyncThunk(
  'metadata/upload/csv',
  async (file: File, { dispatch }) => {
    dispatch(showLoader(true));
    UploadService.uploadMetadataCsv(file)
      .then(() => {
        dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        dispatch(
          showModal({
            title: 'Error while uploading CSV file',
            reason: handleResponseError(error),
          })
        );
      })
      .finally(() => {
        dispatch(showLoader(false));
      });
  }
);

export const cancelUpload = createAsyncThunk('users/cancelUpload', async (id: string) => {
  UploadService.cancelUpload(id);
});

export const {
  addFile,
  removeFile,
  setUploadProgress,
  setStatus,
  addFailed,
  clearFailed,
  setFileKey,
  openModal,
  closeModal,
  updateSelection,
  removeSelectedDocs,
} = uploadSlice.actions;

export default uploadSlice.reducer;
