import { useMinimizeItemForVersion } from '../../../StaticComponents/Modals/MinimizeItem/useMinimizeItem';

export const useDocumentTabItemsHook = () => {
  const { handleMinimize, checkIfItemMinimized, checkIfMinimizeDisabled, handleClose } =
    useMinimizeItemForVersion({});

  const toggleMinimize = (DocumentId: string, FileName: string) => {
    if (checkIfItemMinimized(DocumentId)) {
      handleClose(DocumentId);
    } else {
      handleMinimize(DocumentId, FileName);
    }
  };

  return { toggleMinimize, checkIfItemMinimized, checkIfMinimizeDisabled, handleClose };
};
