import React from 'react';
import { Chip } from '@mui/material';
import { AdminPanelUser } from '../../../store/adminPanel/adminPanel.types';
import './UsersListChip.scss';

export const UsersListChip: React.FC<{ selectedUsers?: AdminPanelUser[] }> = ({
  selectedUsers,
}) => {
  return (
    <div className='users-list'>
      {selectedUsers?.map((user) => (
        <Chip label={`${user.Name} ${user.FamilyName}`} key={user.Email} />
      ))}
    </div>
  );
};
