import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorModalProps, MinimizedData } from './ui.types';
import { getMinimizedDocs, getMinimizedClauses } from './ui.helpers';
import {
  addDocToCompare,
  addClauseToCompare,
  clearCompare,
  clearMinimizedDocs,
  clearMinimizedClauses,
  closeMinimizedDoc,
  closeMinimizedClause,
  removeDocFromCompare,
  saveMinimizedDocs,
  saveMinimizedClauses,
  removeClauseFromCompare,
  clearClauseCompare,
} from './ui.actions';
import { DocumentUpdateStatuses } from '../../components/SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { Clause } from '../files/clauses/clauses.list.types';
import { Document } from '../files/documents/documents.list.types';
import { COMPARE_MODE } from '../../components/Clauses/ClauseCompareModal/CompareOptionsBar/CompareOptionsBar.types';
import { LanguageToggleSwitchTypes } from '../../components/StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { Order } from '../../components/StaticComponents/FileViewer/useDocumentCompare';

export interface UIState {
  showLoader: boolean;
  errorModal: ErrorModalProps & {
    show: boolean;
  };
  uploadedDocuments: {
    selectedDocs: string[];
    updateStatuses: DocumentUpdateStatuses;
  };
  uploadFilesPopup: boolean;
  uploadedFiles: {
    unsavedChanges: boolean;
  };
  minimizedDocs: MinimizedData[];
  minimizedClauses: MinimizedData[];
  authError: string;
  compareModal?: (Clause | Document)[];
  reportedReasonModal?: Document | Clause;
  compareClauseReferences: {
    reference1?: {
      clauseText?: string;
    };
    reference2?: {
      clauseText?: string;
    };
  };
  compareDocumentReferences: {
    [key: string]: string;
    reference0: string;
    reference1: string;
    reference2: string;
  };
  compareOptions: {
    compareMode: COMPARE_MODE;
    isLanguageLocked: boolean;
    lockedLanguage: LanguageToggleSwitchTypes;
  };
}

const initialState: UIState = {
  showLoader: false,
  errorModal: {
    show: false,
    title: '',
    reason: '',
  },
  uploadedDocuments: {
    selectedDocs: [],
    updateStatuses: {},
  },
  uploadFilesPopup: false,
  uploadedFiles: {
    unsavedChanges: false,
  },
  minimizedDocs: getMinimizedDocs(),
  minimizedClauses: getMinimizedClauses(),
  authError: '',
  compareModal: undefined,
  reportedReasonModal: undefined,
  compareClauseReferences: {
    reference1: undefined,
    reference2: undefined,
  },
  compareOptions: {
    compareMode: COMPARE_MODE.SHOW_ORIGINAL,
    isLanguageLocked: true,
    lockedLanguage: LanguageToggleSwitchTypes.Original,
  },
  compareDocumentReferences: {
    reference0: '',
    reference1: '',
    reference2: '',
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showLoader: (state: UIState, { payload: showLoader }: PayloadAction<boolean>) => {
      state.showLoader = showLoader;
    },
    showModal: (
      state: UIState,
      { payload: { title, reason, label, closeAction } }: PayloadAction<ErrorModalProps>
    ) => {
      state.errorModal.show = true;
      state.errorModal.title = title;
      state.errorModal.reason = reason;
      state.errorModal.label = label;
      state.errorModal.closeAction = closeAction;
    },
    closeModal: (state: UIState) => {
      state.errorModal.show = false;
      state.errorModal.title = '';
      state.errorModal.reason = '';
      state.errorModal.label = undefined;
      state.errorModal.closeAction = undefined;
    },
    showUploadFilesPopup: (state: UIState) => {
      state.uploadFilesPopup = true;
    },
    closeUploadFilesPopup: (state: UIState) => {
      state.uploadFilesPopup = false;
    },
    selectDocs: (state: UIState, { payload: documentIds }: PayloadAction<string[]>) => {
      state.uploadedDocuments.selectedDocs = documentIds;
    },
    updateStatuses: (
      state: UIState,
      { payload: statuses }: PayloadAction<DocumentUpdateStatuses>
    ) => {
      state.uploadedDocuments.updateStatuses = statuses;
    },
    setAuthError: (state: UIState, { payload }: PayloadAction<string>) => {
      state.authError = payload;
    },
    unsavedChanges: (state: UIState, { payload: unsavedChanges }: PayloadAction<boolean>) => {
      state.uploadedFiles.unsavedChanges = unsavedChanges;
    },
    openCompareModal: (
      state: UIState,
      action: PayloadAction<{ documents: Document[] | Clause[] }>
    ) => {
      state.compareModal = action.payload.documents;
    },
    closeCompareModal: (state: UIState) => {
      state.compareModal = undefined;
    },
    openReportedReasonModal: (state: UIState, { payload }: PayloadAction<Document | Clause>) => {
      state.reportedReasonModal = payload;
    },
    closeReportedReasonModal: (state: UIState) => {
      state.reportedReasonModal = undefined;
    },
    setCompareClauseReferences: (
      state: UIState,
      { payload }: PayloadAction<{ clauseText: string; referenceNumber: 0 | 1 }>
    ) => {
      const { referenceNumber, clauseText } = payload;
      const referenceKey = referenceNumber === 0 ? 'reference1' : 'reference2';

      if (!state.compareClauseReferences) {
        state.compareClauseReferences = {};
      }

      if (!state.compareClauseReferences[referenceKey]) {
        state.compareClauseReferences[referenceKey] = {};
      }

      state.compareClauseReferences[referenceKey]!.clauseText = clauseText;
    },
    setCompareDocumentReferences: (
      state: UIState,
      {
        payload,
      }: PayloadAction<{
        text: string;
        order: Order;
      }>
    ) => {
      const { order, text } = payload;
      const referenceKey = `reference${order}`;

      if (state.compareDocumentReferences[referenceKey] === text) return;
      state.compareDocumentReferences[referenceKey] = text;
    },
    setCompareMode: (state: UIState, { payload }: PayloadAction<COMPARE_MODE>) => {
      state.compareOptions.compareMode = payload;
    },
    setLanguageLock: (state: UIState, { payload }: PayloadAction<boolean>) => {
      state.compareOptions.isLanguageLocked = payload;
    },
    setLockedLanguage: (state: UIState, { payload }: PayloadAction<LanguageToggleSwitchTypes>) => {
      state.compareOptions.lockedLanguage = payload;
    },
    resetCompareSettings: (
      state: UIState,
      { payload }: PayloadAction<{ documentMode?: boolean }>
    ) => {
      const { documentMode } = payload;

      state.compareOptions.lockedLanguage = documentMode
        ? LanguageToggleSwitchTypes.PDF
        : LanguageToggleSwitchTypes.Original;
      state.compareOptions.isLanguageLocked = true;
      state.compareOptions.compareMode = COMPARE_MODE.SHOW_ORIGINAL;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(saveMinimizedDocs.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.minimizedDocs = action.payload;
        }
      })
      .addCase(saveMinimizedClauses.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.minimizedClauses = action.payload;
        }
      })
      .addCase(closeMinimizedDoc.fulfilled, (state, action) => {
        state.minimizedDocs = action.payload;
      })
      .addCase(closeMinimizedClause.fulfilled, (state, action) => {
        state.minimizedClauses = action.payload;
        state.compareOptions.compareMode = COMPARE_MODE.SHOW_ORIGINAL;
      })
      .addCase(addDocToCompare.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.minimizedDocs = action.payload;
        }
      })
      .addCase(addClauseToCompare.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.minimizedClauses = action.payload;
          state.compareOptions.compareMode = COMPARE_MODE.SHOW_ORIGINAL;
        }
      })
      .addCase(removeDocFromCompare.fulfilled, (state, action) => {
        state.minimizedDocs = action.payload;
      })
      .addCase(removeClauseFromCompare.fulfilled, (state, action) => {
        state.minimizedClauses = action.payload;
        state.compareOptions.compareMode = COMPARE_MODE.SHOW_ORIGINAL;
      })
      .addCase(clearCompare.fulfilled, (state, action) => {
        state.minimizedDocs = action.payload;
      })
      .addCase(clearClauseCompare.fulfilled, (state, action) => {
        state.minimizedClauses = action.payload;
      })
      .addCase(clearMinimizedDocs.fulfilled, (state, action) => {
        state.minimizedDocs = action.payload;
      })
      .addCase(clearMinimizedClauses.fulfilled, (state, action) => {
        state.minimizedClauses = action.payload;
      });
  },
});

export const {
  closeModal,
  showModal,
  showUploadFilesPopup,
  closeUploadFilesPopup,
  showLoader,
  selectDocs,
  updateStatuses,
  setAuthError,
  unsavedChanges,
  openCompareModal,
  closeCompareModal,
  openReportedReasonModal,
  closeReportedReasonModal,
  setCompareClauseReferences,
  setCompareMode,
  setLanguageLock,
  setLockedLanguage,
  setCompareDocumentReferences,
  resetCompareSettings,
} = uiSlice.actions;

export default uiSlice.reducer;
