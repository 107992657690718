import React from 'react';
import { DroppableDocumentComparePanelProps } from './DocumentComparePanel.types';
import { useDrag, useDrop } from 'react-dnd';
import { Identifier } from 'dnd-core';
import { DocumentComparePanel } from './DocumentComparePanel';
import { ReactComponent as DragAndDropIcon } from '../../../../assets/icons/drag-n-drop.svg';
import './DocumentComparePanel.scss';

export const DroppableClauseComparePanel = ({
  clauseDictionaries,
  clause,
  index,
  onDrop,
}: DroppableDocumentComparePanelProps) => {
  const [{ handlerId, isOver }, ref] = useDrop<
    { id: string; index: number; type: string },
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: 'DroppableClauseComparePanel',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver({ shallow: true }),
    }),
    drop(item) {
      onDrop && onDrop(item.index, index || 0);
    },
  });
  const [, refDrag, preview] = useDrag({
    type: 'DroppableClauseComparePanel',
    item: { index },
  });

  return (
    <div ref={preview}>
      <span ref={refDrag} className='drag-icon'>
        <DragAndDropIcon />
      </span>
      <DocumentComparePanel
        ref={ref}
        data-handler-id={handlerId}
        className={`${isOver ? 'document-compare-drop' : ''} full-height`}
        clauseDictionaries={clauseDictionaries}
        clause={clause}
        key={clause?.ClauseId}
        order={index}
      />
    </div>
  );
};
