import {
  DataGridPro,
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useState } from 'react';
import './Documents.scss';
import { useGetDictionariesQuery } from '../../store/files/upload/list.service';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  COLUMNS_DEFINITION,
  getColumnsVisibility,
  updateColumnsVisibility,
} from './DocumentsListGrid.helpers';
import { Box, CircularProgress } from '@mui/material';
import { DocumentsListProps } from './Documents.types';
import { useContextMenu } from './DocumentContextMenu/DocumentContextMenu.hooks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectDocs } from '../../store/ui/ui.slice';
import { useClearSelectedAndErrorDocs } from './DocumentsListGrid.hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { NUMBER_OF_ITEMS_ALLOWED, SORTING_ORDER } from '../../config/config';
import { paginationOptions } from '../StaticComponents/CustomPagination/CustomPagination';
import { DocumentContextMenu } from './DocumentContextMenu/DocumentContextMenu';
import { getErrorRowClass } from '../SpreadSheet/DocumentsGrid/AchDocumentsGrid.helpers';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { useLocation } from 'react-router-dom';
import { RoutePath } from '../../services/route.service';
import { Document } from '../../store/files/documents/documents.list.types';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { useGetRelatedDocumentsFromLocationState } from './useGetRelatedDocumentsFromLocationState';
import { ObjectType } from '../../store/files/reports/reports.types';

export const DocumentsListGrid = ({
  menuOptions,
  useGetDataListWithParams,
}: DocumentsListProps) => {
  const [columnsButtonEl, setColumnsButtonEl] = useState<HTMLButtonElement | null>(null);
  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState(getColumnsVisibility());
  const { control, handleContextMenu } = useContextMenu<Document>();
  const updateStatuses = useAppSelector(uiSelectors.selectUpdateStatuses);
  const selected = useAppSelector(uiSelectors.selectSelectedItem);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { relatedDocuments } = useGetRelatedDocumentsFromLocationState();

  const { data: dictionaries, isLoading: isDictLoading } = useGetDictionariesQuery({
    AccessMode: AccessMode.READ,
  });
  const {
    data,
    isLoading: isDocsLoading,
    isFetching,
    rowCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    filters,
    defaultParams,
    sort,
    setSort,
  } = useGetDataListWithParams(relatedDocuments);

  const documents = data?.Documents ?? [];
  const paginationMode =
    pathname === RoutePath.DOCUMENTS
      ? paginationOptions({ ...defaultParams, ...filters }, ObjectType.DOCUMENT)
      : paginationOptions();
  useClearSelectedAndErrorDocs();
  const DocumentsListGridToolbar = useCallback(
    () => (
      <GridToolbarContainer className='documents-list-grid-toolbar'>
        <GridToolbarColumnsButton
          ref={setColumnsButtonEl}
          startIcon={
            <SettingsIcon data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_COLUMNS_CHOICE} />
          }
          className='documents-list-settings'
        />
      </GridToolbarContainer>
    ),
    []
  );

  if (isDocsLoading || isFetching || isDictLoading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const componentsCustomProps: GridSlotsComponentsProps = {
    panel: {
      anchorEl: columnsButtonEl,
      placement: 'bottom-end',
      'data-test-id': TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_COLUMNS_LIST,
      sx: {
        [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
          display: 'none',
        },
      },
    },
    pagination: paginationMode,
  };

  return documents.length ? (
    <div
      data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_DOCUMENTS_LIST}
      style={{ minHeight: '100vh', width: '100%' }}
    >
      <DataGridPro
        sortingMode='server'
        sortingOrder={SORTING_ORDER}
        sortModel={sort}
        onSortModelChange={setSort}
        className='documents-list-grid'
        autoHeight={true}
        columns={COLUMNS_DEFINITION(dictionaries!, handleContextMenu, updateStatuses)}
        rows={documents}
        rowCount={rowCount}
        rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
        pagination
        paginationMode='server'
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selected}
        onSelectionModelChange={(documentIds) => {
          dispatch(selectDocs(documentIds as string[]));
        }}
        getRowId={(row) => row.DocumentId}
        rowHeight={64}
        disableColumnMenu
        components={{
          Toolbar: DocumentsListGridToolbar,
        }}
        localeText={{ toolbarColumns: '' }}
        scrollbarSize={15}
        columnVisibilityModel={columnsVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnsVisibilityModel(newModel);
          updateColumnsVisibility(newModel);
        }}
        componentsProps={componentsCustomProps}
        getRowClassName={({ row: { DocumentId } }) =>
          getErrorRowClass(updateStatuses, DocumentId, 'error')
        }
      />

      <DocumentContextMenu control={control} documentContextMenuItems={menuOptions} />
    </div>
  ) : (
    <NoItemsFound />
  );
};
