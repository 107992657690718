import { DataGridPro, GridSlotsComponentsProps } from '@mui/x-data-grid-pro';
import React from 'react';
import './PendingItemsListGrid.scss';
import { NoItemsFound } from '../../StaticComponents/NoItemsFound/NoItemsFound';
import { NUMBER_OF_ITEMS_ALLOWED } from '../../../config/config';
import { paginationOptions } from '../../StaticComponents/CustomPagination/CustomPagination';
import { Box, CircularProgress } from '@mui/material';
import { PendingItemsListGridProps } from './PendingItemsListGrid.types';
import { DocumentsListResponse } from '../../../store/files/documents/documents.list.types';
import { ClausesListResponse } from '../../../store/files/clauses/clauses.list.types';

const componentsCustomProps: GridSlotsComponentsProps = {
  pagination: paginationOptions(),
};

export const PendingItemsListGrid = (options: PendingItemsListGridProps) => {
  const { data, isLoading, isFetching, rowCount, page, pageSize, setPage, setPageSize } =
    options.dataHook();

  const pendingItemListData =
    options.dataType === 'Documents'
      ? (data as DocumentsListResponse)?.[options.dataType] ?? []
      : (data as ClausesListResponse)?.[options.dataType] ?? [];

  if (isLoading || isFetching) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return pendingItemListData.length ? (
    <div style={{ minHeight: '100vh', width: '100%' }}>
      <DataGridPro
        className='documents-list-grid pending-documents-list-grid'
        autoHeight={true}
        columns={options.columnsDefinition()}
        rows={pendingItemListData}
        rowCount={rowCount}
        rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
        pagination
        paginationMode='server'
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        disableSelectionOnClick
        getRowId={(row) => row[options.rowIdKey]}
        rowHeight={64}
        disableColumnMenu
        headerHeight={0}
        scrollbarSize={15}
        componentsProps={componentsCustomProps}
      />
    </div>
  ) : (
    <NoItemsFound subtitle='' icon={options.dataType === 'Documents' ? 'default' : 'clauseIcon'} />
  );
};
