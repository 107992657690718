import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { FileTypeIcon } from '../../StaticComponents/FileTypeIcon';
import { MetadataForm } from '../MetadataForm/MetadataForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { closeModal } from '../../../store/files/upload/upload.slice';
import {
  useGetListQuery,
  useUpdateMetadataMutation,
} from '../../../store/files/upload/list.service';
import { UPDATE_METADATA_CACHE_KEY } from '../../../store/files/upload/list.service.types';
import './MetadataEditModal.scss';
import { cleanupLocalFields } from '../MetadataForm/MetadataFormHelpers';
import { DocumentMetadata } from '../MetadataForm/MetadataForm.types';
import { DocumentViewer } from './DocumentViewer';
import { UPLOAD_FILES_BLOCKED_COLUMNS } from '../../../config/config';
import { handleApiError } from '../../../store/error.helpers';
import { ItemNameText } from '../../DocumentsAndClauses/ItemNameText/ItemNameText';

export const MetadataEditModal = () => {
  const [apiError, setApiError] = useState<string>();
  const dispatch = useAppDispatch();
  const documentId = useAppSelector(uploadSelectors.selectModalDocument);

  const { document } = useGetListQuery(undefined, {
    selectFromResult: ({ data }) => ({
      document: data?.find((d) => d.DocumentId === documentId),
    }),
  });
  const [update, { isLoading }] = useUpdateMetadataMutation({
    fixedCacheKey: UPDATE_METADATA_CACHE_KEY,
  });

  const handleClose = () => {
    dispatch(closeModal());
    setApiError(undefined);
  };
  const handleSubmit = (data: DocumentMetadata) => {
    setApiError(undefined);
    if (!document) return;
    update(cleanupLocalFields([{ ...document, ...data }]))
      .unwrap()
      .then(() => handleClose())
      .catch((response) => setApiError(handleApiError(response)));
  };

  return (
    <Modal open={!!document?.DocumentId} onClose={handleClose}>
      <div className='metadata-edit-modal'>
        <ModalClose onClose={handleClose} />

        <div className='back-button' onClick={handleClose}>
          <ChevronLeftIcon /> Edit metadata
        </div>

        <Grid container>
          <Grid item xs className='document-view'>
            <DocumentViewer documentId={documentId} />
          </Grid>

          <Grid item className='form-view'>
            <div className='metadata-edit-modal-header'></div>
            <div className='metadata-edit-modal-metadata'>
              <div className='modal-title'>
                <FileTypeIcon fileType={document?.fileType} />
                <ItemNameText fileName={document?.FileName!} strong />
              </div>
              <MetadataForm
                disabledColumns={UPLOAD_FILES_BLOCKED_COLUMNS.FORM}
                document={document}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                apiError={apiError}
                validate={true}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
