import React from 'react';
import './NoItemsFound.scss';
import { NoItemsFoundProps, REQUEST_ACCESS_OR_CHANGE_SEARCH_MESSAGE } from './NoItemsFound.types';
import { ReactComponent as BookIcon } from '../../../assets/icons/book.svg';
import { ReactComponent as ClauseIcon } from '../../../assets/icons/align-left.svg';
import SearchIcon from '@mui/icons-material/Search';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GroupOutlined from '@mui/icons-material/GroupOutlined';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddOutlined';

export const NoItemsFound: React.FC<NoItemsFoundProps> = ({
  subtitle = REQUEST_ACCESS_OR_CHANGE_SEARCH_MESSAGE,
  icon = 'default',
  handleClick,
}) => {
  return (
    <div className='no-documents-found'>
      <div className='no-documents-found-box'>
        {
          {
            default: (
              <>
                <BookIcon className='no-documents-found-icon' />
                <div className='no-documents-found-msg-title'>No documents found</div>
              </>
            ),
            searchIcon: (
              <>
                <SearchIcon className='no-documents-found-icon' />
                <div className='no-documents-found-msg-title'>No saved searches found</div>
              </>
            ),

            clauseIcon: (
              <>
                <ClauseIcon className='no-documents-found-icon' />
                <div className='no-documents-found-msg-title'>No clauses found</div>
              </>
            ),
            folderIconBookmarks: (
              <>
                <FolderOutlinedIcon className='no-documents-found-icon' />
                <div className='no-documents-found-msg-title'>No bookmarks found</div>
              </>
            ),
            folderIconReports: (
              <>
                <FolderOutlinedIcon className='no-documents-found-icon' />
                <div className='no-documents-found-msg-title'>No reports found</div>
              </>
            ),
            usersIcon: (
              <>
                <GroupOutlined className='no-documents-found-icon' />
                <div className='no-documents-found-msg-title'>No users found</div>
              </>
            ),
          }[icon]
        }

        <div className='no-documents-found-msg-subtitle'>{subtitle}</div>
      </div>
      {handleClick ? (
        <Button
          onClick={handleClick}
          className='upload-files'
          variant='contained'
          startIcon={<AddIcon fontSize='large' />}
        >
          Create new
        </Button>
      ) : null}
    </div>
  );
};
