import { ListParams } from '../documentsAndClauses/list.types';

export enum DOCUMENTS_LIST_API_ENDPOINTS {
  GET_DOCUMENTS_LIST = 'getDocumentsList',
  DELETE_DOCUMENT = 'deleteDocument',
  UPDATE_DOCUMENTS_METADATA = 'updateDocumentsMetadata',
  GET_DOCUMENT_FILE = 'getDocumentFile',
  REPORT_DOCUMENT = 'reportDocument',
  DELETE_DOCUMENT_REPORT = 'deleteDocumentReport',
  GET_SIMILAR = 'getSimilar',
}

export enum DOCUMENTS_LIST_API_TAGS {
  DOCUMENTS_LIST = 'DocumentsList',
  DOCUMENT_FILE = 'DocumentFile',
}

export type DocumentsListParamsNames = keyof DocumentsListParams;

export interface DocumentsListParams extends ListParams {
  BookmarkId?: string;
  Active?: string;
  BusinessType?: string[];
  Classification?: string[];
  Country?: string[];
  DocumentId?: string[];
  DocumentType?: string[];
  Entity?: string[];
  ExternalId?: string;
  Language?: string[];
  LatestVersion?: string;
  Lob?: string[];
  Lop?: string[];
  RelatedClauses?: string;
  Status?: string[];
  ParentDocuments?: string[];
}

export interface DocumentsListResponse {
  TotalDocumentCount: number;
  Page: number;
  TotalPages: number;
  Documents: DocumentResponse[];
}

export enum DocumentFileResponseFields {
  OriginalPdfUrl = 'OriginalPdfUrl',
  OriginalHtmlUrl = 'OriginalHtmlUrl',
  TranslatedHtmlUrl = 'TranslatedHtmlUrl',
}

export type DocumentFileResponse = {
  [value in DocumentFileResponseFields]: string;
};

export interface DocumentResponse {
  FileName: string;
  Entity: string;
  Country: string;
  Lob: string;
  Lop: string;
  BusinessType: string;
  DocumentType: string;
  Classification: string;
  Language: string;
  Active: string;
  ValidFrom: string;
  ValidTo: string;
  ExpireAt: string;
  Version: string;
  LatestVersion: string;
  DocumentName: string;
  DocumentId: string;
  UpdatedBy: string;
  UpdatedAt: string;
  CreatedBy: string;
  CreatedAt: string;
  Remarks: string;
  Reported: boolean;
  ReportedAt: string;
  ReportedBy: string;
  ReportedComment: string;
  ReportedReason: string;
  RelatedClauses: string[];
  Status: DocumentStatus;
  FailureReason: ValidationError;
  ExternalId: string;
  ParentDocuments: string[];
  ProductType: string;
  InsurerIndustry: string;
}

export type Document = DocumentResponse;

export enum DocumentStatus {
  PROCESSING = 'Document being Processed',
  READY = 'Processed Successfully',
  FAILED = 'Document Processing Failed',
  DUPLICATE = 'Failed document to delete',
  DELETING = 'DELETING',
}

export type DocumentMetadataUpdateRequest = {
  DocumentId: string;
  Country?: string;
  Lob?: string;
  Lop?: string;
  FileName?: string;
  BusinessType?: string;
  DocumentType?: string;
  Classification?: string;
  Active?: string;
  ValidFrom?: string;
  ValidTo?: string;
  ExpireAt?: string;
  Version?: string;
  LatestVersion?: string;
  Remarks?: string;
  ProductType?: string;
  InsurerIndustry?: string;
}[];

export interface DocumentMetadataUpdateResponseError {
  document_id?: string | null;
  error?: string;
  errorMessage?: string;
}

export interface DocumentMetadataUpdateResponse {
  Added?: string[];
  Failed?: string[];
  Errors?: DocumentMetadataUpdateResponseError[];
}

export interface ErrorResponse<T> {
  status: number;
  data: T;
}

export enum ValidationErrorCode {
  UnknownError = 'E1001',
  DocumentNotExist = 'E1002',
  MissingMandatoryFields = 'E1003',
  InvalidValue = 'E1004',
  InvalidParamName = 'E1005',
  InvalidDateOrder = 'E1006',
  InvalidFormat = 'E1007',
  InvalidColumn = 'E1008',
  InvalidFileNameFormat = 'E1009',
  IncorrectDocumentStatus = 'E1010',
  IncorrectHeaders = 'E1012',
  NotAllowedSizeOfFile = 'E1013',
  InvalidTypeOfFile = 'E1014',
  ProtectedFileByPassword = 'E1015',
  FileEmptyOrCorrupted = 'E1016',
  UnprocessableEntity = 'E1017',
  NumberOfFilesNotAllowed = 'E1018',
  NoPermissionsToParamValue = 'E1101',
  NoWritePermissions = 'E1102',
  NoPermissionToModifyFiles = 'E1103',
  DuplicateNameDetected = 'E1203',
  DuplicateNameDetectedReport = 'E1402',
  UnknownErrorInMSS = 'E3001',
  InvalidScanningReport = 'E3002',
  DocumentDuplicate = 'E2001',
  ClauseDuplicate = 'E2101',
  ClauseDuplicateIncompatibleLanguage = 'E2108',
  ClauseDuplicateMetadataExtended = 'E2109',
  ClauseAutomatedExtractMissingTag = 'E2110',
  UnknownErrorACH = 'E2003',
  UserNotFound = 'E1501',
  UserAlreadyExists = 'E1502',
  ProviderMismatch = 'E2103',
  NoClauseEditPermissions = 'E2104',
  NoPermissionsToDeleteAllMetadata = 'E2105',
  MetadataMismatch = 'E2106',
  UserGroupNotFound = 'E1503',
}

export interface ValidationError {
  errorCode: ValidationErrorCode | string;
  errorDetails?: {
    documentId?: string;
    fileName?: string | null;
    paramName?: string;
    providedValue?: string;
    allowedValues?: string[];
    documentIds?: string[];
    missingFields?: string[];
    providedColumn?: string;
    extendedClauseId?: string;
    duplicateClauseId?: string;
    duplicatedIds?: string[];
  };
}

export interface ValidationErrorResponse {
  message: string | null;
  errors: ValidationError[];
}

export const DOCS_UPDATE_METADATA_CACHE_KEY = 'docs_update_metadata_cache_key';

export const FILE_REQUEST_CACHE_EXPIRATION = 600;

export enum OriginalLanguage {
  ORIGINAL = 'True',
  TRANSLATED = 'False',
}

export interface ReportRequest {
  DocumentId: string;
  ReportedReason: string;
  ReportedComment: string;
}
