import { Button, Modal } from '@mui/material';
import { ModalClose } from '../StaticComponents/Modals/ModalClose';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { ReactComponent as BookIcon } from '../../assets/icons/book.svg';
import { RoutePath } from '../../services/route.service';
import { Link } from 'react-router-dom';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeUploadFilesPopup } from '../../store/ui/ui.slice';
import { useEffect } from 'react';

import './UploadFilesPopup.scss';

export const UploadFilesPopup = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const open: boolean = useAppSelector(uiSelectors.selectUploadFilesPopup);
  const onClose = () => dispatch(closeUploadFilesPopup());

  useEffect(() => {
    dispatch(closeUploadFilesPopup());
  }, [dispatch]);

  return (
    <Modal open={open}>
      <div className='uploadfiles-popup'>
        <ModalClose onClose={onClose} />
        <div className='uploadfiles-popup__content'>
          <div className='uploadfiles-popup__item'>
            <BookIcon />
            <div className='uploadfiles-popup__title'>Documents upload</div>
            <div className='uploadfiles-popup__text'>
              Upload multiple files to add <br />
              to the document repository.
            </div>
            <Link to={RoutePath.UPLOAD_FILES} className='uploadfiles-popup__button'>
              <Button onClick={onClose}>Upload</Button>
            </Link>
          </div>
          <div className='uploadfiles-popup__separator'></div>
          <div className='uploadfiles-popup__item'>
            <FormatAlignLeftIcon />
            <div className='uploadfiles-popup__title'>Clauses upload</div>
            <div className='uploadfiles-popup__text'>Add single clause to the clause library.</div>
            <Link to={RoutePath.UPLOAD_CLAUSE} className='uploadfiles-popup__button'>
              <Button onClick={onClose}>Upload</Button>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};
