import {
  useGetReportedClausesListWithParams,
  useGetUploadedClausesListWithParams,
} from '../../store/files/clauses/clauses.hooks';
import { RoutePath } from '../../services/route.service';
import {
  MenuItemsReportedClauses,
  MenuItemsUploadedClauses,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';

export const UPLOADED_CLAUSES_CONFIG = {
  useGetDataListWithParams: useGetUploadedClausesListWithParams,
  title: 'Uploaded clauses',
  basePath: RoutePath.UPLOADED_CLAUSES,
  menuOptions: MenuItemsUploadedClauses,
  disablePending: false,
};

export const REPORTED_CLAUSES_CONFIG = {
  useGetDataListWithParams: useGetReportedClausesListWithParams,
  title: 'Reported clauses',
  basePath: RoutePath.REPORTED_CLAUSES,
  menuOptions: MenuItemsReportedClauses,
  disablePending: true,
};
