import { useMemo, useCallback } from 'react';

import { MinimizedData } from '../../../../store/ui/ui.types';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { uiSelectors } from '../../../../store/ui/ui.selectors';
import {
  MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE,
  MAX_NUMBER_OF_MINIMIZE_DATA,
} from '../../../../config/config';
import { saveMinimizedDocs, closeMinimizedDoc } from '../../../../store/ui/ui.actions';
import { saveMinimizedClauses } from '../../../../store/ui/ui.actions';

import { Clause } from '../../../../store/files/clauses/clauses.list.types';
import { Document } from '../../../../store/files/documents/documents.list.types';
import { MinimizeItemProps } from './MinimizeItem.types';

export const useMinimizeItem = <T extends Document | Clause>({
  item,
  onMinimize,
  setMinimize,
}: MinimizeItemProps<T>) => {
  const dispatch = useAppDispatch();
  const minimizedItems = useAppSelector(
    (item as Document)?.DocumentId
      ? uiSelectors.selectMinimizedDocs
      : uiSelectors.selectMinimizedClauses
  );
  const newMinimizedDataId = (item as Document)?.DocumentId || (item as Clause)?.ClauseId;
  const newMinimizedItemName = (item as Document)?.FileName || (item as Clause)?.ClauseName;

  const maxNumberOfMinimized = (item as Document).DocumentId
    ? MAX_NUMBER_OF_MINIMIZE_DATA
    : MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE;
  const isMinimizeDisabled = useMemo(() => {
    return (
      minimizedItems?.length >= maxNumberOfMinimized &&
      !minimizedItems.some(({ dataId }) => dataId === newMinimizedDataId)
    );
  }, [maxNumberOfMinimized, minimizedItems, newMinimizedDataId]);

  const handleMinimize = () => () => {
    if (!isMinimizeDisabled) {
      const minimizedItem: MinimizedData = {
        dataId: newMinimizedDataId,
        name: newMinimizedItemName,
      };

      setMinimize?.(true);
      setTimeout(() => {
        setMinimize?.(false);
        onMinimize();
        if ('DocumentId' in item) {
          dispatch(saveMinimizedDocs(minimizedItem));
        } else {
          dispatch(saveMinimizedClauses(minimizedItem));
        }
      }, 1000);
    }
  };

  return { isMinimizeDisabled, handleMinimize };
};

export const useMinimizeItemForVersion = ({ onMinimize }: { onMinimize?: () => void }) => {
  const dispatch = useAppDispatch();
  const minimizedDocs = useAppSelector(uiSelectors.selectMinimizedDocs);

  const checkIfMinimizeDisabled = useCallback(
    (itemId: string) => {
      return (
        minimizedDocs?.length >= MAX_NUMBER_OF_MINIMIZE_DATA &&
        !minimizedDocs.some(({ dataId }) => dataId === itemId)
      );
    },
    [minimizedDocs]
  );

  const checkIfItemMinimized = useCallback(
    (itemId: string) => {
      return minimizedDocs.some(({ dataId }) => dataId === itemId);
    },
    [minimizedDocs]
  );

  const handleMinimize = (itemId: string, name: string) => {
    if (!checkIfMinimizeDisabled(itemId)) {
      const minimizedItem: MinimizedData = {
        dataId: itemId,
        name: name,
      };

      onMinimize?.();
      dispatch(saveMinimizedDocs(minimizedItem));
    }
  };

  const handleClose = (itemId: string) => {
    if (checkIfItemMinimized(itemId)) {
      dispatch(closeMinimizedDoc([itemId]));
    }
  };

  return { checkIfMinimizeDisabled, checkIfItemMinimized, handleClose, handleMinimize };
};
