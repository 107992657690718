import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AUTHORIZATION_USER_ENDPOINT } from '../services/api.service.config';
import { appendAuthorizationHeader } from '../components/Auth/auth.helpers';
import { AuthorizationResponse } from './authorization.service.types';

export const authorizationApi = createApi({
  reducerPath: 'authorizationApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: appendAuthorizationHeader,
  }),
  endpoints: (builder) => ({
    getUser: builder.query<AuthorizationResponse, void>({
      query: () => ({
        url: AUTHORIZATION_USER_ENDPOINT,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserQuery } = authorizationApi;
