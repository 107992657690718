import { useEffect, useState } from 'react';
import { useFileLoader } from './FileLoader.hook';
import { getSanitizedBlob } from './HtmlViewer.helpers';
import { SearchMethod } from '../../../store/files/documentsAndClauses/list.types';

import { useDocumentCompare, Order } from './useDocumentCompare';

export const useTransformDocumentDataToDiplayableUrl = (
  data: string,
  loading: boolean,
  error: boolean,
  method?: SearchMethod,
  keyword?: string,
  order?: number
) => {
  const { applyDifferencesOnStringifiedHtml, invertedCompare } = useDocumentCompare(order as Order);
  const { isLoading, setLoading } = useFileLoader(loading, error);
  const [documentUrl, setDocumentUrl] = useState('');

  useEffect(() => {
    let documentUrl = '';
    if (data) {
      setLoading(true);

      documentUrl = URL.createObjectURL(
        getSanitizedBlob(
          data,
          keyword,
          method,
          applyDifferencesOnStringifiedHtml,
          order as Order,
          invertedCompare
        )
      );
      setDocumentUrl(documentUrl);
      setLoading(false);
    }
    return () => {
      URL.revokeObjectURL(documentUrl);
    };
  }, [
    setLoading,
    data,
    keyword,
    method,
    applyDifferencesOnStringifiedHtml,
    order,
    invertedCompare,
  ]);

  return { documentUrl, isLoading };
};
