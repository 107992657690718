import { Box, CircularProgress } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ClauseResponse } from '../../../../store/files/clauses/clauses.list.types';
import { useDocumentRelatedClauses } from './ClauseRelatedDocuments.hooks';
import { COLUMNS_DEFINITION } from './DocumentRelatedClauses.helpers';

export const DocumentRelatedClauses = ({ clauses }: { clauses: ClauseResponse[] }) => {
  const { openNewClauseViewModal } = useDocumentRelatedClauses();
  return (
    <>
      {!clauses.length ? (
        <Box sx={{ textAlign: 'center', padding: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGridPro
          className='tab-items-list-grid'
          autoHeight={true}
          disableColumnFilter
          disableColumnSelector
          columns={COLUMNS_DEFINITION()}
          rows={clauses}
          getRowId={(row) => row.ClauseId}
          disableSelectionOnClick
          onRowClick={({ row: { ClauseId } }) =>
            openNewClauseViewModal && openNewClauseViewModal(ClauseId)
          }
          rowHeight={150}
          disableColumnMenu
          scrollbarSize={15}
          headerHeight={0}
          hideFooterRowCount
        />
      )}
    </>
  );
};
