import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import Button from '@mui/material/Button';
import React from 'react';
import { CompareProps } from './Compare.types';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { useCompareDocumentsInView } from '../../../store/ui/ui.compare.hooks';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { getDisableDocumentCompareMessage } from './Compare.helpers';

export const Compare = ({ documentId, name, dataType }: CompareProps) => {
  const { addOrRemoveDoc, isDisableAddToCompare, isDocInCompare } = useCompareDocumentsInView(
    documentId,
    name,
    dataType
  );

  return (
    <div>
      {!isDocInCompare ? (
        <Tooltip title={isDisableAddToCompare ? getDisableDocumentCompareMessage(dataType) : ''}>
          <div>
            <Button
              variant='text'
              startIcon={<DifferenceOutlinedIcon className='document-view-icons' />}
              onClick={addOrRemoveDoc}
              disabled={isDisableAddToCompare}
              data-test-id={TEST_ID.DOC_VIEW.TOGGLE_COMPARE}
            >
              Compare
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          className='document-view__compare--remove'
          variant='text'
          startIcon={<CloseIcon className='document-view-icons' />}
          onClick={addOrRemoveDoc}
          data-test-id={TEST_ID.DOC_VIEW.TOGGLE_COMPARE}
        >
          Remove from compare
        </Button>
      )}
    </div>
  );
};
