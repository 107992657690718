import { ClauseDictionariesResponse } from '../../../store/files/upload/list.service.types';
import { SequentialSearch } from '../../../store/files/documentsAndClauses/list.types';
import { notEmptySearchQuery } from '../DocumentsSearch/DocumentsSearch.helpers';

export const hasWriteAccessToDoc = (
  entity: string,
  classification: string,
  writeAccessDictionaries: ClauseDictionariesResponse
): boolean => {
  const isEntityIncluded = writeAccessDictionaries.Entities.values.some((e) => e.value === entity);
  const entityClassifications = writeAccessDictionaries.Entities.values.find(
    ({ value }) => value === entity
  )?.related?.Classification;
  const isClassificationIncluded = entityClassifications?.some(
    (entityClassification) => entityClassification === classification
  );
  return isEntityIncluded && !!isClassificationIncluded;
};

export const getLastNotEmptyQuery = (queries?: SequentialSearch[]) => {
  const notEmptyQueries = queries?.filter(notEmptySearchQuery);
  return notEmptyQueries?.[notEmptyQueries.length - 1] || {};
};
