import { useCallback, useEffect, useState } from 'react';
import { DirectionFlag } from './FileViewer.types';
import { calculateScale } from './PdfViewer.helpers';

const TAG = 'mark';
const CURRENT = 'current';

export const useMarkSwitch = () => {
  const [current, setCurrent] = useState(0);
  const [document, setDocument] = useState<HTMLElement | undefined>();

  const switchMark = useCallback(() => {
    if (!document) return;
    const marks = document.querySelectorAll(TAG);
    marks?.forEach((e) => e.classList.remove(CURRENT));

    const mark = marks[current];
    mark?.classList.add(CURRENT);
    mark?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [current, document]);

  useEffect(() => {
    switchMark();
  }, [current, document, switchMark]);

  const initialize = useCallback((doc?: HTMLElement | null) => {
    doc && setDocument(doc);
  }, []);

  const handleSwitch = useCallback(
    (direction: DirectionFlag = 1) => {
      if (!document) return;
      const marks = document.querySelectorAll(TAG);

      const next = current + direction;
      setCurrent(next === marks.length ? 0 : next === -1 ? marks.length - 1 : next);

      switchMark();
    },
    [current, document, switchMark]
  );

  return { initialize, handleSwitch, switchMark };
};

export const useZoomSwitch = (url?: string) => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    setScale(1);
  }, [url]);

  const handleZoom = (dir: DirectionFlag = 1) => setScale(calculateScale(scale, dir));

  return { handleZoom, scale };
};
