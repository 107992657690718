import { getOnlyFilteredParams } from '../../../store/files/documents/documents.list.helpers';
import {
  DocumentsListParams,
  OriginalLanguage,
} from '../../../store/files/documents/documents.list.types';
import {
  ClauseDictionariesResponse,
  DictionariesResponse,
  MetadataDictionary,
  MetadataDictionaryRelated,
} from '../../../store/files/upload/list.service.types';
import { IS_LANGUAGE_FILTERING_ENABLED } from '../../../config/config';
import { FILTERS_PREFIX } from '../../Settings/SavedSearches/SavedSearches.types';
import { ClausesListParams } from '../../../store/files/clauses/clauses.list.types';
import { SetValueType } from './DocumentsSearch.types';
import {
  SEQUENTIAL_SEARCH,
  SequentialSearch,
} from '../../../store/files/documentsAndClauses/list.types';
import _ from 'lodash';

export type FilterFields = Pick<
  DocumentsListParams,
  'Entity' | 'Country' | 'Lop' | 'Lob' | 'BusinessType'
>;

export type ClauseFilterFields = Pick<
  ClausesListParams,
  'Entities' | 'Countries' | 'Lops' | 'Lobs' | 'BusinessTypes'
>;

export const notEmptySearchQuery = ({ SearchQuery }: SequentialSearch) => !!SearchQuery;
const notEmptyExcludePhrases = ({ ExcludePhrases }: SequentialSearch) => ExcludePhrases?.length;

export const getFiltersCount = (
  values: DocumentsListParams | ClausesListParams,
  clause = false
) => {
  return (
    (values.Queries?.filter(notEmptySearchQuery).length ?? 0) +
    (values.Queries?.filter(notEmptyExcludePhrases).length ?? 0) +
    getOnlyFilteredParams(values, clause).length
  );
};

export const isOriginalLanguage = (value?: string) => value === OriginalLanguage.ORIGINAL;

export const setOriginalLanguage = (checked: boolean) =>
  checked ? OriginalLanguage.ORIGINAL : OriginalLanguage.TRANSLATED;

export const areArraysEqual = (array1: string[], array2: string[]) =>
  JSON.stringify([...array1].sort()) !== JSON.stringify([...array2].sort());

export const getFilterPath = (filterName: string, withPathPrefix?: boolean) =>
  (withPathPrefix ? `${FILTERS_PREFIX}.${filterName}` : filterName) as unknown as keyof Omit<
    DocumentsListParams,
    typeof SEQUENTIAL_SEARCH
  >;

export enum SingularClausesKeys {
  Lobs = 'Lob',
  Lops = 'Lop',
  Entities = 'Entity',
  Countries = 'Country',
  BusinessTypes = 'BusinessType',
}

const getDictValues = (
  related: MetadataDictionaryRelated | undefined,
  key: string,
  clausesFilters: boolean
) => {
  let keyName = clausesFilters ? SingularClausesKeys[key as keyof typeof SingularClausesKeys] : key;
  return (
    (Array.isArray(related?.[keyName as keyof MetadataDictionaryRelated])
      ? related?.[keyName as keyof MetadataDictionaryRelated]
      : [related?.[keyName as keyof MetadataDictionaryRelated]]) || []
  );
};

export const filterEntityValuesBasedOnFields = (
  fields: Partial<DocumentsListParams> | Partial<ClausesListParams>,
  entities: MetadataDictionary | undefined
) => {
  let selectedEntities: string[] | undefined = [];
  let restFilters: Partial<DocumentsListParams> | Partial<ClausesListParams> = {};
  let clausesFilters = false;
  if (fields?.hasOwnProperty('Entity')) {
    const { Entity: entityDocs, ...restDocFilters } = fields as Partial<DocumentsListParams>;
    selectedEntities = entityDocs;
    restFilters = restDocFilters;
  } else if (fields?.hasOwnProperty('Entities')) {
    const { Entities: entityClause, ...restClauseFilters } = fields as Partial<ClauseFilterFields>;
    selectedEntities = entityClause;
    restFilters = restClauseFilters;
    clausesFilters = true;
  }
  return entities?.values.filter(({ value, related }) => {
    return Object.entries(restFilters).every(([key, values]) => {
      const dictValue = getDictValues(related, key, clausesFilters);
      return (
        !(values as string[]).length ||
        _.intersection(dictValue, values as string[])?.length ||
        selectedEntities?.includes(value)
      );
    });
  });
};

export const filterCountryValues = (
  dicts: DictionariesResponse | undefined,
  fieldsValues: FilterFields
) => {
  const filteredRelatedCountries = flattenUnique(
    dicts?.Entity.values
      .filter(({ value }) => fieldsValues.Entity?.includes(value))
      .map(({ related }) => related?.Country)
  );

  return dicts?.Country?.values.filter(
    ({ value }) =>
      filteredRelatedCountries?.includes(value) || fieldsValues.Country?.includes(value)
  );
};

export const filterCountriesValues = (
  dicts: ClauseDictionariesResponse | undefined,
  fieldsValues: ClauseFilterFields
) => {
  const filteredRelatedCountries = flattenUnique(
    dicts?.Entities.values
      .filter(({ value }) => fieldsValues.Entities?.includes(value))
      .map(({ related }) => related?.Country)
  );

  return dicts?.Countries?.values.filter(
    ({ value }) =>
      filteredRelatedCountries?.includes(value) || fieldsValues.Countries?.includes(value)
  );
};

export const flattenUnique = (array: (string[] | string | undefined)[] | undefined) => {
  if (array?.length) {
    return Array.from(new Set(array.flat())).filter(Boolean);
  }
  return [];
};

const filterChildBasedOnParent = (
  parentDict: MetadataDictionary | undefined,
  childDict: MetadataDictionary | undefined,
  parentValues: string[] | undefined,
  childValues: string[] | undefined,
  field: keyof MetadataDictionaryRelated
) => {
  if (!parentValues?.length) {
    return childDict?.values;
  }

  const filterRelatedChildren = flattenUnique(
    parentDict?.values
      .filter(({ value }) => parentValues?.includes(value))
      .map(({ related }) => related?.[field])
  );

  return childDict?.values.filter(
    ({ value }) => filterRelatedChildren?.includes(value) || childValues?.includes(value)
  );
};

const filterLobValuesBase = (
  dictEntity: MetadataDictionary | undefined,
  dictLobs: MetadataDictionary | undefined,
  entities: string[] | undefined,
  lobs: FilterFields['Lob'],
  lops: FilterFields['Lop']
) => {
  if (!entities?.length) {
    return dictLobs?.values;
  }

  const filteredRelatedLobs = flattenUnique(
    dictEntity?.values
      .filter(({ value }) => entities?.includes(value))
      .map(({ related }) => related?.Lob)
  );

  return dictLobs?.values.filter(({ value, related }) => {
    return (
      (filteredRelatedLobs?.includes(value) &&
        (!lops?.length || _.intersection(lops, related?.Lop).length)) ||
      lobs?.includes(value)
    );
  });
};

export const filterLobValues = (
  dicts: DictionariesResponse | undefined,
  fieldsValues: FilterFields
) => {
  return filterLobValuesBase(
    dicts?.Entity,
    dicts?.Lob,
    fieldsValues.Entity,
    fieldsValues.Lob,
    fieldsValues.Lop
  );
};

export const filterLobsValues = (
  dicts: ClauseDictionariesResponse | undefined,
  fieldsValues: ClauseFilterFields
) => {
  return filterLobValuesBase(
    dicts?.Entities,
    dicts?.Lobs,
    fieldsValues.Entities,
    fieldsValues.Lobs,
    fieldsValues.Lops
  );
};

export const filterBusinessTypeValues = (
  dicts: DictionariesResponse | undefined,
  fieldsValues: FilterFields
) => {
  return filterChildBasedOnParent(
    dicts?.Entity,
    dicts?.BusinessType,
    fieldsValues.Entity,
    fieldsValues.BusinessType,
    'BusinessType'
  );
};

export const filterBusinessTypesValues = (
  dicts: ClauseDictionariesResponse | undefined,
  fieldsValues: ClauseFilterFields
) => {
  return filterChildBasedOnParent(
    dicts?.Entities,
    dicts?.BusinessTypes,
    fieldsValues.Entities,
    fieldsValues.BusinessTypes,
    'BusinessType'
  );
};

export const filterLopValues = (
  dicts: DictionariesResponse | undefined,
  fieldsValues: FilterFields
) => {
  return filterChildBasedOnParent(
    dicts?.Lob,
    dicts?.Lop,
    fieldsValues.Lob,
    fieldsValues.Lop,
    'Lop'
  );
};

export const filterLopsValues = (
  dicts: ClauseDictionariesResponse | undefined,
  fieldsValues: ClauseFilterFields
) => {
  return filterChildBasedOnParent(
    dicts?.Lobs,
    dicts?.Lops,
    fieldsValues.Lobs,
    fieldsValues.Lops,
    'Lop'
  );
};

const filterLanguageValuesBase = (
  dictEntity: MetadataDictionary | undefined,
  dictLanguage: MetadataDictionary | undefined,
  entities: string[] | undefined,
  classifications: string[] | undefined,
  countries: string[] | undefined,
  languages: string[] | undefined,
  field: string,
  setValue: SetValueType
) => {
  if (!IS_LANGUAGE_FILTERING_ENABLED) return dictLanguage?.values;
  const entityLanguage: string[] =
    dictEntity?.values
      .filter((e) => entities?.includes(e.value))
      .map((e) => e?.related?.Language as string) || [];

  const filteredLanguage = dictLanguage?.values.filter(({ value }) => {
    if (entityLanguage.length) {
      return entityLanguage.includes(value);
    } else if (classifications?.length && !countries?.length) {
      return dictEntity?.values
        .filter((v) => v?.related?.Classification?.some((rank) => classifications.includes(rank)))
        .map((entity) => entity?.related?.Language)
        .includes(value);
    } else if (countries?.length) {
      return dictEntity?.values
        .filter((v) => countries.includes(v?.related?.Country as string))
        .map((entity) => entity?.related?.Language)
        .includes(value);
    }
    return true;
  });
  const filteredLanguageValues: string[] = filteredLanguage?.map((v) => v.value) ?? [];

  if (
    languages?.length &&
    entities?.length &&
    areArraysEqual(filteredLanguageValues, languages) &&
    languages.length > entities.length
  ) {
    setValue(field, entityLanguage);
  } else if (
    languages?.length &&
    areArraysEqual(filteredLanguageValues, languages) &&
    languages.length >= filteredLanguageValues.length
  ) {
    setValue(field, filteredLanguageValues);
  }

  return filteredLanguage;
};

export const filterLanguageValues = (
  dicts: DictionariesResponse | undefined,
  values: DocumentsListParams,
  setValue: (name: string, value: string | string[] | undefined) => void
) => {
  return filterLanguageValuesBase(
    dicts?.Entity,
    dicts?.Language,
    values.Entity,
    values.Classification,
    values.Country,
    values.Language,
    'Language',
    setValue
  );
};

export const filterLanguagesValues = (
  dicts: ClauseDictionariesResponse | undefined,
  values: ClausesListParams,
  setValue: SetValueType
) => {
  return filterLanguageValuesBase(
    dicts?.Entities,
    dicts?.Language,
    values.Entities,
    values.Classifications,
    values.Countries,
    values.Language,
    'Language',
    setValue
  );
};

const filterClassificationValuesBase = (
  dictEntity: MetadataDictionary | undefined,
  entities: string[] | undefined,
  classifications: string[] | undefined,
  countries: string[] | undefined,
  languages: string[] | undefined,
  field: string,
  setValue: SetValueType
) => {
  const Classification: string[] = [];
  dictEntity?.values.filter((Entity) => {
    if (!entities?.length && !countries?.length && !languages?.length) {
      if (Entity?.related?.Classification) {
        Entity?.related?.Classification.forEach((rank) => {
          const classificationFound = Classification?.find((c) => c === rank);
          if (!classificationFound) {
            Classification.push(rank);
          }
        });
      }
    } else if (!countries?.length && !languages?.length) {
      entities?.forEach((entity) => {
        if (entity === Entity.value) {
          Entity?.related?.Classification?.forEach((rank) => {
            if (!Classification.includes(rank)) {
              Classification.push(rank);
            }
          });
        }
      });
    } else if (countries?.length) {
      countries.forEach((country) => {
        if (Entity?.related?.Country === country) {
          Entity?.related?.Classification?.forEach((rank) => {
            if (!Classification.includes(rank)) {
              Classification.push(rank);
            }
          });
        }
      });
    } else if (languages?.length) {
      languages.forEach((language) => {
        if (Entity?.related?.Language === language) {
          Entity?.related?.Classification?.forEach((rank) => {
            if (!Classification.includes(rank)) {
              Classification.push(rank);
            }
          });
        }
      });
    }
    return false;
  });

  const classificationInFilters = Classification.filter((rank) => classifications?.includes(rank));
  if (
    classifications?.length &&
    areArraysEqual(Classification, classifications) &&
    areArraysEqual(classificationInFilters, classifications)
  ) {
    setValue(field, classificationInFilters);
  }
  return Classification.map((rank) => ({ label: rank, value: rank }));
};

export const filterClassificationValues = (
  dicts: DictionariesResponse | undefined,
  values: DocumentsListParams,
  setValue: (name: string, value: string | string[] | undefined) => void
) => {
  return filterClassificationValuesBase(
    dicts?.Entity,
    values.Entity,
    values.Classification,
    values.Country,
    values.Language,
    'Classification',
    setValue
  );
};

export const filterClassificationsValues = (
  dicts: ClauseDictionariesResponse | undefined,
  values: ClausesListParams,
  setValue: SetValueType
) => {
  return filterClassificationValuesBase(
    dicts?.Entities,
    values.Entities,
    values.Classifications,
    values.Countries,
    values.Language,
    'Classifications',
    setValue
  );
};
