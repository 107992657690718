import { MAX_PAGE_SIZE } from '../../../config/config';
import { useGetDocumentsListQuery } from '../../../store/files/documents/documents.list.service';
import {
  DocumentsListParams,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { BOOLEAN_VALUES } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';

export const useCheckIfDocumentIsParent = (documentId: string | string[]) => {
  const documentIds = Array.isArray(documentId) ? documentId : [documentId];

  const queryParameters: DocumentsListParams = {
    PageSize: MAX_PAGE_SIZE.toString(),
    Status: [DocumentStatus.READY],
    ParentDocuments: documentIds,
    AccessMode: AccessMode.READ,
    Reported: BOOLEAN_VALUES.FALSE,
  };

  const { data: { Documents } = {}, isLoading } = useGetDocumentsListQuery(queryParameters, {
    skip: !documentId,
  });

  return {
    documentIsParent: !!Documents?.length,
    isLoading,
    count: Documents?.length,
    documentsList: Documents?.map((document) => document.DocumentId),
  };
};
