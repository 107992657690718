import { Paper } from '@mui/material';
import React from 'react';
import './UploadMetadata.scss';
import { BulkMetadataModal } from './BulkMetadataModal/BulkMetadataModal';
import { BulkDeleteModal } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal';
import { MetadataMenu } from './MetadataCsv/MetadataMenu';
import { UploadMetadataProps } from './UploadMetadata.types';
import { ReadMoreControl } from './ReadMoreControl';
import { useAppSelector } from '../../store/hooks';
import { uploadSelectors } from '../../store/files/upload/upload.selectors';
import { useBulkDeletePreLandingDocs } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal.hooks';

export const UploadMetadata: React.FC<UploadMetadataProps> = ({
  fullscreen,
  bulkEditOpen,
  setBulkEditOpen,
}) => {
  const selectedDocsIds = useAppSelector(uploadSelectors.selectedDocs);

  return (
    <Paper className={'upload-metadata-header ' + (fullscreen ? 'fullscreen' : '')} elevation={0}>
      <span className='upload-metadata-title'>Documents upload</span>
      <div className='upload-metadata-header-menu'>
        <ReadMoreControl />
        <BulkDeleteModal handler={useBulkDeletePreLandingDocs} />
        <BulkMetadataModal
          selectedDocsIds={selectedDocsIds}
          bulkEditOpen={bulkEditOpen}
          setBulkEditOpen={setBulkEditOpen}
        />
        <MetadataMenu />
      </div>
    </Paper>
  );
};
