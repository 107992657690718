import { Paper, PaperProps } from '@mui/material';
import { DictionariesResponse } from '../../../store/files/upload/list.service.types';
import { SpreadSheetDocument } from './DocumentsGrid.types';

export const CustomPaper = (props: PaperProps) => {
  return <Paper {...props} sx={{ minWidth: 'max-content' }} />;
};

export const getLopOptions = (
  dictionaries: DictionariesResponse,
  lobValue: SpreadSheetDocument['Lob']
) => {
  const lob = dictionaries.Lob.values.find(({ value }) => value === lobValue);
  return lob
    ? dictionaries.Lop.values.filter(({ value }) => lob?.related?.Lop?.includes(value))
    : [];
};
