import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Clause, ClausesListParams } from './clauses.list.types';
import { INIT_NUMBER_OF_DOCUMENTS } from '../../../config/config';
import { defaultSequentialSearch } from '../upload/list.helpers';

export const defaultListQueryParams = (): ClausesListParams => ({
  PageNumber: '1',
  PageSize: String(INIT_NUMBER_OF_DOCUMENTS),
  Queries: [defaultSequentialSearch()],
});

export interface ClausesState {
  clauseMetadataEditModal?: Clause;
  clauseViewModal?: Clause;
  listQueryParams: ClausesListParams;
  pendingListQueryParams: ClausesListParams;
  uploadedListQueryParams: ClausesListParams;
  reportedListQueryParams: ClausesListParams;
}

const initialState: ClausesState = {
  clauseMetadataEditModal: undefined,
  clauseViewModal: undefined,
  listQueryParams: defaultListQueryParams(),
  pendingListQueryParams: defaultListQueryParams(),
  uploadedListQueryParams: defaultListQueryParams(),
  reportedListQueryParams: defaultListQueryParams(),
};

export const clausesSlice = createSlice({
  name: 'clauses',
  initialState,
  reducers: {
    openClauseMetadataEditModal: (
      state: ClausesState,
      action: PayloadAction<{ clause: Clause }>
    ) => {
      state.clauseMetadataEditModal = action.payload.clause;
    },
    closeClauseMetadataEditModal: (state: ClausesState) => {
      state.clauseMetadataEditModal = undefined;
    },
    openClauseViewModal: (state: ClausesState, action: PayloadAction<{ clause: Clause }>) => {
      state.clauseViewModal = action.payload.clause;
    },
    closeClauseViewModal: (state: ClausesState) => {
      state.clauseViewModal = undefined;
    },
    updateListQueryParams: (state: ClausesState, action: PayloadAction<ClausesListParams>) => {
      state.listQueryParams = action.payload;
    },
    updatePendingListQueryParams: (
      state: ClausesState,
      action: PayloadAction<ClausesListParams>
    ) => {
      state.pendingListQueryParams = action.payload;
    },
    updateUploadedListQueryParams: (
      state: ClausesState,
      action: PayloadAction<ClausesListParams>
    ) => {
      state.uploadedListQueryParams = action.payload;
    },
    updateReportedListQueryParams: (
      state: ClausesState,
      action: PayloadAction<ClausesListParams>
    ) => {
      state.reportedListQueryParams = action.payload;
    },
  },
});

export const {
  openClauseMetadataEditModal,
  closeClauseMetadataEditModal,
  openClauseViewModal,
  closeClauseViewModal,
  updateListQueryParams,
  updatePendingListQueryParams,
  updateUploadedListQueryParams,
  updateReportedListQueryParams,
} = clausesSlice.actions;

export default clausesSlice.reducer;
