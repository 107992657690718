import { Button } from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { MarsModal } from '../StaticComponents/Modals/MarsModal';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  useAddBookmarkMutation,
  useAddItemToBookmarkMutation,
  useDeleteBookmarkItemMutation,
  useGetBookmarksQuery,
} from '../../store/files/bookmarks/bookmarks.service';
import { FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { bookmarksSelectors } from '../../store/files/bookmarks/bookmars.selectors';
import { closeBookmarkModal } from '../../store/files/bookmarks/bookmarks.slice';
import { useForm } from 'react-hook-form';
import { Bookmark } from '../../store/files/bookmarks/bookmarks.types';
import { NameInput } from '../SharedComponents/NameInput/NameInput';
import { ApiError } from '../UploadFiles/MetadataForm/ApiError';
import { useSnackbar } from 'notistack';
import { handleApiError } from '../../store/error.helpers';
import { BookmarkSelect } from './BookmarkSelect';
import { isDocument, mapToAddItemBody } from './BookmarksModal.helper';
import { CreateBookmarkResult } from './BookmarksCreate.types';
import { ErrorMessages } from '../../services/errors.service.types';
import { DOCUMENTS_LIST_API_TAGS } from '../../store/files/documents/documents.list.types';
import { CLAUSES_LIST_API_TAGS } from '../../store/files/clauses/clauses.list.types';
import { documentsListApi } from '../../store/files/documents/documents.list.service';
import { clausesListApi } from '../../store/files/clauses/clauses.list.service';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import './BookmarksModal.scss';
import { ITEM_TYPE } from '../../store/ui/ui.types';
import { Name } from '../SharedComponents/NameInput/NameInput.types';
import { useApiError } from '../../store/ui/ui.helpers';

export const BookmarksModal = (): JSX.Element => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const { data } = useGetBookmarksQuery();
  const [add, { isLoading: isAddingBookmark }] = useAddBookmarkMutation();
  const [addItem, { isLoading: isAddingItemToBookmark }] = useAddItemToBookmarkMutation();
  const [deleteItem] = useDeleteBookmarkItemMutation();
  const [item, currentBookmarkId] = useAppSelector(bookmarksSelectors.selectBookmarksModal);
  const [step, setStep] = useState(0);
  const [selectedBookmark, setSelectedBookmark] = useState('');
  const dispatch = useAppDispatch();
  const bookmarkNames: Bookmark[] | undefined = data?.map((el) => el);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data && data[0].BookmarkId) {
      setSelectedBookmark(data[0].BookmarkId);
    }
  }, [data, setSelectedBookmark]);

  const {
    control,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm<Name>({
    mode: 'onChange',
  });

  const hasError = !!Object.keys(errors).length;

  const preventDefault = (e?: FormEvent) => {
    e?.preventDefault();
    setApiError('');
  };

  const handleDeleteItem = (itemId: string) => {
    if (currentBookmarkId) {
      deleteItem({
        BookmarkId: currentBookmarkId,
        ItemsToDelete: [itemId || ''],
      })
        .unwrap()
        .then(() => {
          dispatch(
            isDocument(item)
              ? documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST])
              : clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST])
          );
        })
        .catch(() => enqueueSnackbar(ErrorMessages.BookmarkItemRemoveError, { variant: 'error' }));
    }
  };

  const handleAddItem = (
    bookmarkId: string,
    successMessage: string,
    onError: (response: FetchBaseQueryError | SerializedError) => void
  ) => {
    addItem(mapToAddItemBody(bookmarkId, item))
      .unwrap()
      .then(() => {
        handleCloseBookmarksModal();
        reset();
        enqueueSnackbar(successMessage);
      })
      .catch(onError);
  };

  const handleSubmit = (e?: FormEvent) => {
    preventDefault(e);
    const { Name } = getValues();

    add({ Name })
      .then((result) => {
        if ('data' in result) {
          const newBookmark = result.data as unknown as CreateBookmarkResult;
          if (newBookmark?.bookmark_id) {
            handleAddItem(
              newBookmark.bookmark_id,
              'Bookmark created and item added successfully',
              (response) => setApiError(handleApiError(response, true))
            );
          } else {
            setApiError('Error: Bookmark ID is missing');
          }
        } else {
          setApiError(handleApiError(result.error, true));
        }
      })
      .catch((response) => setApiError(handleApiError(response, true)));

    if (currentBookmarkId) {
      handleDeleteItem(item?.DocumentId || item?.ClauseId || '');
    }
  };

  const handleAddItemSubmit = (e?: FormEvent) => {
    preventDefault(e);
    handleAddItem(
      selectedBookmark,
      currentBookmarkId ? 'File moved successfully.' : 'Bookmark updated successfully',
      (response) => setApiError(handleApiError(response))
    );

    if (currentBookmarkId) {
      handleDeleteItem(item?.DocumentId || item?.ClauseId || '');
    }
  };

  const handleCloseBookmarksModal = () => {
    dispatch(closeBookmarkModal());
    setStep(0);
    setApiError('');
  };

  const onPrevious = () => {
    setStep(0);
    setApiError('');
  };
  switch (step) {
    case 0:
      return (
        <MarsModal
          className='bookmarks-modal'
          open={!!item}
          buttonLabel='Save'
          onConfirm={handleAddItemSubmit}
          onClose={handleCloseBookmarksModal}
          loading={isAddingItemToBookmark}
        >
          <div className='bookmarks-modal-title'>
            {currentBookmarkId ? 'Move file' : 'Save to bookmark'}
          </div>
          <div className='bookmarks-modal-content'>
            {currentBookmarkId
              ? 'Choose the bookmark where you want to move the file.'
              : 'Creating a bookmark can allow you to quickly access the frequently visited documents or clauses. Choose the folder where you want to save the bookmark.'}
          </div>
          <Box display='block'>
            <BookmarkSelect
              selectedBookmark={selectedBookmark}
              setSelectedBookmark={setSelectedBookmark}
              bookmarkNames={bookmarkNames}
            />
            <Button
              className='create-button'
              startIcon={<AddIcon />}
              size='small'
              variant='text'
              onClick={() => {
                setStep(1);
              }}
            >
              Create new folder
            </Button>
          </Box>
        </MarsModal>
      );
    case 1:
      return (
        <MarsModal
          open={!!item}
          buttonLabel='Save'
          className='bookmarks-modal'
          onClose={handleCloseBookmarksModal}
          onConfirm={handleSubmit}
          loading={isAddingBookmark || isAddingItemToBookmark}
          disabled={hasError || !!apiError || !isDirty}
        >
          <div className='bookmarks-modal-title-block'>
            <ArrowBackIosNewIcon className='modal-previous' onClick={onPrevious} />
            <div className='bookmarks-modal-title'>Create new folder</div>
          </div>
          <div className='bookmarks-modal-content'>
            Bookmark will be saved in created folder. You can always access all your folders in the
            bookmarks page.
          </div>
          <div className='bookmarks-input'>
            <NameInput
              control={control}
              isLoading={false}
              reset={reset}
              clearApiError={clearApiError}
              itemType={ITEM_TYPE.BOOKMARK}
            />
          </div>
          <ApiError apiError={apiError} />
        </MarsModal>
      );
    default:
      return <></>;
  }
};
