import { getRoutePath, RoutePath } from '../../../../services/route.service';

export const openNewDocumentViewModal = (DocumentId: string) => {
  window.open(getRoutePath(RoutePath.DOCUMENTS, DocumentId as RoutePath), '_blank');
};

export const useClauseRelatedDocuments = () => {
  return { openNewDocumentViewModal };
};

export const openNewClauseViewModal = (ClauseId: string) => {
  window.open(getRoutePath(RoutePath.CLAUSES, ClauseId as RoutePath), '_blank');
};

export const useDocumentRelatedClauses = () => {
  return { openNewClauseViewModal };
};
