export enum ErrorMessages {
  FileDeleteError = 'Error deleting file, try again later.',
  MetadataUpdateDuplicate = 'The metadata could not be changed. The document with the same metadata and content already exists. Please delete the document if necessary',
  MetadataUpdateError = 'The metadata could not be changed. Try again',
  DocumentNotExistsCompare = 'The Document you have selected to compare is not available anymore in Global Wording Repository.',
  DocumentsNotExistCompare = 'The Documents you have selected to compare are not available anymore in Global Wording Repository.',
  DocumentNotExistsCompareTitle = 'Document to compare no longer exists',
  DocumentsNotExistCompareTitle = 'Documents to compare no longer exists',
  FileReportError = 'Error reporting file, try again later.',

  ClauseNotExistsCompare = 'The Clause you have selected to compare is not available anymore in Global Wording Repository.',
  ClausesNotExistCompare = 'The Clauses you have selected to compare are not available anymore in Global Wording Repository.',
  ClauseNotExistsCompareTitle = 'Clause to compare no longer exists',
  ClausesNotExistCompareTitle = 'Clauses to compare no longer exists',

  SavedSearchUpdateError = 'The saved search could not be changed. Try again',
  DeleteSavedSearchError = 'Error deleting saved search, try again later.',
  DeleteBookmarkError = 'Error deleting bookmark, try again later.',
  BookmarkItemRemoveError = 'Error removing file, try again later.',

  ReportDeleteError = 'Error deleting report, try again later.',
  CommentAddedError = 'Failed to create a comment. Please try again',
  CommentDeleteError = 'Failed to delete the comment. Please try again',

  AuthenticationError = 'Unable to retrieve user access rights from the server.',
}
