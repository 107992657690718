import { DataGridPro, GridSlotsComponentsProps } from '@mui/x-data-grid-pro';
import { useGetClauseDictionariesQuery } from '../../store/files/upload/list.service';
import { Box, CircularProgress } from '@mui/material';
import { selectDocs } from '../../store/ui/ui.slice';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { NUMBER_OF_ITEMS_ALLOWED, SORTING_ORDER } from '../../config/config';
import { paginationOptions } from '../StaticComponents/CustomPagination/CustomPagination';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { ClausesListProps } from './Clauses.types';
import { useContextMenu } from '../Documents/DocumentContextMenu/DocumentContextMenu.hooks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useClearSelectedAndErrorDocs } from '../Documents/DocumentsListGrid.hooks';
import { DocumentContextMenu } from '../Documents/DocumentContextMenu/DocumentContextMenu';
import { COLUMNS_DEFINITION } from './ClausesListGrid.helpers';
import { Clause } from '../../store/files/clauses/clauses.list.types';
import { ObjectType } from '../../store/files/reports/reports.types';
import './ClausesListGrid.scss';
import { RoutePath } from '../../services/route.service';
import { useLocation } from 'react-router-dom';
import { getLastNotEmptyQuery } from '../Documents/DocumentViewModal/DocumentViewModal.helpers';

export const ClausesListGrid = ({ menuOptions, useGetDataListWithParams }: ClausesListProps) => {
  const { control, handleContextMenu } = useContextMenu<Clause>();
  const selected = useAppSelector(uiSelectors.selectSelectedItem);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isLoading: isDictLoading } = useGetClauseDictionariesQuery({
    AccessMode: AccessMode.READ,
  });
  const {
    data,
    filters,
    isLoading: isDocsLoading,
    isFetching,
    rowCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    sort,
    setSort,
    defaultParams,
  } = useGetDataListWithParams();
  const clauses = data?.Clauses ?? [];
  const { SearchQuery = '' } = getLastNotEmptyQuery(filters.Queries);
  useClearSelectedAndErrorDocs();
  if (isDocsLoading || isFetching || isDictLoading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const componentsCustomProps: GridSlotsComponentsProps = {
    pagination:
      pathname === RoutePath.CLAUSES
        ? paginationOptions({ ...defaultParams, ...filters }, ObjectType.CLAUSE)
        : paginationOptions(),
  };

  return clauses.length ? (
    <div style={{ minHeight: '100vh', width: '100%' }}>
      <DataGridPro
        sortingMode='server'
        sortingOrder={SORTING_ORDER}
        sortModel={sort}
        onSortModelChange={setSort}
        className='clauses-list-grid documents-list-grid'
        autoHeight={true}
        disableColumnFilter
        disableColumnSelector
        columns={COLUMNS_DEFINITION(handleContextMenu, SearchQuery)}
        rows={clauses}
        rowCount={rowCount}
        rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
        pagination
        paginationMode='server'
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selected}
        onSelectionModelChange={(clauseIds) => {
          dispatch(selectDocs(clauseIds as string[]));
        }}
        getRowId={(row) => row.ClauseId}
        rowHeight={240}
        disableColumnMenu
        scrollbarSize={15}
        componentsProps={componentsCustomProps}
      />

      <DocumentContextMenu control={control} documentContextMenuItems={menuOptions} />
    </div>
  ) : (
    <NoItemsFound icon='clauseIcon' />
  );
};
