import React from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DocumentContextMenuButtonProps } from './DocumentContextMenuButton.types';

export const DocumentContextMenuButton = ({
  disabled,
  onClick,
}: DocumentContextMenuButtonProps) => {
  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <MoreVertIcon />
    </IconButton>
  );
};
