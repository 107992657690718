import { Dispatch, SetStateAction, useState } from 'react';
import { SequentialSearch } from '../../../../store/files/documentsAndClauses/list.types';
import { concat, set } from 'lodash';
import { defaultSequentialSearch } from '../../../../store/files/upload/list.helpers';

export interface SequentialSearchHookReturn {
  addSearchQuery: () => void;
  addSearchQueryActive: boolean;
  collapsedItems: number;
  deleteSearchQuery: (index: number) => void;
  lastSearchQuery: number;
  searchQueriesCollapsed: boolean;
  selectedSearch: number;
  sequentialSearches: SequentialSearch[];
  setSelectedSearch: Dispatch<SetStateAction<number>>;
  setSsearchQueriesCollapsed: Dispatch<SetStateAction<boolean>>;
  shouldCollapse: boolean;
  updateSearchQuery: (index: number, value: string) => void;
}

export const useSequentialSearch = (
  sequentialSearches: SequentialSearch[] = [],
  setSequentialSearches: (values: SequentialSearch[]) => void
): SequentialSearchHookReturn => {
  const [selectedSearch, setSelectedSearch] = useState(0);
  const [searchQueriesCollapsed, setSsearchQueriesCollapsed] = useState(true);
  const addSearchQueryActive = sequentialSearches.length < 7;
  const shouldCollapse = sequentialSearches.length > 3;
  const collapsedItems = sequentialSearches.length - 2;
  const addSearchQuery = () =>
    setSequentialSearches(concat(sequentialSearches, defaultSequentialSearch()));
  const updateSearchQuery = (index: number, value: string) =>
    setSequentialSearches(set(sequentialSearches, `[${index}].SearchQuery`, value));
  const lastSearchQuery = sequentialSearches.length - 1;
  const deleteSearchQuery = (index: number) => {
    setSequentialSearches(sequentialSearches.filter((_, i) => index !== i));
    setSelectedSearch(0);
  };

  return {
    addSearchQuery,
    addSearchQueryActive,
    collapsedItems,
    deleteSearchQuery,
    lastSearchQuery,
    searchQueriesCollapsed,
    selectedSearch,
    sequentialSearches,
    setSelectedSearch,
    setSsearchQueriesCollapsed,
    shouldCollapse,
    updateSearchQuery,
  };
};
