import React, { FormEvent, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';

import { MAX_COMMENT_LENGTH } from '../../config/config';
import { CustomTextField } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  useAddCommentMutation,
  useDeleteCommentMutation,
} from '../../store/files/comments/comments.service';
import { AddCommentData, Comment } from '../../store/files/comments/comments.types';
import LoadingButton from '@mui/lab/LoadingButton';
import { AddCommentRules } from './Comments.helpers';
import { ErrorMessages } from '../../services/errors.service.types';
import { Messages } from '../../services/messages.service.types';
import { CommentsProps } from './Comments.types';
import { CommentList } from './CommentList/CommentList';

import './Comments.scss';

export const Comments: React.FC<CommentsProps> = ({
  comments,
  itemId,
  itemType,
  isDataFetching,
}) => {
  const {
    control,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<AddCommentData>({
    mode: 'onChange',
    defaultValues: {
      ItemId: itemId,
      ItemType: itemType,
      Content: '',
    },
  });
  const [userEmail, setUserEmail] = useState<string>();
  const [deletingComment, setDeletingComment] = useState<string>('');
  const [sortedComments, setSortedComments] = useState<Comment[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const [update, { isLoading: isAddingComment }] = useAddCommentMutation();
  const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation();

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      setUserEmail(data?.attributes?.email);
    });
  }, []);

  useEffect(() => {
    setSortedComments(
      [...comments].sort((a, b) => {
        return new Date(a?.CreatedAt).getTime() - new Date(b?.CreatedAt).getTime();
      })
    );
  }, [comments]);

  const isCurrentUserCommentAuthor = (commentAuthorEmail: string) =>
    userEmail === commentAuthorEmail;

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    update(getValues())
      .unwrap()
      .then(() => {
        reset();
        enqueueSnackbar(Messages.CommentAddedSuccess);
      })
      .catch(() => enqueueSnackbar(ErrorMessages.CommentAddedError, { variant: 'error' }));
  };

  const handleDelete = (commentId: string) => {
    setDeletingComment(commentId);
    deleteComment(commentId)
      .unwrap()
      .then(() => {
        setSortedComments(sortedComments.filter(({ CommentId }) => CommentId !== commentId));
        enqueueSnackbar(Messages.CommentRemovedSuccess);
      })
      .catch(() => enqueueSnackbar(ErrorMessages.CommentDeleteError, { variant: 'error' }))
      .finally(() => setDeletingComment(''));
  };

  return (
    <div className='comments-container'>
      <CommentList
        comments={sortedComments}
        isCurrentUserCommentAuthor={isCurrentUserCommentAuthor}
        isDeletingComment={isDeletingComment}
        isAddingComment={isAddingComment}
        deletingComment={deletingComment}
        handleDelete={handleDelete}
        isCommentsFetching={isDataFetching}
      />
      <div className='comment-form-container'>
        <form className='comment-form-container__form' onSubmit={handleSubmit}>
          <Controller
            name='Content'
            control={control}
            defaultValue=''
            rules={AddCommentRules}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
                {...field}
                multiline
                disabled={isAddingComment || isDeletingComment || isDataFetching}
                helperWarning={error}
                label=''
                hasCounter
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder='Add a comment'
              />
            )}
          />
          <LoadingButton
            variant='text'
            disabled={!isValid || isDeletingComment}
            loading={isAddingComment}
            type='submit'
          >
            Publish
          </LoadingButton>
        </form>
      </div>
    </div>
  );
};
