export enum RoutePath {
  UPLOAD_CLAUSE = '/upload-clause',
  UPLOAD_FILES = '/upload-files',
  UPLOAD_FILES_LIST_VIEW = 'list',
  UPLOAD_FILES_SPREADSHEET_VIEW = 'spreadsheet',
  DOCUMENTS = '/documents',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SETTINGS = '/settings',
  ADMIN_PANEL = '/admin-panel',
  SETTINGS_ACCOUNT = 'account',
  SETTINGS_NOTIFICATIONS = 'notifications',
  SETTINGS_SAVED_SEARCHES = 'saved-searches',
  NOT_FOUND = '/not-found',
  NO_ACCESS = '/no-access',
  NO_WRITE_ACCESS = '/no-write-access',
  CLAUSES = '/clauses',
  BOOKMARKS = '/bookmarks',
  BOOKMARKS_DOCUMENTS = 'documents',
  BOOKMARKS_CLAUSES = 'clauses',
  UPLOADED_DOCUMENTS = '/my-files/uploaded-documents',
  REPORTED_DOCUMENTS = '/my-files/reported-documents',
  UPLOADED_DOCUMENTS_LIST_VIEW = 'list',
  UPLOADED_DOCUMENTS_SPREADSHEET_VIEW = 'spreadsheet',
  UPLOADED_DOCUMENTS_PENDING = 'pending',
  UPLOADED_CLAUSES = '/my-files/uploaded-clauses',
  REPORTED_CLAUSES = '/my-files/reported-clauses',
  UPLOADED_CLAUSES_LIST_VIEW = 'list',
  UPLOADED_CLAUSES_PENDING = 'pending',
  REPORTS = '/reports',
  MY_REPORTS = 'my-reports',
  REPORTS_REPORTS = 'reports',
  ID = ':id',
  BOOKMARK_ID = ':bookmark_id',
}

export const getRoutePath = (base: RoutePath, path: RoutePath) => `${base}/${path}`;

export const getChildPath = (base: RoutePath) => `${base}/*`;
