import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import {
  UploadedFile,
  UploadedFileStatus,
} from '../../../components/UploadFiles/UploadedFileList.types';
import { UploadState } from './upload.slice';

const selectUpload = (state: RootState) => state.files.upload;

const selectDocs = (state: RootState) => selectUpload(state).docs;

const selectDocsList = createSelector(selectDocs, (items): UploadedFile[] =>
  Object.values(items).reverse()
);

const selectFailedFiles = createSelector(
  selectUpload,
  (items): UploadState['failedFiles'] => items.failedFiles
);

const selectModalDocument = createSelector(
  selectUpload,
  (items): string | undefined => items.metadataEditModal
);

const selectedDocs = (state: RootState) => selectUpload(state).selectedDocs;

const selectFilesUploading = createSelector(selectDocsList, (items): UploadedFile[] =>
  items.filter(({ Status }) => Status === UploadedFileStatus.UPLOADING)
);

const selectFilesUploaded = createSelector(selectFilesUploading, (items): UploadedFile[] =>
  items.filter(({ progress }) => progress === 100)
);

export const uploadSelectors = {
  selectDocsList,
  selectFailedFiles,
  selectModalDocument,
  selectedDocs,
  selectFilesUploading,
  selectFilesUploaded,
};
