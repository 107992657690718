import React from 'react';
import {
  useFetchAndDispatchClause,
  useGetClausesListWithParams,
} from '../../store/files/clauses/clauses.hooks';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { useClauseReadAccess } from '../../contexts/UserAccess';
import { DocumentsSearch } from '../Documents/DocumentsSearch/DocumentsSearch';
import { DocumentsCount } from '../Documents/DocumentsCount';
import { MenuItemsClauses } from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { ClausesList } from './ClausesList';

const useGetDataListWithParams = useGetClausesListWithParams;

export const Clauses = () => {
  useClauseReadAccess();
  useFetchAndDispatchClause();
  const { filters, setFilters } = useGetDataListWithParams();

  return (
    <>
      <DocumentsSearch
        filters={filters}
        onApply={setFilters}
        accessMode={AccessMode.READ}
        clauseMode
      />
      <DocumentsCount useGetDataListWithParams={useGetDataListWithParams} clauseMode />
      <ClausesList
        menuOptions={MenuItemsClauses}
        useGetDataListWithParams={useGetDataListWithParams}
      />
    </>
  );
};
