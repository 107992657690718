import { showStatus } from '../../UploadFiles/UploadedFileList.helper';
import { UploadedFileStatus } from '../../UploadFiles/UploadedFileList.types';
import { removeSelectedDocs } from '../../../store/files/upload/upload.slice';
import { ErrorMessages } from '../../../services/errors.service.types';
import { listApi, useDeleteFileMutation } from '../../../store/files/upload/list.service';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import { selectDocs, showLoader } from '../../../store/ui/ui.slice';
import { showDeletingStatusACH } from '../../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import {
  documentsListApi,
  useDeleteDocumentMutation,
} from '../../../store/files/documents/documents.list.service';
import { DOCUMENTS_LIST_API_TAGS } from '../../../store/files/documents/documents.list.types';
import { Messages } from '../../../services/messages.service.types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useDeleteSavedSearchMutation } from '../../../store/user/savedsearch.service';
import { useSnackbar } from 'notistack';
import {
  CLAUSE,
  CLAUSES,
  DOCUMENT,
  DOCUMENTS,
  REPORT,
  REPORTS,
  SAVED_SEARCH,
  SAVED_SEARCHES,
  DELETE_MESSAGE_PARENT_RELATED_DOCUMENT,
} from '../Modals/ConfirmationModal/ConfirmationModal.messages';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { BulkDeleteBaseHook, UseBulkDeleteHook } from './BulkDeleteModal.types';
import { useEditingDisabled } from '../../UploadFiles/EditingDisabled.hook';
import { getTitleAndMessage, processDelete } from './BulkDeleteModal.helpers';
import { REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION } from '../../../config/config';
import {
  clausesListApi,
  useDeleteClauseMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { CLAUSES_LIST_API_TAGS } from '../../../store/files/clauses/clauses.list.types';
import { useDeleteReportMutation } from '../../../store/files/reports/reports.service';
import { useEffect } from 'react';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { useCheckIfDocumentIsParent } from '../../Documents/DocumentContextMenu/useCheckIfDocumentIsParent';

const useBulkDeleteBase: BulkDeleteBaseHook = (
  selector,
  useMutation,
  [singular, plural],
  errorMsg,
  successMsg
) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedIds = useAppSelector(selector);
  const [remove] = useMutation();

  return {
    disabled: !selectedIds.length,
    handleDelete: async () => {
      const anyFailed = await processDelete(selectedIds, remove);
      if (anyFailed) {
        enqueueSnackbar(errorMsg, { variant: 'error' });
      } else {
        if (successMsg) {
          const [success, successMulti] = successMsg;
          enqueueSnackbar(selectedIds.length > 1 ? successMulti : success);
        }
      }
    },
    ...getTitleAndMessage(singular, plural, selectedIds),
    selectedIds,
  };
};

export function useBulkDeletePreLandingDocs(): UseBulkDeleteHook {
  const dispatch = useAppDispatch();
  const { disabled, handleDelete, message, selectedIds, title } = useBulkDeleteBase(
    uploadSelectors.selectedDocs,
    useDeleteFileMutation,
    [DOCUMENT, DOCUMENTS],
    ErrorMessages.FileDeleteError
  );
  const editingDisabled = useEditingDisabled({ forDeleteSelected: true });

  async function handleBulkDelete() {
    selectedIds.forEach((id) => dispatch(showStatus(id, UploadedFileStatus.DELETING)));

    await handleDelete();

    dispatch(removeSelectedDocs());
    dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
  }

  return { disabled: disabled || editingDisabled, handleBulkDelete, message, title };
}

export function useBulkDeleteACHDocs(): UseBulkDeleteHook {
  const dispatch = useAppDispatch();
  const { disabled, handleDelete, message, selectedIds, title } = useBulkDeleteBase(
    uiSelectors.selectSelectedItem,
    useDeleteDocumentMutation,
    [DOCUMENT, DOCUMENTS],
    ErrorMessages.FileDeleteError
  );
  const { documentIsParent, isLoading } = useCheckIfDocumentIsParent(selectedIds);
  const messageExt = `${
    documentIsParent ? `${DELETE_MESSAGE_PARENT_RELATED_DOCUMENT} <br />` + message : message
  }`;

  async function handleBulkDelete() {
    dispatch(showLoader(true));
    selectedIds.forEach((id) => dispatch(showDeletingStatusACH(id)));

    await handleDelete();
    await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);

    dispatch(documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST]));
    dispatch(showLoader(false));
  }

  return { disabled: disabled || isLoading, handleBulkDelete, message: messageExt, title };
}

export function useBulkDeleteACHClauses(): UseBulkDeleteHook {
  const dispatch = useAppDispatch();
  const { disabled, handleDelete, message, selectedIds, title } = useBulkDeleteBase(
    uiSelectors.selectSelectedItem,
    useDeleteClauseMutation,
    [CLAUSE, CLAUSES],
    ErrorMessages.FileDeleteError
  );

  async function handleBulkDelete() {
    dispatch(showLoader(true));
    selectedIds.forEach((id) => dispatch(showDeletingStatusACH(id)));

    await handleDelete();
    await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);

    dispatch(clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]));
    dispatch(showLoader(false));
  }

  return { disabled, handleBulkDelete, message, title };
}

export function useBulkDeleteSavedSearches(): UseBulkDeleteHook {
  const dispatch = useAppDispatch();
  const { disabled, handleDelete, message, title } = useBulkDeleteBase(
    uploadSelectors.selectedDocs,
    useDeleteSavedSearchMutation,
    [SAVED_SEARCH, SAVED_SEARCHES],
    ErrorMessages.DeleteSavedSearchError,
    [Messages.SavedSearchRemovedSuccess, Messages.SavedSearchRemovedSuccessMulti]
  );

  async function handleBulkDelete() {
    dispatch(showLoader(true));

    await handleDelete();

    dispatch(removeSelectedDocs());
    dispatch(showLoader(false));
  }

  return { disabled, handleBulkDelete, message, title };
}

export function useBulkDeleteReports(): UseBulkDeleteHook {
  const dispatch = useAppDispatch();
  const { disabled, handleDelete, message, title } = useBulkDeleteBase(
    uiSelectors.selectSelectedItem,
    useDeleteReportMutation,
    [REPORT, REPORTS],
    ErrorMessages.ReportDeleteError,
    [Messages.ReportRemovedSuccess, Messages.ReportRemovedSuccessMulti]
  );

  useEffect(() => {
    return () => {
      dispatch(selectDocs([]));
    };
  }, [dispatch]);

  async function handleBulkDelete() {
    dispatch(showLoader(true));

    await handleDelete();

    dispatch(selectDocs([]));
    dispatch(showLoader(false));
  }

  return { disabled, handleBulkDelete, message, title };
}
