import React from 'react';
import './UploadButton.scss';
import { Button } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { UploadButtonProps } from './UploadButton.types';

export const UploadButton: React.FC<UploadButtonProps> = ({ small, onClick }) => {
  return (
    <Button
      className={'upload-files ' + (small ? 'small' : '')}
      variant='contained'
      startIcon={<FileUploadOutlinedIcon fontSize={small ? 'small' : 'large'} />}
      onClick={onClick}
    >
      Upload files
    </Button>
  );
};
