import { MarsMenu } from '../StaticComponents/MarsMenu/MarsMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { SvgIcon } from '@mui/material';
import { Auth } from 'aws-amplify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import './UserProfile.scss';
import { useEffect, useState } from 'react';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { MarsMenuItem } from '../StaticComponents/MarsMenu/MarsMenu.types';
import { cleanupLastUsedProvider } from '../Auth/auth.helpers';

export const UserProfile = () => {
  const { hasReadAccess, hasAdminPanelAccess } = useUserAccessContext();
  const [userName, setUserName] = useState<string>();

  const onLogout = async () => {
    cleanupLastUsedProvider();
    await Auth.signOut();
  };

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      setUserName(data?.attributes?.email);
    });
  }, []);

  return (
    <MarsMenu
      className='user-profile'
      icon={<AccountCircleIcon fontSize='large' style={{ marginRight: '0' }} />}
      endIcon={<KeyboardArrowDownIcon />}
      name=''
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      menuItems={
        [
          {
            label: (
              <div className='user-profile-menu'>
                <AccountCircleIcon />
                {userName}
              </div>
            ),
            href: '#',
            divider: true,
            disabled: true,
          },
          hasAdminPanelAccess && {
            label: (
              <div
                data-test-id={TEST_ID.ADMIN_PANEL_PAGE.ADMIN_PANEL_PAGE_BUTTON_LINK}
                className='user-profile-menu'
              >
                <SvgIcon component={DashboardIcon} />
                Admin Panel
              </div>
            ),
            href: '/admin-panel',
            divider: true,
            disabled: !hasAdminPanelAccess,
          },
          {
            label: (
              <div
                data-test-id={TEST_ID.SAVED_SEARCHES.SETTINGS_PAGE_BUTTON_LINK}
                className='user-profile-menu'
              >
                <SettingsIcon />
                Settings
              </div>
            ),
            href: '/settings',
            divider: true,
            disabled: !hasReadAccess,
          },
          {
            label: (
              <div className='user-profile-logout-menu'>
                <PowerSettingsNewIcon />
                Logout
              </div>
            ),
            href: '#',
            onClick: onLogout,
          },
        ].filter((item) => item !== null) as MarsMenuItem[]
      }
    />
  );
};
