import React, { useState } from 'react';
import { PanelCard } from '../PanelCard/PanelCard';
import { ReactComponent as CreateUserIcon } from '../../../assets/icons/create_user.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/report.svg';
import { PanelSettingsListProps } from './PanelSettingsList.types';

import './PanelSettingsList.scss';
import { GenerateReport } from '../Report/GenerateReport';

export const PanelSettingsList: React.FC<PanelSettingsListProps> = ({
  handleClick,
  selectedCard,
}) => {
  const [renderGenerateReportModal, setRenderGenerateReportModal] = useState<boolean>(false);

  return (
    <div className='panel-settings-list'>
      <PanelCard
        title='Create user'
        handleClick={() => handleClick(0)}
        icon={<CreateUserIcon />}
        selected={selectedCard === 0}
      />
      <PanelCard
        title='Manage users'
        handleClick={() => handleClick(1)}
        icon={<UserIcon />}
        selected={selectedCard === 1}
      />
      <PanelCard
        title='Generate report'
        handleClick={() => setRenderGenerateReportModal(true)}
        icon={<ReportIcon />}
        selected={selectedCard === 2}
      />
      {renderGenerateReportModal && (
        <GenerateReport onClose={() => setRenderGenerateReportModal(false)} />
      )}
    </div>
  );
};
