import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { listApi, useDeleteFileMutation } from '../../../store/files/upload/list.service';
import { useAppDispatch } from '../../../store/hooks';
import { UploadedFileDeleteButtonProps } from './UploadedFileDeleteButton.types';
import { useConfirmationModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.hook';
import { DeleteConfirmModal } from '../../StaticComponents/Modals/ConfirmationModal/DeleteConfirmModal';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { useSnackbar } from 'notistack';
import { UploadedFileStatus } from '../UploadedFileList.types';
import { showStatus } from '../UploadedFileList.helper';
import {
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
  DOCUMENT,
} from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';

const title = DELETE_MODAL_TITLE(DOCUMENT);
const message = DELETE_MODAL_MESSAGE(DOCUMENT);

export const UploadedFileDeleteButton = ({ DocumentId }: UploadedFileDeleteButtonProps) => {
  const dispatch = useAppDispatch();
  const [deleteFile] = useDeleteFileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleOpen, handleClose } = useConfirmationModal();
  const handleDeleteConfirm = async () => {
    dispatch(showStatus(DocumentId, UploadedFileStatus.DELETING));
    deleteFile(DocumentId)
      .unwrap()
      .catch(() => enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' }))
      .then(() => dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST])));
  };
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteOutlineOutlinedIcon
          data-test-id={TEST_ID.UPLOAD_FILES.DELETE_UPLOADED_DOCUMENT}
          fontSize='small'
        />
      </IconButton>
      <DeleteConfirmModal
        open={open}
        onClose={handleClose()}
        onConfirm={handleDeleteConfirm}
        title={title}
        message={message}
      />
    </>
  );
};
