import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { openClauseViewModal } from '../../store/files/clauses/clauses.slice';
import { DocumentContextMenuButton } from '../Documents/DocumentContextMenu/DocumentContextMenuButton';
import { ClauseItem } from './ClauseItem/ClauseItem';
import { RoutePath, getRoutePath } from '../../services/route.service';
import { ContextMenuHandler } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import React from 'react';
import { CLAUSES_LIST_COLUMNS } from './ClausesListGrid.types';
import DOMPurify from 'dompurify';
import { getFilteredPages } from '../../store/ui/ui.helpers';
import { Clause } from '../../store/files/clauses/clauses.list.types';
import { DocumentStatus } from '../../store/files/documents/documents.list.types';

export const COLUMNS_DEFINITION = (
  handleMenuButtonClick: ContextMenuHandler<Clause>,
  searchQuery: string
): GridColDef[] => {
  return [
    {
      flex: 10,
      headerName: 'Clause',
      field: CLAUSES_LIST_COLUMNS.CLAUSE_NAME,
      hideable: false,
      cellClassName: 'clause-item-cell',
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <ClauseItem
            clause={row}
            action={openClauseViewModal({ clause: row })}
            searchQuery={searchQuery}
          />
        );
      },
    },
    {
      flex: 1,
      field: 'ContextMenu',
      headerName: '',
      width: 40,
      sortable: false,
      hideable: false,
      renderCell: ({ row }: GridRenderCellParams) => (
        <DocumentContextMenuButton
          disabled={row.Status === DocumentStatus.DELETING}
          onClick={(event: React.MouseEvent<HTMLElement>) => handleMenuButtonClick(event, row)}
        />
      ),
    },
  ];
};
const clauseBarAllowedPages = [
  RoutePath.CLAUSES,
  getRoutePath(RoutePath.UPLOADED_CLAUSES, RoutePath.UPLOADED_CLAUSES_LIST_VIEW),
  getRoutePath(RoutePath.REPORTED_CLAUSES, RoutePath.UPLOADED_CLAUSES_LIST_VIEW),
  getRoutePath(
    getRoutePath(RoutePath.BOOKMARKS, RoutePath.BOOKMARK_ID) as RoutePath,
    RoutePath.BOOKMARKS_CLAUSES
  ),
];

export const shouldShowSelectedBar = (
  pathname: string,
  minimizedClausesLength: number | undefined
) => getFilteredPages(clauseBarAllowedPages, pathname) && !!minimizedClausesLength;

export const generatePreview = (item: string, keyword?: string) => {
  const maxLength = 300;
  const sanitizedItem = DOMPurify.sanitize(item);

  if (!keyword) {
    return sanitizedItem.length <= maxLength
      ? sanitizedItem
      : sanitizedItem.substring(0, maxLength) + '...';
  }

  const sanitizedKeyword = DOMPurify.sanitize(keyword);
  const keywordWords = sanitizedKeyword.toLowerCase().split(' ');
  const firstKeywordIndex = sanitizedItem.toLowerCase().indexOf(keywordWords[0]);

  if (firstKeywordIndex === -1) {
    return sanitizedItem.length <= maxLength
      ? sanitizedItem
      : sanitizedItem.substring(0, maxLength) + '...';
  }

  const previewStartIndex = Math.max(
    0,
    firstKeywordIndex - (maxLength - keywordWords[0].length) / 2
  );
  const previewEndIndex = previewStartIndex + maxLength;

  let preview = sanitizedItem.substring(previewStartIndex, previewEndIndex);

  if (previewStartIndex > 0) {
    preview = '...' + preview;
  }

  if (previewEndIndex < sanitizedItem.length) {
    preview += '...';
  }

  if (keyword) {
    const regex = new RegExp(`(${keywordWords.join('|')})`, 'gi');
    const sanitizedPreviewWithHighlights = preview.replace(regex, '<strong>$1</strong>');
    return sanitizedPreviewWithHighlights;
  } else {
    return preview;
  }
};
