import { AccessMode, SearchMethod, SortOrder } from './list.types';
import { DocumentsListParams } from '../documents/documents.list.types';

export type GetClauseDTO = DocumentDTO & {
  ExcludeFilters?: ClauseFilters;
  IncludeFilters?: ClauseFilters;
};
export type GetDocumentDTO = DocumentDTO & {
  ExcludeFilters: DocumentFilters;
  IncludeFilters: DocumentFilters;
};

export const isGetDocumentDTO = (
  params: DocumentsListParams | GetDocumentDTO
): params is GetDocumentDTO => {
  return (
    (params as GetDocumentDTO).ExcludeFilters !== undefined &&
    (params as GetDocumentDTO).IncludeFilters !== undefined
  );
};

export interface DocumentDTO {
  AccessMode?: AccessMode;
  BookmarkId?: string;
  PageNumber?: number;
  PageSize?: number;
  Queries?: {
    ExcludePhrases?: string[];
    OriginalLanguage?: OriginalLanguage;
    SearchMethod?: SearchMethod;
    SearchQuery?: string;
  }[];
  SortBy?: string;
  SortOrder?: SortOrder;
}

export enum OriginalLanguage {
  ORIGINAL = 'True',
  TRANSLATED = 'False',
}

export interface DocumentFilters {
  Active?: string;
  BusinessType?: string[];
  Classification?: string[];
  Country?: string[];
  DocumentId?: string[];
  DocumentType?: string[];
  Entity?: string[];
  ExternalId?: string[];
  ExternalVersion?: string[];
  Language?: string[];
  LatestVersion?: string;
  Lob?: string[];
  Lop?: string[];
  ParentDocuments?: string[];
  Reported?: string[];
  RelatedClauses?: string[];
  Status?: string[];
  UploadedBy?: string[];
  VersionKey?: string[];
}

export interface ClauseFilters {
  BusinessTypes?: string[];
  Classification?: string[];
  ClauseId?: string[];
  ClauseTypes?: string[];
  ClauseSources?: string[];
  Countries?: string[];
  DocumentType?: string[];
  Entities?: string[];
  Language?: string[];
  LatestVersion?: string[];
  Lobs?: string[];
  Lops?: string[];
  Provider?: string[];
  Reported?: string[];
  Status?: string[];
  Tag?: string[];
  ClauseStatus?: string[];
}
