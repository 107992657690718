import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '../../store/hooks';
import { uploadSelectors } from '../../store/files/upload/upload.selectors';
import { useGetListQuery } from '../../store/files/upload/list.service';
import { getDocsByStatus } from './UploadedFileList.helper';
import { UploadedFileStatus } from './UploadedFileList.types';

export const useGetUploadedFiles = () => {
  const [pollingInterval, setPollingInterval] = useState(0);
  const localDocs = useAppSelector(uploadSelectors.selectDocsList);
  const uploaded = useAppSelector(uploadSelectors.selectFilesUploaded).length;

  const { data, isLoading } = useGetListQuery(undefined, {
    pollingInterval,
  });

  useEffect(() => {
    const scanning = getDocsByStatus(UploadedFileStatus.SCANNING, data).length;

    if (uploaded || scanning) {
      setPollingInterval(5000);
    } else {
      setPollingInterval(0);
    }
  }, [data, uploaded]);

  const docs = useMemo(
    () => (data?.length ? [...localDocs, ...data] : localDocs),
    [data, localDocs]
  );

  return { docs, isLoading };
};
