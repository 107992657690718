import React, { useCallback, useEffect, useMemo } from 'react';
import { Divider, ListSubheader, MenuItem, TextField, Tooltip } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { countryCodeEmoji } from 'country-code-emoji';
import { parseISO } from 'date-fns';
import './MetadataFormHelpers.scss';
import {
  DictionariesResponse,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';
import {
  CustomTextFieldProps,
  FormNoticeProps,
  FormWarningProps,
  SearchInputFieldProps,
} from './MetadataFormHelpers.types';
import { UploadedFile, UploadedFileStatus } from '../UploadedFileList.types';
import {
  formatDate,
  isDocumentEditable,
} from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { DocumentMetadata, DocumentMetadataFields } from './MetadataForm.types';
import { useSearchInputDict } from '../../StaticComponents/SearchInput/SearchInput.hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { API_DATE_FORMAT } from '../../../config/config';
import { FieldPath, UseFormGetFieldState, UseFormSetValue } from 'react-hook-form';
import { ClauseMetadata } from '../../UploadClause/UploadClause.types';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import {
  MenuItemMetadataDictionaryValue,
  SELECT_ALL,
  SELECT_ALL_GERMAN_COMPANIES,
} from './ClauseMetadataForm.types';
import { SetValueType } from '../../AdminPanel/CreateUserPanel/CreateUserPanel.types';
import { getBusinessTypes, getRelatedLobs } from './ClauseMetadataFormHelpers';
import { getGermanEntitiesSubsetFromFilteredValues } from '../../Documents/DocumentsSearch/DocumentFilter.helpers';
import { ClauseNomenclaturePopover } from './ClauseNomenclaturePopover/ClauseNomenclaturePopover';

export const NOT_SELECTED = 'Not selected';

export enum BOOLEAN_VALUES {
  TRUE = 'True',
  FALSE = 'False',
}

export const cleanupLocalFields = (docs: UploadedFile[]): UploadedFile[] =>
  docs.map(({ action, edit, fileType, ...doc }) => doc);

export const formatApiDate = (date?: Date | null): string => formatDate(date, API_DATE_FORMAT);

export const formatBoolean = (boolValue: boolean | null): string => {
  return boolValue ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE;
};

export const parseDate = (date?: string | null): Date | undefined => {
  return date ? parseISO(date) : undefined;
};

export const requiredRule = (validate: boolean, required: boolean) =>
  validate
    ? {
        required: {
          value: required,
          message: UploadedFileStatus.MISSING_DATA,
        },
      }
    : {};

export const minMaxFieldLengthRule = (
  validate: boolean,
  required: boolean,
  min: number,
  max: number
) =>
  validate
    ? {
        required: {
          value: required,
          message: UploadedFileStatus.MISSING_DATA,
        },
        maxLength: {
          value: max,
          message: `Field length should be between ${min} and ${max} `,
        },
        minLength: {
          value: min,
          message: `Field length should be between ${min} and ${max} `,
        },
      }
    : {};

export const apiValidationRule = (
  getFieldState: UseFormGetFieldState<ClauseMetadata>,
  field: FieldPath<ClauseMetadata>,
  inputNameError: string | undefined,
  serverField: string,
  apiErrorMessage: string | undefined
) => ({
  validate: () => {
    return !getFieldState(field).isDirty && inputNameError === serverField
      ? apiErrorMessage
      : undefined;
  },
});

export const cleanupEmpty = (values: DocumentMetadata): DocumentMetadata => {
  let key: keyof DocumentMetadata;
  for (key in values) {
    if (!values[key]) {
      delete values[key];
    }
  }
  return values;
};

export const prepareBulkRequest = (values: DocumentMetadata, documents: UploadedFile[] = []) => {
  const uploadedFile = cleanupEmpty(values);
  const filteredDocs = documents.filter(({ Status }) => isDocumentEditable(Status));
  return filteredDocs.map((doc) => ({ ...doc, ...uploadedFile }));
};

export const FormWarning: React.FC<FormWarningProps> = ({ error }) => {
  return !!error ? (
    <span
      data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_DUPLICATE_INFO}
      className='form-warning'
    >
      <WarningAmberOutlinedIcon /> {error.message}
    </span>
  ) : null;
};

export const FormNotice: React.FC<FormNoticeProps> = ({ tooltipMessage }) => {
  return (
    <span className='form-notice'>
      <Tooltip title={<div className='form-notice-tooltip'>{tooltipMessage}</div>} placement='top'>
        <InfoOutlinedIcon fontSize='inherit' />
      </Tooltip>
    </span>
  );
};

export const DictionaryMenuItem = (v: MetadataDictionaryValue, disabled?: boolean) => (
  <MenuItem key={v.value} value={v.value} disabled={!disabled && v.disabled}>
    {v.label}
  </MenuItem>
);

export const CountryMenuItem = (v: MetadataDictionaryValue, disabled = false) => (
  <MenuItem key={v.value} value={v.value} disabled={disabled}>
    {countryCodeEmoji(v.value)} {v.label}
  </MenuItem>
);

export const ParentDocuemntMenuItem = (v: MenuItemMetadataDictionaryValue, disabled?: boolean) => (
  <MenuItem
    key={v.value}
    value={v.value}
    disabled={disabled}
    className='menu-item--parent-documents'
  >
    <span>
      {v.label}
      <small>{v.label2 ? v.label2 : ''}</small>
    </span>
  </MenuItem>
);

export const SearchInputField: React.FC<SearchInputFieldProps> = ({ label, onChangeHandle }) => (
  <ListSubheader value=''>
    <TextField
      onChange={onChangeHandle}
      fullWidth
      size='small'
      autoFocus
      label={`Search for ${label}`}
      onKeyDown={(e) => e.stopPropagation()}
    />
  </ListSubheader>
);

const GroupHeader = (label: string, divider?: boolean) => [
  divider ? <Divider key={`${label}-divider`} /> : undefined,
  <div key={label} className='label'>
    {label}
  </div>,
];

export const CustomTextField: React.FC<CustomTextFieldProps> = React.forwardRef(
  (
    {
      helperWarning,
      tooltipMessage,
      value,
      values,
      isCountry,
      hasCounter,
      hasNamingGuide,
      multiselect,
      groups,
      isParentDocuments,
      selectAll,
      entitiesDictionary,
      ...props
    },
    ref
  ) => {
    const { filteredValues = [], onChangeSearch } = useSearchInputDict(values ?? []);
    const CustomMenuItem = isParentDocuments
      ? ParentDocuemntMenuItem
      : isCountry
      ? CountryMenuItem
      : DictionaryMenuItem;

    const selectableFilteredValues = useMemo(() => {
      return (
        filteredValues?.filter((value: MenuItemMetadataDictionaryValue) => !value.isNonEditable) ||
        []
      );
    }, [filteredValues]);

    const selectableGermanCompanies = useMemo(() => {
      return getGermanEntitiesSubsetFromFilteredValues(selectableFilteredValues);
    }, [selectableFilteredValues]);

    const mapToMenuItem = useCallback(
      (item: MenuItemMetadataDictionaryValue) => CustomMenuItem(item, item.isNonEditable),
      [CustomMenuItem]
    );

    const menuItems = useMemo(
      () =>
        groups
          ? groups.reduce<(JSX.Element | undefined)[]>((previousValue, label, i) => {
              const items = (filteredValues as MenuItemMetadataDictionaryValue[])
                .filter(({ group }) => group === label)
                .map(mapToMenuItem);

              return [...previousValue, ...GroupHeader(label, i !== 0), ...items];
            }, [])
          : filteredValues.map(mapToMenuItem),
      [filteredValues, groups, mapToMenuItem]
    );

    return (
      <>
        <TextField
          {...props}
          ref={ref}
          fullWidth
          size='small'
          variant='filled'
          SelectProps={{ multiple: multiselect, displayEmpty: true }}
          value={value}
          helperText={
            <>
              <FormWarning error={helperWarning} />
              <span className='guide-container'>
                {hasNamingGuide && <ClauseNomenclaturePopover />}
                {tooltipMessage && <FormNotice tooltipMessage={tooltipMessage} />}
              </span>
            </>
          }
        >
          <SearchInputField
            label={(props?.label as String)?.toLowerCase()}
            onChangeHandle={onChangeSearch}
          />

          {selectAll &&
            multiselect &&
            selectableFilteredValues &&
            selectableFilteredValues.length > 1 && (
              <MenuItem value={SELECT_ALL}>
                <em>{SELECT_ALL}</em>
              </MenuItem>
            )}

          {selectableGermanCompanies?.length > 1 && (
            <MenuItem value={SELECT_ALL_GERMAN_COMPANIES}>
              <em>{SELECT_ALL_GERMAN_COMPANIES}</em>
            </MenuItem>
          )}

          {menuItems}
        </TextField>

        {hasCounter && (
          <div className='custom-text-field-counter'>
            {(value as string).length}/{props?.inputProps?.maxLength}
          </div>
        )}
      </>
    );
  }
);

export const isStringifiedBoolFieldTrue = (value?: string | string[]) =>
  value === BOOLEAN_VALUES.TRUE;
export const stringifyBoolField = (checked: boolean) =>
  checked ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE;

export const filterParentDocuemntsValues = (
  parentDocuments: string[],
  setValue: SetValueType<DocumentMetadata>,
  shouldSelectDefaultParentDocumentsOpt: boolean
) => {
  const indexOfNotSelectedOpt = parentDocuments.indexOf(NOT_SELECTED);
  if (indexOfNotSelectedOpt === 0 && parentDocuments.length > 1) {
    setValue(DocumentMetadataFields.ParentDocuments, parentDocuments.slice(1));
  } else if (
    (indexOfNotSelectedOpt !== 0 && indexOfNotSelectedOpt !== -1 && parentDocuments.length > 1) ||
    shouldSelectDefaultParentDocumentsOpt
  ) {
    setValue(DocumentMetadataFields.ParentDocuments, [NOT_SELECTED]);
  }
};

export const useFormFiltering = (
  dictionaries: DictionariesResponse | undefined,
  { Entity, Lob, BusinessType }: DocumentMetadata,
  setValue: UseFormSetValue<DocumentMetadata>
) => {
  const [relatedLobs, relatedBusinessTypes] = useMemo(() => {
    const entities = Entity ? [Entity] : [];
    return [
      getRelatedLobs(dictionaries?.Entity.values, entities),
      getBusinessTypes(dictionaries?.Entity.values, entities),
    ];
  }, [Entity, dictionaries?.Entity.values]);

  const lobValues = useMemo(
    () =>
      relatedLobs.length
        ? dictionaries?.Lob.values.filter(({ value }) => relatedLobs.includes(value))
        : dictionaries?.Lob.values,
    [dictionaries?.Lob.values, relatedLobs]
  );

  const businessTypesValues = useMemo(
    () =>
      relatedBusinessTypes.length
        ? dictionaries?.BusinessType.values.filter(({ value }) =>
            relatedBusinessTypes.includes(value)
          )
        : dictionaries?.BusinessType.values,
    [dictionaries?.BusinessType.values, relatedBusinessTypes]
  );

  useEffect(() => {
    if (!Lob && relatedLobs.length === 1) {
      setValue(DocumentMetadataFields.Lob, relatedLobs[0], { shouldValidate: true });
    }
  }, [Lob, relatedLobs, setValue]);

  useEffect(() => {
    if (!BusinessType && relatedBusinessTypes.length === 1) {
      setValue(DocumentMetadataFields.BusinessType, relatedBusinessTypes[0], {
        shouldValidate: true,
      });
    }
  }, [BusinessType, relatedBusinessTypes, setValue]);

  return { noEntitySelected: !Entity, lobValues, businessTypesValues };
};
