import { documentsListApi, useGetDocumentsListQuery } from './documents.list.service';
import {
  DOCUMENTS_LIST_API_TAGS,
  DocumentsListParams,
  DocumentStatus,
} from './documents.list.types';
import { AccessMode } from '../documentsAndClauses/list.types';
import { documentsSelectors } from './documents.selectors';
import {
  updateListQueryParams,
  updatePendingListQueryParams,
  updateReportedDocumentsListQueryParams,
  updateUploadedDocumentsListQueryParams,
} from './documents.slice';
import { bookmarksSelectors } from '../bookmarks/bookmars.selectors';
import { updateDocumentsForBookmarkListQueryParams } from '../bookmarks/bookmarks.slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  preparePaginationForGrid,
  prepareQueryParams,
  prepareQueryParamsWithFirstPage,
  prepareSortForGrid,
} from '../documentsAndClauses/list.hooks.helpers';
import {
  GetDocumentsListWithParamsBaseHookParams,
  LoadingWithTimeoutHookReturn,
} from './documents.hooks.types';
import {
  ACH_METADATA_EDIT_DELAY,
  PENDING_LIST_REFRESH_INTERVAL,
  TAB_ITEMS_PAGE_SIZE,
} from '../../../config/config';
import { useCallback, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { BOOLEAN_VALUES } from '../../../components/UploadFiles/MetadataForm/MetadataFormHelpers';
import { CLAUSES_LIST_API_TAGS } from '../clauses/clauses.list.types';
import { clausesListApi } from '../clauses/clauses.list.service';

function useGetDocumentsListWithParamsBase({
  defaultParams,
  queryOptions,
  selector,
  action,
}: GetDocumentsListWithParamsBaseHookParams) {
  const dispatch = useAppDispatch();

  const params = useAppSelector(selector);
  const { PageNumber, PageSize, SortBy, SortOrder, ...filters } = params;

  const queryParameters: DocumentsListParams = { ...params, ...defaultParams };

  const query = useGetDocumentsListQuery(queryParameters, {
    refetchOnMountOrArgChange: true,
    ...queryOptions,
  });
  const rowCount = query.data?.TotalDocumentCount ?? 0;

  const { page, pageSize } = preparePaginationForGrid(PageNumber, PageSize);
  const sort = prepareSortForGrid(SortBy, SortOrder);

  const setPage = (pageNumber: number) =>
    dispatch(action(prepareQueryParams<DocumentsListParams>(filters, pageNumber, pageSize, sort)));
  const setPageSize = (size: number) =>
    dispatch(action(prepareQueryParamsWithFirstPage<DocumentsListParams>(filters, size, sort)));
  const setFilters = (newFilters: DocumentsListParams) =>
    dispatch(
      action(prepareQueryParamsWithFirstPage<DocumentsListParams>(newFilters, pageSize, sort))
    );
  const setSort = (model: GridSortModel) =>
    dispatch(
      action(prepareQueryParamsWithFirstPage<DocumentsListParams>(filters, pageSize, model))
    );

  return {
    ...query,
    defaultParams,
    rowCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    filters,
    setFilters,
    sort,
    setSort,
  };
}

export function useGetDocumentsListWithParams(relatedDocuments?: string[]) {
  return useGetDocumentsListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.READ,
      Reported: BOOLEAN_VALUES.FALSE,
      DocumentId: relatedDocuments || undefined,
    },
    selector: documentsSelectors.selectListQueryParams,
    action: updateListQueryParams,
  });
}

export function useGetDocumentsListForVersionsWithParams(ExternalId: string) {
  return useGetDocumentsListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.READ,
      ExternalId,
      PageSize: TAB_ITEMS_PAGE_SIZE.toString(),
      Reported: BOOLEAN_VALUES.FALSE,
      Queries: [],
    },
    queryOptions: {
      skip: !ExternalId,
    },
    selector: documentsSelectors.selectVersionsParams,
    action: updateListQueryParams,
  });
}

export function useGetPendingDocumentsListWithParams() {
  return useGetDocumentsListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.PROCESSING, DocumentStatus.FAILED],
      AccessMode: AccessMode.WRITE,
      Queries: [],
    },
    queryOptions: {
      pollingInterval: PENDING_LIST_REFRESH_INTERVAL,
    },
    selector: documentsSelectors.selectPendingListQueryParams,
    action: updatePendingListQueryParams,
  });
}

export function useGetUploadedDocumentsListWithParams() {
  return useGetDocumentsListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.WRITE,
      Reported: BOOLEAN_VALUES.FALSE,
    },
    selector: documentsSelectors.selectUploadedDocumentsListQueryParams,
    action: updateUploadedDocumentsListQueryParams,
  });
}

export function useGetReportedDocumentsListWithParams() {
  return useGetDocumentsListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.WRITE,
      Reported: BOOLEAN_VALUES.TRUE,
    },
    selector: documentsSelectors.selectReportedDocumentsListQueryParams,
    action: updateReportedDocumentsListQueryParams,
  });
}

export function useGetDocumentsForBookmarkListWithParams(BookmarkId: string) {
  return useGetDocumentsListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.READ,
      Reported: BOOLEAN_VALUES.FALSE,
      BookmarkId,
    },
    selector: bookmarksSelectors.selectDocumentsForBookmarkListQueryParams,
    action: updateDocumentsForBookmarkListQueryParams,
  });
}

export const useLoadingWithTimeout = (clauseMode?: boolean): LoadingWithTimeoutHookReturn => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  const loadWithTimeout = useCallback(
    (fn: () => void) => {
      setTimeout(() => {
        if (clauseMode) {
          dispatch(clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]));
        } else {
          dispatch(documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST]));
        }

        setIsLoading(false);
        fn();
      }, ACH_METADATA_EDIT_DELAY);
    },
    [dispatch, clauseMode]
  );

  return { isLoading, startLoading, stopLoading, loadWithTimeout };
};
