import React, { useState } from 'react';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { PanelSettingsList } from './PanelSettingsList/PanelSettingsList';

import { CreateUserPanel } from './CreateUserPanel/CreateUserPanel';

import { ReactComponent as TriangleIcon } from '../../assets/icons/triangle.svg';

import './AdminPanel.scss';
import { ManageUsersGroups } from './ManageUsersGroups/ManageUsersGroups';
import { useAdminPanelAccess } from '../../contexts/UserAccess';

export const AdminPanel = () => {
  useAdminPanelAccess();
  const [selectedCard, setSelectedCard] = useState(0);

  const handleCardSelect = (index: number) => {
    setSelectedCard(index);
  };

  return (
    <div className='admin-panel'>
      <Paper elevation={0} className='settings-header'>
        <span className='admin-panel-title'>Admin Panel</span>
      </Paper>

      <Box className='settings-container'>
        <PanelSettingsList handleClick={handleCardSelect} selectedCard={selectedCard} />
        <div className='slider'>
          <div className='slider--line'>
            <TriangleIcon className={`selected-${selectedCard}`} />
          </div>
        </div>
      </Box>
      {selectedCard === 0 ? (
        <Paper elevation={0} sx={{ minWidth: 600, borderRadius: 4 }}>
          <CreateUserPanel />
        </Paper>
      ) : selectedCard === 1 ? (
        <ManageUsersGroups />
      ) : (
        <></>
      )}
    </div>
  );
};
