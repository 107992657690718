import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  CLAUSE_FILE_GET_ENDPOINT,
  CLAUSE_FILE_REPORT_DELETE_ENDPOINT,
  CLAUSE_FILE_REPORT_ENDPOINT,
  CLAUSE_INHERITED_METADATA_ENDPOINT,
  CLAUSES_CREATE_ENDPOINT,
  CLAUSES_LIST_ENDPOINT,
  CLAUSES_UPDATE_ENDPOINT,
  CLAUSE_FILE_TAG_ENDPOINT,
} from '../../../services/api.service.config';
import {
  ClauseFileResponse,
  ClauseInheritedMetadataResponse,
  ClauseMetadataUpdateRequest,
  ClauseMetadataUpdateResponse,
  CLAUSES_LIST_API_ENDPOINTS,
  CLAUSES_LIST_API_TAGS,
  ClausesListParams,
  ClausesListResponse,
  CreateClauseRequest,
  Tags,
} from './clauses.list.types';

import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';
import { mapClauseListParamsToGetClauseDTO } from '../documentsAndClauses/list.helpers';
import { transformTagsBody } from '../documentsAndClauses/list.helpers';
import { FILE_REQUEST_CACHE_EXPIRATION, ReportRequest } from '../documents/documents.list.types';
import { timeoutPromise } from '../../../helpers/app.helpers';

const getClauseFileTag = (id: string) => [{ type: CLAUSES_LIST_API_TAGS.CLAUSE_FILE, id }];

export const clausesListApi = createApi({
  reducerPath: 'clausesListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CLAUSES_LIST_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [CLAUSES_LIST_API_TAGS.CLAUSES_LIST, CLAUSES_LIST_API_TAGS.CLAUSE_FILE],
  endpoints: (builder) => ({
    [CLAUSES_LIST_API_ENDPOINTS.GET_CLAUSES_LIST]: builder.query<
      ClausesListResponse,
      ClausesListParams
    >({
      query: (values) => ({
        url: CLAUSES_LIST_ENDPOINT,
        body: mapClauseListParamsToGetClauseDTO(values),
        method: 'POST',
      }),
      providesTags: [CLAUSES_LIST_API_TAGS.CLAUSES_LIST],
    }),
    [CLAUSES_LIST_API_ENDPOINTS.DELETE_CLAUSE]: builder.mutation<void, string>({
      query: (id) => ({
        url: CLAUSES_UPDATE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
    }),
    [CLAUSES_LIST_API_ENDPOINTS.UPDATE_CLAUSES_METADATA]: builder.mutation<
      ClauseMetadataUpdateResponse,
      { clauseId: string; body: ClauseMetadataUpdateRequest }
    >({
      query: ({ clauseId, body }) => ({
        url: CLAUSES_UPDATE_ENDPOINT.replace('{id}', clauseId),
        method: 'PUT',
        body: body,
      }),
    }),

    [CLAUSES_LIST_API_ENDPOINTS.CREATE_CLAUSE]: builder.mutation<
      ClausesListResponse,
      CreateClauseRequest
    >({
      query: (clause) => ({
        url: CLAUSES_CREATE_ENDPOINT,
        method: 'POST',
        body: clause,
      }),
    }),
    [CLAUSES_LIST_API_ENDPOINTS.GET_CLAUSE_FILE]: builder.query<ClauseFileResponse, string>({
      query: (id) =>
        CLAUSE_FILE_GET_ENDPOINT.replace('{id}', id) +
        `?ExpiresIn=${FILE_REQUEST_CACHE_EXPIRATION}`,
      keepUnusedDataFor: FILE_REQUEST_CACHE_EXPIRATION,
      providesTags: (result, error, id) => getClauseFileTag(id),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await timeoutPromise(FILE_REQUEST_CACHE_EXPIRATION * 1000);
        dispatch(clausesListApi.util.invalidateTags(getClauseFileTag(id)));
      },
    }),
    [CLAUSES_LIST_API_ENDPOINTS.REPORT_CLAUSE]: builder.mutation<void, ReportRequest>({
      query: (body) => ({
        url: CLAUSE_FILE_REPORT_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
    [CLAUSES_LIST_API_ENDPOINTS.DELETE_CLAUSE_REPORT]: builder.mutation<void, string>({
      query: (id) => ({
        url: CLAUSE_FILE_REPORT_DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
    }),
    [CLAUSES_LIST_API_ENDPOINTS.GET_INHERITED_METADATA]: builder.query<
      ClauseInheritedMetadataResponse,
      string
    >({
      query: (id) => ({
        url: CLAUSE_INHERITED_METADATA_ENDPOINT.replace('{id}', id),
        method: 'GET',
      }),
    }),
    [CLAUSES_LIST_API_ENDPOINTS.GET_CLAUSE_TAGS]: builder.query<Tags, void>({
      query: () => ({
        url: CLAUSE_FILE_TAG_ENDPOINT,
        method: 'GET',
      }),
      transformResponse: (response: string[]) => transformTagsBody(response),
    }),
  }),
});

export const {
  useGetClausesListQuery,
  useGetClauseFileQuery,
  useDeleteClauseMutation,
  useUpdateClausesMetadataMutation,
  useCreateClauseMutation,
  useReportClauseMutation,
  useDeleteClauseReportMutation,
  useGetInheritedMetadataQuery,
  useGetClauseTagsQuery,
} = clausesListApi;
