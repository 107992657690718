import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setCompareMode, setLanguageLock } from '../../../../store/ui/ui.slice';
import { useAppDispatch } from '../../../../store/hooks';
import { uiSelectors } from '../../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../../store/hooks';
import { CheckboxWithLabel } from '../../../StaticComponents/CheckboxWithLabel';

import './CompareOptionsBar.scss';

import { COMPARE_MODE, CompareOptionsBarProps } from './CompareOptionsBar.types';

export const CompareOptionsBar: React.FC<CompareOptionsBarProps> = ({
  disabled = false,
  isDocumentMode = false,
}) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(uiSelectors.selectCompareMode);
  const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCompareMode((event.target as HTMLInputElement).value as COMPARE_MODE));
  };

  const handleLanguageLockChange = (checked: boolean) => {
    dispatch(setLanguageLock(checked));
  };

  return !disabled ? (
    <div className='compare-options-bar'>
      <RadioGroup
        row
        aria-labelledby='compare-options-label'
        name='compare-options-group'
        onChange={handleModeChange}
        value={mode}
      >
        <FormControlLabel
          value={COMPARE_MODE.SHOW_ORIGINAL}
          control={<Radio />}
          label='Show original'
        />
        <FormControlLabel
          value={COMPARE_MODE.SHOW_DIFFERENCES}
          control={<Radio />}
          label='Show differences'
        />
        <FormControlLabel
          value={COMPARE_MODE.SHOW_SIMILARITIES}
          control={<Radio />}
          label='Show similarities'
        />
        {!isDocumentMode ? (
          <FormControlLabel
            value={COMPARE_MODE.SHOW_REPLACEMENTS}
            control={<Radio />}
            label='Show replacements'
          />
        ) : null}
      </RadioGroup>
      <CheckboxWithLabel
        checked={isLanguageLocked}
        onChange={(_, checked) => handleLanguageLockChange(checked)}
        label='Lock tab'
      />
    </div>
  ) : null;
};
