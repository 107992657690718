import { Navigate } from 'react-router-dom';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { initialPage } from '../Auth/auth.helpers';
import { RoutePath } from '../../services/route.service';

export const MainPageRedirect = () => {
  const { hasDocumentReadAccess } = useUserAccessContext();
  const mainPage = hasDocumentReadAccess ? RoutePath.DOCUMENTS : RoutePath.CLAUSES;
  return <Navigate to={initialPage || mainPage} />;
};
