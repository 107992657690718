import React, { useState } from 'react';
import './DragAndDrop.scss';
import { useDropzone } from 'react-dropzone';
import { DragAndDropProps } from './DragAndDrop.types';
import { convertToMB, wasUploadRejectedDueToTotalNumber } from './DragAndDrop.helper';
import { UploadingModalError } from '../UplodingErrorModal/UploadingModalError';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadFile, clearFailed } from '../../../store/files/upload/upload.slice';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { MAXIMUM_NO_OF_UPLOADING_DOCS } from '../../../config/config';
import { showModal } from '../../../store/ui/ui.slice';

export const DragAndDrop: React.FC<DragAndDropProps> = ({ filesTypes, maxFilesSize }) => {
  const [filesRejected, setFilesRejected] = useState<string[]>([]);
  const filesMimeTypes = filesTypes?.map(({ mimeType }) => mimeType);
  const filesMimeNames = filesTypes?.map(({ name }) => name);
  const maxFilesSizeMB = convertToMB(maxFilesSize);
  const dispatch = useAppDispatch();
  const failedFiles = useAppSelector(uploadSelectors.selectFailedFiles);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    accept: filesMimeTypes,
    maxSize: maxFilesSize,
    multiple: true,
    maxFiles: MAXIMUM_NO_OF_UPLOADING_DOCS,
    onDrop: (accepted) => {
      accepted.forEach((f) => dispatch(uploadFile(f)));
    },
    onDropRejected: (frs) => {
      if (wasUploadRejectedDueToTotalNumber(frs)) {
        dispatch(
          showModal({
            title: 'Uploading error',
            reason: `Too many files selected. Please select up to ${MAXIMUM_NO_OF_UPLOADING_DOCS} documents.`,
          })
        );
      } else {
        setFilesRejected(frs.map(({ file: { name } }) => name));
      }
    },
  });

  const numberOfFilesDropped = fileRejections?.length + acceptedFiles?.length;

  let styles = isFocused ? 'focus ' : '';
  styles += isDragActive || isDragAccept ? 'accept-style ' : '';
  styles += isDragReject ? 'reject-style ' : '';

  return (
    <>
      {filesRejected ? (
        <UploadingModalError
          files={filesRejected}
          numberOfFilesDropped={numberOfFilesDropped}
          message={`- file has to be ${filesMimeTypes} and max size ${maxFilesSizeMB}MB`}
          onClose={() => setFilesRejected([])}
        />
      ) : null}
      {failedFiles ? (
        <UploadingModalError
          files={failedFiles}
          numberOfFilesDropped={numberOfFilesDropped}
          onClose={() => dispatch(clearFailed())}
        />
      ) : null}
      <div className='drag-and-drop' data-test-id={TEST_ID.UPLOAD_FILES.DRAG_AND_DROP}>
        <div {...getRootProps()} className={`drag-and-drop-box ${styles}`}>
          <input {...getInputProps()} />
          <p className='dnd-msg-title'>
            Drag & Drop your files here or <button>browse</button>
          </p>
          <p className='dnd-msg-subtitle'>
            Files supported: {filesMimeNames?.join(',')?.toUpperCase()}
            <br />
            Max file size: {maxFilesSizeMB}MB
          </p>
          <p className='dnd-msg-subtitle sensitive-data-warning'>
            You confirm that documents don't contain personal data
          </p>
        </div>
      </div>
    </>
  );
};
