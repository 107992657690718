import { ValidationErrorResponse } from './files/documents/documents.list.types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  mapErrorResponseToErrorMessage,
  mapErrorResponseToErrorMessageDetails,
} from './files/documents/documents.update.error.helpers';
import axios, { AxiosError } from 'axios';
import { ClauseUploadApiErrorResponse } from './error.types';

export enum ERRORS {
  NO_INTERNET_CONNECTION = 'No internet connection',
  REQUEST_ERROR = 'Request error',
}

const requestError = (message?: string) => ERRORS.REQUEST_ERROR + (message ? `: ${message}` : '');

export const handleResponseError = (error: Error | AxiosError): string => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.data?.errors?.length) {
        return mapErrorResponseToErrorMessage(error.response.data.errors);
      }
    } else if (error.request) {
      return ERRORS.NO_INTERNET_CONNECTION;
    }
  }
  return requestError(error.message);
};

export const handleApiError = (
  error: FetchBaseQueryError | SerializedError,
  single?: boolean
): string => {
  if ('status' in error) {
    if (error?.status === 'FETCH_ERROR') {
      return ERRORS.NO_INTERNET_CONNECTION;
    } else if (error.data) {
      const validationErrorResponse = error.data as ValidationErrorResponse;
      if (validationErrorResponse?.errors?.length) {
        const { errors } = validationErrorResponse;
        return single
          ? mapErrorResponseToErrorMessageDetails(errors)
          : mapErrorResponseToErrorMessage(errors);
      }
    }
    return requestError('error' in error ? error.error : '');
  }
  return requestError(error.message);
};

export const getFirstParamNameFromError = (error: ClauseUploadApiErrorResponse) => {
  return error?.data?.errors?.[0]?.errorDetails?.paramName || '';
};
