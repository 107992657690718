import React, { useEffect, useState } from 'react';
import { UploadMetadata } from './UploadMetadata';
import { UploadedFileList } from './UploadedFileList';
import { DragAndDrop } from './DragAndDrop/DragAndDrop';
import { UploadProgress } from './UploadProgress';
import { MetadataEditModal } from './MetadataEditModal/MetadataEditModal';
import { MAX_FILES_SIZE, MIME_TYPES, TAB_NAMES } from '../../config/config';
import { Paper } from '@mui/material';
import './UploadFiles.scss';
import { SpreadSheetView } from '../SpreadSheet/SpreadSheetView';
import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { UploadSubmitDocuments } from './UploadSubmitDocuments/UploadSubmitDocuments';
import { DOCUMENTS_EDIT_SOURCE } from '../SpreadSheet/SpreadSheetView.types';
import { useGetListQuery } from '../../store/files/upload/list.service';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { useAppSelector } from '../../store/hooks';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { useDocumentWriteAccess } from '../../contexts/UserAccess';
import { useCustomPageLeavePrompt } from '../StaticComponents/Modals/ConfirmationModal/CustomPageLeavePrompt.hook';
import { ConfirmationModal } from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal';
import { uiSelectors } from '../../store/ui/ui.selectors';
import {
  LEAVE_PAGE_MODAL_MESSAGE_SUBMIT_IN_PROGRESS,
  LEAVE_PAGE_MODAL_MESSAGE_UNSAVED_DATA,
  LEAVE_PAGE_MODAL_MESSAGE_UPLOAD_IN_PROGRESS,
  LEAVE_PAGE_MODAL_SUBMIT_BUTTON_LABEL,
  LEAVE_PAGE_MODAL_TITLE,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { uploadSelectors } from '../../store/files/upload/upload.selectors';
import { getDocsByStatus } from './UploadedFileList.helper';
import { UploadedFileStatus } from './UploadedFileList.types';
import { RoutePath } from '../../services/route.service';

export const UploadFiles = () => {
  useDocumentWriteAccess();
  const { data: docs } = useGetListQuery();
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const toggleFullscreen = () => setFullscreen(!fullscreen);
  const [bulkEditOpen, setBulkEditOpen] = useState<boolean>(false);

  const unsavedSpreadsheetData = useAppSelector(uiSelectors.selectUnsavedChanges);
  const uploadInProgress = useAppSelector(uploadSelectors.selectFilesUploading).length > 0;
  const submitInProgress = getDocsByStatus(UploadedFileStatus.SUBMITTING, docs).length > 0;

  const { setConfirmedNavigationState, open, handleClose } = useCustomPageLeavePrompt(
    unsavedSpreadsheetData || uploadInProgress || submitInProgress
  );

  const promptMessage = uploadInProgress
    ? LEAVE_PAGE_MODAL_MESSAGE_UPLOAD_IN_PROGRESS
    : submitInProgress
    ? LEAVE_PAGE_MODAL_MESSAGE_SUBMIT_IN_PROGRESS
    : LEAVE_PAGE_MODAL_MESSAGE_UNSAVED_DATA;

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (open && !message) {
      setMessage(promptMessage);
    }
  }, [message, open, promptMessage]);

  return (
    <div>
      <UploadMetadata
        fullscreen={fullscreen}
        bulkEditOpen={bulkEditOpen}
        setBulkEditOpen={setBulkEditOpen}
      />
      <DragAndDrop maxFilesSize={MAX_FILES_SIZE} filesTypes={[MIME_TYPES.PDF]} />
      <Tabs
        base={RoutePath.UPLOAD_FILES}
        components={[
          {
            label: TAB_NAMES.LIST_VIEW,
            url: RoutePath.UPLOAD_FILES_LIST_VIEW,
            component: (
              <Paper
                elevation={0}
                className='uploaded-file-list'
                data-test-id={TEST_ID.UPLOAD_FILES.DOCUMENT_LIST_VIEW}
              >
                <UploadedFileList data-test-id={TEST_ID.UPLOAD_FILES.DRAG_AND_DROP} />
                <UploadSubmitDocuments isSnackbarEnabled={!bulkEditOpen} />
                <UploadProgress />
                <MetadataEditModal />
              </Paper>
            ),
          },
          {
            label: TAB_NAMES.SPREADSHEET_VIEW,
            url: RoutePath.UPLOAD_FILES_SPREADSHEET_VIEW,
            component: docs?.length ? (
              <SpreadSheetView
                editMode={DOCUMENTS_EDIT_SOURCE.PRE_LANDING}
                fullscreen={fullscreen}
                toggleFullscreen={toggleFullscreen}
                isSnackbarEnabled={!bulkEditOpen}
              />
            ) : (
              <Paper elevation={0} className='uploaded-file-list-no-docs uploaded-file-list'>
                <NoItemsFound subtitle='' />
              </Paper>
            ),
          },
        ]}
      />
      <ConfirmationModal
        open={open}
        onClose={handleClose(() => setMessage(''))}
        onConfirm={setConfirmedNavigationState}
        title={LEAVE_PAGE_MODAL_TITLE}
        message={message}
        successButtonLabel={LEAVE_PAGE_MODAL_SUBMIT_BUTTON_LABEL}
      />
    </div>
  );
};
