import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DocumentFilterProps, FirstParam, MENU_ITEM_GROUP } from './DocumentsSearch.types';
import {
  CountryMenuItem,
  DictionaryMenuItem,
  SearchInputField,
} from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  getGermanEntitiesSubsetFromFilteredValues,
  getValueOrEmptyArray,
  handleAllGermansSelection,
  prepareLabel,
} from './DocumentFilter.helpers';
import { useSearchInputDict } from '../../StaticComponents/SearchInput/SearchInput.hooks';
import { DOCUMENT_LABELS } from '../../../config/config';

export const DocumentFilter = React.forwardRef(
  (
    {
      multiple,
      label,
      value,
      onChange,
      values = [],
      isCountry,
      firstParam = FirstParam.ALL,
      disabled,
      entityDictionary,
      onClose,
      onOpen,
      isSearch = false,
    }: DocumentFilterProps,
    ref
  ) => {
    const { inputText, setInputText, filteredValues, onChangeSearch } = useSearchInputDict(values);

    const selectableGermanCompanies = getGermanEntitiesSubsetFromFilteredValues(filteredValues);

    const handleChange = ({ target: { value: newValue } }: SelectChangeEvent<typeof value>) => {
      if (multiple && label === DOCUMENT_LABELS.COMPANY_NAME && entityDictionary) {
        newValue = handleAllGermansSelection(newValue, selectableGermanCompanies);
      }
      onChange(multiple ? getValueOrEmptyArray(newValue, firstParam) : newValue);
    };

    return (
      <Select
        ref={ref}
        size='small'
        disabled={disabled}
        className={value?.length ? 'filter-values-selected' : ''}
        multiple={multiple}
        displayEmpty
        value={value}
        onOpen={() => onOpen?.()}
        onChange={handleChange}
        onClose={() => {
          onClose?.(value);
          setInputText('');
        }}
        input={<OutlinedInput />}
        renderValue={(selected) => (
          <>
            {label}:{' '}
            <strong>{prepareLabel(isCountry, multiple, values, selected, firstParam)}</strong>
          </>
        )}
      >
        <SearchInputField label={label.toLowerCase()} onChangeHandle={onChangeSearch} />
        {!inputText && firstParam && (
          <MenuItem value=''>
            <em>{firstParam === FirstParam.ALL ? MENU_ITEM_GROUP.ALL : MENU_ITEM_GROUP.NONE}</em>
          </MenuItem>
        )}
        {multiple && !inputText && selectableGermanCompanies.length > 1 && (
          <MenuItem value={MENU_ITEM_GROUP.ALL_GERMAN}>
            <em>{MENU_ITEM_GROUP.ALL_GERMAN}</em>
          </MenuItem>
        )}
        {filteredValues.map((item) =>
          isCountry ? CountryMenuItem(item) : DictionaryMenuItem(item, isSearch)
        )}
      </Select>
    );
  }
);
