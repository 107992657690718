import { MenuItem } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { closeDocumentViewModal } from '../../../store/files/documents/documents.slice';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../services/route.service';
import { useMinimizeItemForVersion } from '../../StaticComponents/Modals/MinimizeItem/useMinimizeItem';
import { useGetDocumentsListWithParams } from '../../../store/files/documents/documents.hooks';

import { useCheckIfDocumentIsParent } from './useCheckIfDocumentIsParent';

export const DocumentContextMenuShowRelated = ({
  document,
  onClose,
}: DocumentContextMenuItemProps) => {
  const { handleMinimize, checkIfItemMinimized, checkIfMinimizeDisabled } =
    useMinimizeItemForVersion({});
  const { setFilters } = useGetDocumentsListWithParams();

  const parentDocumentId = (document as DocumentResponse)?.DocumentId ?? '';
  const fileName = (document as DocumentResponse)?.FileName ?? '';

  const { documentIsParent, isLoading, count, documentsList } = useCheckIfDocumentIsParent(
    parentDocumentId ?? ''
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const minimizeCurrentDocument = () => {
    if (checkIfMinimizeDisabled(parentDocumentId)) return;
    if (!checkIfItemMinimized(parentDocumentId)) {
      handleMinimize(parentDocumentId, fileName);
    }
  };

  const handleShowRelated = () => {
    if (document && documentIsParent) {
      dispatch(closeDocumentViewModal());
      setFilters({});
      minimizeCurrentDocument();
      navigate(RoutePath.DOCUMENTS, {
        state: { parentDocumentId, fileName, relatedDocuments: documentsList },
      });
    }
    onClose();
  };
  return (
    <MenuItem onClick={handleShowRelated} disabled={!count || isLoading}>
      <AssignmentTurnedInOutlinedIcon />
      Show related ({count})
    </MenuItem>
  );
};
