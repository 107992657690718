const GROUP_PREFIX = 'gwr_';
const CLAUSE_PREFIX = 'gwr_clause_';
const WRITE_GROUP = '_editors';
const DOCUMENT_READ_REPORTED_GROUP =
  'GWR_All_Entities_Global_Users_C1-C3_Readers_Reported'.toLowerCase();

const GLOBALS_PREFIX = 'GWR_clause_All_Entities_Global_Users_';
enum UserGroupEditors {
  C1_C2 = 'C1-C2_Editors',
  C3 = 'C3_Editors',
  C4 = 'C4_Editors',
}
const allGlobalEditors = [
  GLOBALS_PREFIX + UserGroupEditors.C1_C2,
  GLOBALS_PREFIX + UserGroupEditors.C3,
  GLOBALS_PREFIX + UserGroupEditors.C4,
];

const ADMIN_PANEL_GROUP = 'ADMIN_PANEL_GWR'.toLowerCase();

const isGwrDocumentGroup = (group: string) =>
  group.toLowerCase().startsWith(GROUP_PREFIX) && !isGwrClauseGroup(group);

const isGwrClauseGroup = (group: string) => group.toLowerCase().startsWith(CLAUSE_PREFIX);

const isGwrEditorsGroup = (group: string) => group.toLowerCase().includes(WRITE_GROUP);

const isGwrDocumentReaderReportedGroup = (group: string) =>
  group.toLowerCase() === DOCUMENT_READ_REPORTED_GROUP;

const isAdminPanelGroup = (group: string) => group.toLowerCase() === ADMIN_PANEL_GROUP;

export const hasUserDocumentReadAccess = (groups?: string[]) => !!groups?.find(isGwrDocumentGroup);

export const hasUserDocumentWriteAccess = (groups?: string[]) =>
  !!groups?.find((group) => isGwrDocumentGroup(group) && isGwrEditorsGroup(group));

export const hasUserClauseReadAccess = (groups?: string[]) => !!groups?.find(isGwrClauseGroup);

export const hasUserClauseWriteAccess = (groups?: string[]) =>
  !!groups?.find((group) => isGwrClauseGroup(group) && isGwrEditorsGroup(group));

export const hasUserDocumentReadReportedAccess = (groups?: string[]) =>
  !!groups?.find(isGwrDocumentReaderReportedGroup);

export const hasAdminPanelAccess = (groups?: string[]) => !!groups?.find(isAdminPanelGroup);

export const hasReportsTabAccessFn = (groups?: string[]) =>
  allGlobalEditors.some((group) => groups?.includes(group));
