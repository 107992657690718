import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { ListParams } from './list.types';
import { DocumentResponse } from '../documents/documents.list.types';
import { ClauseResponse } from '../clauses/clauses.list.types';
import { MINIMIZED_DATA, ITEM_TYPE } from '../../ui/ui.types';
import { isDocumentResponse } from './list.helpers';

export const preparePaginationForGrid = (
  page: ListParams['PageNumber'],
  size: ListParams['PageSize']
) => ({
  page: parseInt(page!) - 1,
  pageSize: parseInt(size!),
});

export const prepareSortForGrid = (field: ListParams['SortBy'], sort: ListParams['SortOrder']) =>
  field && sort ? [{ field, sort }] : [];

export const prepareQueryParams = <T extends ListParams>(
  filters: T,
  page: number,
  size: number,
  [sort]: GridSortModel
): T => {
  const baseParams = {
    PageNumber: String(page + 1),
    PageSize: String(size),
    SortBy: sort?.field,
    SortOrder: sort?.sort ?? undefined,
  };

  return { ...filters, ...baseParams };
};

export const prepareQueryParamsWithFirstPage = <T>(filters: T, size: number, sort: GridSortModel) =>
  prepareQueryParams<T>(filters, 0, size, sort);

export function useIsDocumentResponseData(item: DocumentResponse | ClauseResponse | undefined) {
  const [itemId, itemName, minimizedDataType, dataType] = isDocumentResponse(item)
    ? [item.DocumentId, item.FileName, MINIMIZED_DATA.MINIMIZED_DOCS, ITEM_TYPE.DOCUMENT]
    : [item?.ClauseId, item?.ClauseName, MINIMIZED_DATA.MINIMIZED_CLAUSES, ITEM_TYPE.CLAUSE];

  return { itemId, itemName, minimizedDataType, dataType };
}
