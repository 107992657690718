import React from 'react';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import { ROWS_PER_PAGE_LABEL } from './CustomPagination.types';
import { DocumentsListParams } from '../../../store/files/documents/documents.list.types';
import { ExportButton } from '../../Documents/Export/ExportButton/ExportButton';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import './CustomPagination.scss';
import { ClausesListParams } from '../../../store/files/clauses/clauses.list.types';
import { ObjectType } from '../../../store/files/reports/reports.types';

const CustomPagination =
  (isDocumentsPage: boolean) =>
  ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {
    const pageCount = Math.ceil(count / rowsPerPage);
    const props = isDocumentsPage
      ? { siblingCount: 1, boundaryCount: 0, showFirstButton: true }
      : { siblingCount: 0 };
    return (
      <Box>
        <Pagination
          color='primary'
          shape='rounded'
          size='small'
          {...props}
          count={pageCount}
          page={page + 1}
          onChange={(_, value) => onPageChange(null, value - 1)}
        />
      </Box>
    );
  };

export const paginationOptions = (
  params?: DocumentsListParams | ClausesListParams,
  type?: ObjectType.DOCUMENT | ObjectType.CLAUSE
) => ({
  labelRowsPerPage: ROWS_PER_PAGE_LABEL,
  labelDisplayedRows: () => (params ? <ExportButton params={params} type={type} /> : null),
  ActionsComponent: CustomPagination(!!params),
  className: 'custom-pagination',
  'data-test-id': TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_PAGINATION,
});
