import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import {
  BOOLEAN_VALUES,
  CustomTextField,
  filterParentDocuemntsValues,
  formatApiDate,
  isStringifiedBoolFieldTrue,
  minMaxFieldLengthRule,
  NOT_SELECTED,
  parseDate,
  requiredRule,
  stringifyBoolField,
  useFormFiltering,
} from './MetadataFormHelpers';
import { DocumentMetadata, DocumentMetadataFields, MetadataFormProps } from './MetadataForm.types';
import { useGetDictionariesQuery } from '../../../store/files/upload/list.service';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import {
  DATE_FIELD_CONFIG,
  DOCUMENT_LABELS,
  DOCUMENT_METADATA_TOOLTIPS,
  MAX_INSURED_INDUSTRY_LENGTH,
  MAX_PRODUCT_TYPE_LENGTH,
  MAX_REMARKS_LENGTH,
  MIN_INSURED_INDUSTRY_LENGTH,
  MIN_PRODUCT_TYPE_LENGTH,
  REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION,
} from '../../../config/config';
import { SwitchWithLabel } from '../../StaticComponents/SwitchWithLabel';
import { getDocumentName } from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { FileNameInput } from '../../StaticComponents/FileNameInput/FileNameInput';
import { getLopOptions } from '../../SpreadSheet/DocumentsGrid/EditAutocomplete.helpers';
import { getDatePickerProps } from '../../SpreadSheet/DocumentsGrid/GridEditDateCell.helpers';
import { ApiError } from './ApiError';
import { ReportedField } from '../../SharedComponents/ReportedField/ReportedField';
import { useSnackbar } from 'notistack';
import {
  documentsListApi,
  useDeleteDocumentReportMutation,
} from '../../../store/files/documents/documents.list.service';
import { Messages } from '../../../services/messages.service.types';

import './MetadataForm.scss';
import { closeMetadataEditModal } from '../../../store/files/documents/documents.slice';
import { useAppDispatch } from '../../../store/hooks';
import { DOCUMENTS_LIST_API_TAGS } from '../../../store/files/documents/documents.list.types';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { useParentDocumentsValues } from './useParentDocumentsValues.hook';

export const MetadataForm: React.FC<MetadataFormProps> = ({
  document,
  onSubmit,
  isLoading,
  apiError,
  disabledColumns = [],
  validate = false,
  disableSaveOnMissingData = false,
  bulkMetadataEdit = false,
}) => {
  const { data: dictionaries } = useGetDictionariesQuery({ AccessMode: AccessMode.WRITE });

  const entityValue = dictionaries?.Entity?.values[0]?.value || '';
  const countryValue = dictionaries?.Country?.values[0]?.value || '';
  const bulkEditDefaultValues = {
    [DocumentMetadataFields.Entity]: entityValue,
    [DocumentMetadataFields.Country]: countryValue,
  };

  const [isReportedChecked, setIsReportedChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    control,
    getValues,
    watch,
    trigger,
    formState: { isDirty, isValid },
    setValue,
  } = useForm<DocumentMetadata>({
    mode: 'onChange',
    defaultValues: bulkMetadataEdit ? bulkEditDefaultValues : document,
  });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    trigger();
  }, [trigger, dictionaries]);
  const [cancelDocumentReport, { isLoading: isCancelDocLoading }] =
    useDeleteDocumentReportMutation();

  const { ParentDocuments, ...restFormData } = getValues();
  const { parentDocumentsValues, isRelatedDocumentsEnabled } =
    useParentDocumentsValues(restFormData);
  const shouldSelectDefaultParentDocumentsOpt = !ParentDocuments?.length;

  const selectedParentDocuemnts = watch('ParentDocuments');

  filterParentDocuemntsValues(
    selectedParentDocuemnts ?? [],
    setValue,
    shouldSelectDefaultParentDocumentsOpt
  );

  const lopValues = useMemo(
    () => (dictionaries ? getLopOptions(dictionaries, restFormData.Lob) : []),
    [dictionaries, restFormData.Lob]
  );
  const documentNameValue = getDocumentName(watch());

  const saveDisabled = disableSaveOnMissingData && (!isDirty || !isValid) && !isReportedChecked;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isReportedChecked && document?.DocumentId) {
      cancelDocumentReport(document.DocumentId)
        .unwrap()
        .then(async () => {
          dispatch(closeMetadataEditModal());
          await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
          dispatch(documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST]));
          enqueueSnackbar(Messages.SuccessUpdate);
        });
    }
    const formValues = getValues();
    formValues.ParentDocuments = formValues?.ParentDocuments?.filter((doc) => doc !== NOT_SELECTED);

    isDirty && onSubmit(formValues);
  };

  const { noEntitySelected, lobValues, businessTypesValues } = useFormFiltering(
    dictionaries,
    getValues(),
    setValue
  );

  if (!dictionaries)
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );

  const isCountryGermany = getValues(DocumentMetadataFields.Country) === 'DE';

  return (
    <form className='metadata-form' onSubmit={handleSubmit}>
      <div className='metadata-form-fields'>
        <div className='metadata-form-block'>
          <ReportedField
            item={document}
            isReportedChecked={isReportedChecked}
            setIsReportedChecked={setIsReportedChecked}
            dictionaries={dictionaries}
            editMode
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.FileName}
            defaultValue=''
            rules={requiredRule(validate, true)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.FILE_NAME}
                {...field}
                InputProps={{ inputComponent: FileNameInput }}
                disabled={disabledColumns.includes(DocumentMetadataFields.FileName) || !validate}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.FILE_NAME}
                label={DOCUMENT_LABELS.FILE_NAME}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Entity}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Entity.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.ENTITY}
                {...field}
                disabled={disabledColumns.includes(DocumentMetadataFields.Entity)}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.COMPANY_NAME}
                select
                label={DOCUMENT_LABELS.COMPANY_NAME}
                values={dictionaries?.Entity.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Country}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Country.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.COUNTRY}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.COUNTRY}
                select
                disabled
                label={DOCUMENT_LABELS.COUNTRY}
                values={dictionaries?.Country.values}
                isCountry
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Lob}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Lob.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LOB}
                {...field}
                disabled={noEntitySelected}
                onChange={(e) => {
                  field.onChange(e);
                  setValue(DocumentMetadataFields.Lop, '', { shouldValidate: true });
                }}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.BUSINESS_LINE}
                select
                label={DOCUMENT_LABELS.BUSINESS_LINE}
                values={lobValues}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Lop}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Lop?.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LOP}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.PRODUCT_LINE}
                select
                label={DOCUMENT_LABELS.PRODUCT_LINE}
                disabled={!restFormData.Lob}
                values={lopValues}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.ProductType}
            defaultValue=''
            rules={minMaxFieldLengthRule(
              validate,
              dictionaries?.ProductType?.required || isCountryGermany,
              MIN_PRODUCT_TYPE_LENGTH,
              MAX_PRODUCT_TYPE_LENGTH
            )}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.PRODUCT_TYPE}
                inputProps={{ maxLength: MAX_PRODUCT_TYPE_LENGTH }}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.PRODUCT_TYPE}
                label={DOCUMENT_LABELS.PRODUCT_TYPE}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.InsurerIndustry}
            defaultValue=''
            rules={minMaxFieldLengthRule(
              validate,
              dictionaries?.InsurerIndustry?.required || isCountryGermany,
              MIN_INSURED_INDUSTRY_LENGTH,
              MAX_INSURED_INDUSTRY_LENGTH
            )}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.INSURED_INDUSTRY}
                inputProps={{ maxLength: MAX_INSURED_INDUSTRY_LENGTH }}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.INSURED_INDUSTRY}
                label={DOCUMENT_LABELS.INSURED_INDUSTRY}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.BusinessType}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.BusinessType?.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.BUSINESS_TYPE}
                {...field}
                disabled={noEntitySelected}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.BUSINESS_TYPE}
                select
                label={DOCUMENT_LABELS.BUSINESS_TYPE}
                values={businessTypesValues}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.DocumentType}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.DocumentType.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.DOCUMENT_TYPE}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_TYPE}
                select
                label={DOCUMENT_LABELS.DOCUMENT_TYPE}
                values={dictionaries?.DocumentType.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.ParentDocuments}
            defaultValue={[NOT_SELECTED]}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.PARENT_DOCUMENTS}
                {...field}
                helperWarning={error}
                disabled={!isRelatedDocumentsEnabled}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.PARENT_DOCUMENTS}
                select
                isParentDocuments
                multiselect
                label={DOCUMENT_LABELS.PARENT_DOCUMENTS}
                values={parentDocumentsValues}
              />
            )}
          />
        </div>
        <div className='metadata-form-block'>
          <Controller
            control={control}
            name={DocumentMetadataFields.Classification}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Classification.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                {...field}
                data-test-id={TEST_ID.BULK_METADATA.CLASSIFICATION}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.CLASSIFICATION}
                select
                label={DOCUMENT_LABELS.CLASSIFICATION}
                values={dictionaries?.Classification.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Language}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Language.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LANGUAGE}
                {...field}
                select
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.LANGUAGE}
                label={DOCUMENT_LABELS.LANGUAGE}
                disabled={disabledColumns.includes(DocumentMetadataFields.Language)}
                values={dictionaries?.Language.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.ValidFrom}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.ValidFrom.required)}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                {...DATE_FIELD_CONFIG}
                {...getDatePickerProps(DocumentMetadataFields.ValidFrom, watch())}
                value={parseDate(field.value) || null}
                onChange={(date: Date | null) => field.onChange(formatApiDate(date))}
                label={DOCUMENT_LABELS.VALID_FROM}
                renderInput={(params) => (
                  <CustomTextField
                    data-test-id={TEST_ID.BULK_METADATA.VALID_FROM}
                    {...params}
                    helperWarning={error}
                    tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.VALID_FROM}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.ValidTo}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.ValidTo.required)}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                {...DATE_FIELD_CONFIG}
                {...getDatePickerProps(DocumentMetadataFields.ValidTo, watch())}
                value={parseDate(field.value) || null}
                onChange={(date: Date | null) => field.onChange(formatApiDate(date))}
                label={DOCUMENT_LABELS.VALID_TO}
                renderInput={(params) => (
                  <CustomTextField
                    data-test-id={TEST_ID.BULK_METADATA.VALID_TO}
                    {...params}
                    helperWarning={error}
                    tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.VALID_TO}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.ExpireAt}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.ExpireAt?.required)}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                {...DATE_FIELD_CONFIG}
                {...getDatePickerProps(DocumentMetadataFields.ExpireAt, watch())}
                value={parseDate(field.value) || null}
                onChange={(date: Date | null) => field.onChange(formatApiDate(date))}
                label={DOCUMENT_LABELS.EXPIRE_AT}
                renderInput={(params) => (
                  <CustomTextField
                    data-test-id={TEST_ID.BULK_METADATA.EXPIRE_AT}
                    {...params}
                    helperWarning={error}
                    tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.EXPIRE_AT}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.Version}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Version.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.DOCUMENT_VERSION}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.VERSION}
                label={DOCUMENT_LABELS.VERSION}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.LatestVersion}
            defaultValue={BOOLEAN_VALUES.FALSE}
            rules={requiredRule(validate, dictionaries?.LatestVersion.required)}
            render={({ field }) => (
              <FormControl className='switch-form-control'>
                <SwitchWithLabel
                  {...field}
                  checked={isStringifiedBoolFieldTrue(field.value)}
                  onChange={(_, checked) => field.onChange(stringifyBoolField(checked))}
                  label={DOCUMENT_LABELS.LATEST_VERSION}
                  data-test-id={TEST_ID.BULK_METADATA.LATEST_VERSION}
                  tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.LATEST_VERSION}
                />
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.Active}
            defaultValue={BOOLEAN_VALUES.FALSE}
            rules={requiredRule(validate, dictionaries?.Active?.required)}
            render={({ field }) => (
              <FormControl className='switch-form-control'>
                <SwitchWithLabel
                  {...field}
                  label={DOCUMENT_LABELS.ACTIVE}
                  checked={isStringifiedBoolFieldTrue(field.value)}
                  onChange={(_, checked) => field.onChange(stringifyBoolField(checked))}
                  data-test-id={TEST_ID.BULK_METADATA.ACTIVE}
                  tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.ACTIVE}
                />
              </FormControl>
            )}
          />

          <CustomTextField
            data-test-id={TEST_ID.BULK_METADATA.DOCUMENT_NAME}
            value={documentNameValue}
            disabled
            label={DOCUMENT_LABELS.DOCUMENT_NAME}
            tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_NAME}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.Remarks}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Remarks?.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.REMARKS}
                inputProps={{ maxLength: MAX_REMARKS_LENGTH }}
                {...field}
                value={field.value ?? ''}
                multiline
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.REMARKS}
                label={DOCUMENT_LABELS.REMARKS}
              />
            )}
          />
        </div>
      </div>

      <ApiError apiError={apiError} />

      <div className='metadata-form-submit'>
        <LoadingButton
          data-test-id={TEST_ID.BULK_METADATA.SAVE_BUTTON}
          loading={isLoading || isCancelDocLoading}
          disabled={saveDisabled}
          fullWidth
          variant='contained'
          size='large'
          type='submit'
        >
          Save
        </LoadingButton>
      </div>
    </form>
  );
};
