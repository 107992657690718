import React from 'react';
import { Backdrop } from '@mui/material';
import './DocumentBarList.scss';
import { MinimizedData } from '../../store/ui/ui.types';
import { CompareButton } from './CompareButton';
import CircularProgress from '@mui/material/CircularProgress';
import { BarListProps } from './BarList.types';
import { BarButton } from './BarButton/BarButton';

export const BarList: React.FC<BarListProps> = ({
  minimizedData,
  itemsToCompare,
  isFetching,
  indexOfCompare,
  handleClose,
  handleClick,
  compareEnabled = true,
  dataType,
}) => {
  return (
    <>
      <div
        className={`document-bar-list ${
          !compareEnabled ? 'document-bar-list--compare-disabled' : ''
        }`}
      >
        {minimizedData.map(({ name, dataId }: MinimizedData) => {
          const docIndexOfCompare = indexOfCompare ? indexOfCompare(dataId) : undefined;
          return (
            <BarButton
              handleClick={handleClick(dataId)}
              handleClose={handleClose(dataId)}
              name={name}
              key={name}
              docIndexOfCompare={docIndexOfCompare}
            />
          );
        })}
        <Backdrop
          className='document-bar-list__backdrop'
          open={isFetching}
          transitionDuration={{ exit: 0 }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
      {compareEnabled && <CompareButton dataType={dataType} itemsToCompare={itemsToCompare} />}
    </>
  );
};
