import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import common from '../../../assets/common.module.scss';
import './ExcludedWords.scss';
import { ExcludedWordsProps } from './ExcludedWords.types';
import { processExcludedWord } from './ExcludedWords.helpers';

export const ExcludedWords = ({
  clauseMode = false,
  label,
  onChange,
  value = [],
}: ExcludedWordsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDelete = (index: number) => {
    onChange(value.filter((_, i) => index !== i));
  };

  const handleKeyDown = (key: string) => {
    const word = processExcludedWord(inputValue, value);
    if (key === 'Enter' && word) {
      onChange([...value, word]);
      setInputValue('');
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div className='documents-search-excluded'>
      <span className='documents-search-counter'>{value.length ? value.length : '-'}</span>
      <Typography component='span'>Exclude words or categories</Typography>

      <IconButton aria-describedby={id} size='small' onClick={handleClick}>
        <KeyboardArrowDownIcon fontSize='inherit' />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClick}
      >
        <Paper sx={{ p: '24px 16px', maxWidth: 332 }}>
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: '20px',
              color: common.midGray,
              p: '0 10px 8px',
            }}
          >
            Search only for {clauseMode ? 'clauses' : 'documents'} that do not contain the words
            entered below
          </Typography>

          <div className='excluded-words-input'>
            {value.map((v, i) => (
              <Chip key={i} size='small' label={v} onDelete={() => handleDelete(i)} />
            ))}

            <input
              placeholder='Type excluded text here and press Enter'
              value={inputValue}
              onChange={({ target }) => setInputValue(target.value)}
              onKeyDown={({ key }) => handleKeyDown(key)}
            />
          </div>
        </Paper>
      </Popover>

      {label}
    </div>
  );
};
