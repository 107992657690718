import { FileTypeIcon } from '../../../StaticComponents/FileTypeIcon';
import { mapUploadedFileType } from '../../../../store/files/upload/upload.helpers';
import { getDictionaryLabelByValue } from '../../../../store/files/upload/list.helpers';
import { DictionariesResponse } from '../../../../store/files/upload/list.service.types';
import { ItemNameText } from '../../../DocumentsAndClauses/ItemNameText/ItemNameText';
import { GridColDef } from '@mui/x-data-grid-pro';
import DoneIcon from '@mui/icons-material/Done';
import { BOOLEAN_VALUES } from '../../../UploadFiles/MetadataForm/MetadataFormHelpers';

export const getColumnsVersions = (dictionaries: DictionariesResponse): GridColDef[] => [
  {
    field: 'Details',
    flex: 8,
    sortable: false,
    renderCell: ({ row: { FileName, Entity, Version } }) => {
      return (
        <div className='tab-items-details-column'>
          <FileTypeIcon fileType={mapUploadedFileType(FileName)} />
          <div className='tab-items-details-column__text'>
            <ItemNameText fileName={FileName} strong />
            <ItemNameText
              fileName={getDictionaryLabelByValue(dictionaries?.Entity, Entity) ?? ''}
            />
            <span>{`version ${Version}`}</span>
          </div>
        </div>
      );
    },
  },
  {
    field: 'VersionCheck',
    flex: 3,
    sortable: false,
    renderCell: ({ row: { LatestVersion } }) =>
      LatestVersion !== BOOLEAN_VALUES.TRUE ? (
        <div className='tab-items-check-column'>
          <DoneIcon /> <span>latest version</span>
        </div>
      ) : (
        ''
      ),
  },
];
