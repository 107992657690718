import { useMemo } from 'react';

import { DocumentMetadata } from './MetadataForm.types';
import { NOT_SELECTED } from './MetadataFormHelpers';

import { MAX_PAGE_SIZE, DOCUMENT_TYPES } from '../../../config/config';
import { DocumentStatus } from '../../../store/files/documents/documents.list.types';
import { GetDocumentDTO } from '../../../store/files/documentsAndClauses/list.dto.types';
import { useGetDocumentsListQuery } from '../../../store/files/documents/documents.list.service';

const AUTOMATED_UPLOAD = 'Automated Upload';
export const useParentDocumentsValues = (
  parentDocumentMetadata: DocumentMetadata,
  searchByProvidedParentDocumentsId?: boolean,
  includeDefault: boolean = true
) => {
  const {
    Lob,
    Lop,
    DocumentType,
    Entity,
    DocumentId,
    ParentDocuments = [],
  } = parentDocumentMetadata;

  const baseValuesProvided =
    !!Lob &&
    !!Lop &&
    !!DocumentType &&
    DocumentType !== DOCUMENT_TYPES.STANDARD_GENERAL_CONDITION &&
    !!Entity;

  const isRelatedDocumentsEnabled = !searchByProvidedParentDocumentsId
    ? baseValuesProvided
    : baseValuesProvided && !!ParentDocuments.length;

  const params: GetDocumentDTO = {
    PageSize: MAX_PAGE_SIZE,
    IncludeFilters: {
      Status: [DocumentStatus.READY],
      ...(searchByProvidedParentDocumentsId
        ? { DocumentId: [...ParentDocuments] }
        : {
            Entity: [Entity ?? ''],
            Lop: [Lop ?? ''],
            Lob: [Lob ?? ''],
            DocumentType: [
              DOCUMENT_TYPES.STANDARD_GENERAL_CONDITION,
              DOCUMENT_TYPES.STANDARD_SPECIAL_CONDITION,
            ],
            Classification: ['C1', 'C2'],
          }),
    },
    ExcludeFilters: {
      DocumentId: DocumentId ? [DocumentId] : undefined,
      UploadedBy: [AUTOMATED_UPLOAD],
    },
  };

  const { data: { Documents } = {} } = useGetDocumentsListQuery(params, {
    skip: !isRelatedDocumentsEnabled,
  });

  const mappedDocs = useMemo(() => {
    return Documents
      ? Documents.map((doc) => ({
          label: doc.FileName,
          label2: `Version ${doc.Version}`,
          value: doc.DocumentId,
        }))
      : [];
  }, [Documents]);

  const parentDocumentsValues = useMemo(() => {
    return includeDefault
      ? [{ label: NOT_SELECTED, value: NOT_SELECTED, value2: '' }, ...mappedDocs]
      : [...mappedDocs];
  }, [includeDefault, mappedDocs]);

  return { parentDocumentsValues, isRelatedDocumentsEnabled };
};
