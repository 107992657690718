import {
  DictionariesResponse,
  MetadataDictionary,
} from '../../store/files/upload/list.service.types';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { DOCUMENTS_LIST_COLUMNS } from './DocumentsListGrid.types';
import { FileTypeIcon } from '../StaticComponents/FileTypeIcon';
import { mapUploadedFileType } from '../../store/files/upload/upload.helpers';
import {
  getDictionaryLabelByValue,
  getFlagByCountryName,
} from '../../store/files/upload/list.helpers';
import React from 'react';
import {
  formatUiDateFromString,
  formatUiDateTimeFromString,
  getDocumentName,
} from '../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { DocumentContextMenuButton } from './DocumentContextMenu/DocumentContextMenuButton';
import { ContextMenuHandler } from './DocumentContextMenu/DocumentContextMenu.types';
import { Document, DocumentStatus } from '../../store/files/documents/documents.list.types';
import { DisplayItemName } from '../DocumentsAndClauses/ItemName/DisplayItemName';
import { openDocumentViewModal } from '../../store/files/documents/documents.slice';
import { DocumentUpdateStatuses } from '../SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { DocumentUpdateStatus } from '../SpreadSheet/DocumentsGrid/DocumentUpdateStatus';
import { DOCUMENT_LABELS, DOCUMENT_METADATA_TOOLTIPS, TOOLTIP_DELAY } from '../../config/config';
import { BOOLEAN_VALUES } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import { Tooltip } from '@mui/material';
import { headerWithTooltip } from '../SpreadSheet/DocumentsGrid/DocumentsGridColumns.helpers';

export const LOCAL_STORAGE_DOCUMENTS_GRID_COLUMNS_VISIBILITY_KEY =
  'DOCUMENTS_GRID_COLUMNS_VISIBILITY_KEY';

export const COLUMNS_VISIBILITY: GridColumnVisibilityModel = {
  [DOCUMENTS_LIST_COLUMNS.FILE_NAME]: true,
  [DOCUMENTS_LIST_COLUMNS.COMPANY_NAME]: true,
  [DOCUMENTS_LIST_COLUMNS.COUNTRY]: true,
  [DOCUMENTS_LIST_COLUMNS.BUSINESS_LINE]: true,
  [DOCUMENTS_LIST_COLUMNS.PRODUCT_LINE]: true,
  [DOCUMENTS_LIST_COLUMNS.PRODUCT_TYPE]: true,
  [DOCUMENTS_LIST_COLUMNS.INSURED_INDUSTRY]: true,
  [DOCUMENTS_LIST_COLUMNS.BUSINESS_TYPE]: true,
  [DOCUMENTS_LIST_COLUMNS.DOCUMENT_TYPE]: true,
  [DOCUMENTS_LIST_COLUMNS.CLASSIFICATION]: true,
  [DOCUMENTS_LIST_COLUMNS.LANGUAGE]: true,
  [DOCUMENTS_LIST_COLUMNS.ACTIVE]: true,
  [DOCUMENTS_LIST_COLUMNS.VALID_FROM]: true,
  [DOCUMENTS_LIST_COLUMNS.VALID_TO]: true,
  [DOCUMENTS_LIST_COLUMNS.EXPIRE_AT]: true,
  [DOCUMENTS_LIST_COLUMNS.VERSION]: true,
  [DOCUMENTS_LIST_COLUMNS.LATEST_VERSION]: true,
  [DOCUMENTS_LIST_COLUMNS.DOCUMENT_NAME]: true,
  [DOCUMENTS_LIST_COLUMNS.DOCUMENT_ID]: true,
  [DOCUMENTS_LIST_COLUMNS.UPDATED_BY]: true,
  [DOCUMENTS_LIST_COLUMNS.UPDATED_AT]: true,
  [DOCUMENTS_LIST_COLUMNS.CREATED_BY]: true,
  [DOCUMENTS_LIST_COLUMNS.CREATED_AT]: true,
  [DOCUMENTS_LIST_COLUMNS.REMARKS]: true,
};

export const BOOLEAN_COLUMN_TYPE_CONFIG = {
  type: 'boolean',
  valueGetter: ({ value }: GridValueGetterParams) =>
    value === BOOLEAN_VALUES.TRUE || value === true,
};

export const DATE_COLUMN_TYPE_CONFIG = {
  type: 'date',
  valueGetter: ({ value }: GridValueGetterParams) => formatUiDateFromString(value),
};

export const DATE_TIME_COLUMN_TYPE_CONFIG = {
  type: 'date',
  valueGetter: ({ value }: GridValueGetterParams) => formatUiDateTimeFromString(value),
};

export const DOCUMENT_NAME_COLUMN_TYPE_CONFIG = {
  valueGetter: ({ row }: GridValueGetterParams) => getDocumentName(row),
};

const withTooltip = (params: GridRenderCellParams, dictionaryLabel?: MetadataDictionary) => {
  const finalValue = dictionaryLabel
    ? getDictionaryLabelByValue(dictionaryLabel, params.value)
    : params.value;
  return finalValue ? (
    <Tooltip title={finalValue} enterNextDelay={TOOLTIP_DELAY} placement='top-start'>
      <span>{finalValue}</span>
    </Tooltip>
  ) : null;
};

export const COLUMNS_DEFINITION = (
  dictionaries: DictionariesResponse,
  handleMenuButtonClick: ContextMenuHandler<Document>,
  statuses: DocumentUpdateStatuses
): GridColDef[] => {
  return [
    {
      flex: 10,
      minWidth: 440,
      headerName: DOCUMENT_LABELS.FILE_NAME,
      field: DOCUMENTS_LIST_COLUMNS.FILE_NAME,
      description: DOCUMENT_METADATA_TOOLTIPS.FILE_NAME,
      hideable: false,
      renderHeader: headerWithTooltip,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <>
            <FileTypeIcon fileType={mapUploadedFileType(row.FileName)} />
            <DisplayItemName item={row} action={openDocumentViewModal({ document: row })} />
          </>
        );
      },
    },
    {
      field: 'UpdateStatus',
      headerName: '',
      minWidth: 25,
      width: 25,
      resizable: false,
      sortable: false,
      hideable: false,
      renderCell: ({ row: { DocumentId } }: GridRenderCellParams) => (
        <DocumentUpdateStatus statuses={statuses} documentId={DocumentId} />
      ),
    },
    {
      field: 'ContextMenu',
      headerName: '',
      width: 40,
      sortable: false,
      hideable: false,
      renderCell: ({ row }: GridRenderCellParams) => (
        <DocumentContextMenuButton
          disabled={row.Status === DocumentStatus.DELETING}
          onClick={(event: React.MouseEvent<HTMLElement>) => handleMenuButtonClick(event, row)}
        />
      ),
    },
    {
      flex: 10,
      minWidth: 120,
      headerName: DOCUMENT_LABELS.COMPANY_NAME,
      field: DOCUMENTS_LIST_COLUMNS.COMPANY_NAME,
      description: DOCUMENT_METADATA_TOOLTIPS.COMPANY_NAME,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.Entity),
    },
    {
      flex: 10,
      minWidth: 130,
      headerName: DOCUMENT_LABELS.COUNTRY,
      field: DOCUMENTS_LIST_COLUMNS.COUNTRY,
      description: DOCUMENT_METADATA_TOOLTIPS.COUNTRY,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <span>{getFlagByCountryName(params.row.Country)}</span>
            <span className='country'>{withTooltip(params, dictionaries?.Country) ?? ''}</span>
          </>
        );
      },
    },
    {
      flex: 10,
      minWidth: 200,
      headerName: DOCUMENT_LABELS.BUSINESS_LINE,
      field: DOCUMENTS_LIST_COLUMNS.BUSINESS_LINE,
      description: DOCUMENT_METADATA_TOOLTIPS.BUSINESS_LINE,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.Lob),
    },
    {
      flex: 10,
      minWidth: 200,
      headerName: DOCUMENT_LABELS.PRODUCT_LINE,
      field: DOCUMENTS_LIST_COLUMNS.PRODUCT_LINE,
      description: DOCUMENT_METADATA_TOOLTIPS.PRODUCT_LINE,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.Lop),
    },
    {
      flex: 10,
      minWidth: 200,
      headerName: DOCUMENT_LABELS.PRODUCT_TYPE,
      field: DOCUMENTS_LIST_COLUMNS.PRODUCT_TYPE,
      description: DOCUMENT_METADATA_TOOLTIPS.PRODUCT_TYPE,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.ProductType),
    },
    {
      flex: 10,
      minWidth: 200,
      headerName: DOCUMENT_LABELS.INSURED_INDUSTRY,
      field: DOCUMENTS_LIST_COLUMNS.INSURED_INDUSTRY,
      description: DOCUMENT_METADATA_TOOLTIPS.INSURED_INDUSTRY,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) =>
        withTooltip(params, dictionaries?.InsurerIndustry),
    },
    {
      flex: 10,
      minWidth: 200,
      headerName: DOCUMENT_LABELS.BUSINESS_TYPE,
      field: DOCUMENTS_LIST_COLUMNS.BUSINESS_TYPE,
      description: DOCUMENT_METADATA_TOOLTIPS.BUSINESS_TYPE,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.BusinessType),
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: DOCUMENT_LABELS.DOCUMENT_TYPE,
      field: DOCUMENTS_LIST_COLUMNS.DOCUMENT_TYPE,
      description: DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_TYPE,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.DocumentType),
    },
    {
      flex: 1,
      minWidth: 150,
      headerName: DOCUMENT_LABELS.CLASSIFICATION,
      field: DOCUMENTS_LIST_COLUMNS.CLASSIFICATION,
      description: DOCUMENT_METADATA_TOOLTIPS.CLASSIFICATION,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) =>
        withTooltip(params, dictionaries?.Classification),
    },
    {
      flex: 1,
      minWidth: 150,
      headerName: DOCUMENT_LABELS.LANGUAGE,
      field: DOCUMENTS_LIST_COLUMNS.LANGUAGE,
      description: DOCUMENT_METADATA_TOOLTIPS.LANGUAGE,
      renderHeader: headerWithTooltip,
      renderCell: (params: GridRenderCellParams) => withTooltip(params, dictionaries?.Language),
    },
    {
      flex: 1,
      minWidth: 140,
      headerName: DOCUMENT_LABELS.VALID_FROM,
      field: DOCUMENTS_LIST_COLUMNS.VALID_FROM,
      description: DOCUMENT_METADATA_TOOLTIPS.VALID_FROM,
      renderHeader: headerWithTooltip,
      ...DATE_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 140,
      headerName: DOCUMENT_LABELS.VALID_TO,
      field: DOCUMENTS_LIST_COLUMNS.VALID_TO,
      description: DOCUMENT_METADATA_TOOLTIPS.VALID_TO,
      renderHeader: headerWithTooltip,
      ...DATE_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 10,
      minWidth: 140,
      headerName: DOCUMENT_LABELS.EXPIRE_AT,
      field: DOCUMENTS_LIST_COLUMNS.EXPIRE_AT,
      description: DOCUMENT_METADATA_TOOLTIPS.EXPIRE_AT,
      renderHeader: headerWithTooltip,
      ...DATE_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 80,
      headerName: DOCUMENT_LABELS.VERSION,
      field: DOCUMENTS_LIST_COLUMNS.VERSION,
      description: DOCUMENT_METADATA_TOOLTIPS.VERSION,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
    },
    {
      flex: 1,
      minWidth: 150,
      headerName: DOCUMENT_LABELS.LATEST_VERSION,
      field: DOCUMENTS_LIST_COLUMNS.LATEST_VERSION,
      description: DOCUMENT_METADATA_TOOLTIPS.LATEST_VERSION,
      renderHeader: headerWithTooltip,
      ...BOOLEAN_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 70,
      headerName: DOCUMENT_LABELS.ACTIVE,
      field: DOCUMENTS_LIST_COLUMNS.ACTIVE,
      description: DOCUMENT_METADATA_TOOLTIPS.ACTIVE,
      renderHeader: headerWithTooltip,
      ...BOOLEAN_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 160,
      headerName: DOCUMENT_LABELS.DOCUMENT_NAME,
      field: DOCUMENTS_LIST_COLUMNS.DOCUMENT_NAME,
      description: DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_NAME,
      sortable: false,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      ...DOCUMENT_NAME_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 300,
      headerName: DOCUMENT_LABELS.DOCUMENT_ID,
      field: DOCUMENTS_LIST_COLUMNS.DOCUMENT_ID,
      description: DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_ID,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
    },
    {
      flex: 1,
      minWidth: 160,
      headerName: DOCUMENT_LABELS.UPDATED_BY,
      field: DOCUMENTS_LIST_COLUMNS.UPDATED_BY,
      description: DOCUMENT_METADATA_TOOLTIPS.UPDATED_BY,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
    },
    {
      flex: 10,
      minWidth: 160,
      headerName: DOCUMENT_LABELS.UPDATED_AT,
      field: DOCUMENTS_LIST_COLUMNS.UPDATED_AT,
      description: DOCUMENT_METADATA_TOOLTIPS.UPDATED_AT,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      ...DATE_TIME_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 160,
      headerName: DOCUMENT_LABELS.CREATED_BY,
      field: DOCUMENTS_LIST_COLUMNS.CREATED_BY,
      description: DOCUMENT_METADATA_TOOLTIPS.CREATED_BY,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
    },
    {
      flex: 10,
      minWidth: 160,
      headerName: DOCUMENT_LABELS.CREATED_AT,
      field: DOCUMENTS_LIST_COLUMNS.CREATED_AT,
      description: DOCUMENT_METADATA_TOOLTIPS.CREATED_AT,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      ...DATE_TIME_COLUMN_TYPE_CONFIG,
    },
    {
      flex: 1,
      minWidth: 160,
      headerName: DOCUMENT_LABELS.REMARKS,
      field: DOCUMENTS_LIST_COLUMNS.REMARKS,
      description: DOCUMENT_METADATA_TOOLTIPS.REMARKS,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
    },
  ];
};

export const getColumnsVisibility = (): GridColumnVisibilityModel => {
  const columnsVisibilityStoredDef = localStorage.getItem(
    LOCAL_STORAGE_DOCUMENTS_GRID_COLUMNS_VISIBILITY_KEY
  );
  if (columnsVisibilityStoredDef) {
    return JSON.parse(columnsVisibilityStoredDef);
  }
  return COLUMNS_VISIBILITY;
};

export const updateColumnsVisibility = (columns?: GridColumnVisibilityModel) => {
  if (columns) {
    localStorage.setItem(
      LOCAL_STORAGE_DOCUMENTS_GRID_COLUMNS_VISIBILITY_KEY,
      JSON.stringify(columns)
    );
  }
};
