import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DOCUMENTS_LIST_COLUMNS } from '../../Documents/DocumentsListGrid.types';
import { FileTypeIcon } from '../../StaticComponents/FileTypeIcon';
import { mapUploadedFileType } from '../../../store/files/upload/upload.helpers';
import React from 'react';
import {
  DocumentStatus,
  ValidationErrorCode,
} from '../../../store/files/documents/documents.list.types';
import { DisplayItemName } from '../../DocumentsAndClauses/ItemName/DisplayItemName';
import { PendingStatus } from './PendingStatus';
import { CLAUSES_LIST_COLUMNS } from '../../Clauses/ClausesListGrid.types';
import {
  getDuplicatedClauseId,
  mapErrorResponseToErrorMessageDetails,
} from '../../../store/files/documents/documents.update.error.helpers';
import { openNewClauseViewModal } from '../../Documents/DocumentViewModal/DocumentRelatedClauses/ClauseRelatedDocuments.hooks';

export const COLUMNS_DEFINITION = (type: 'document' | 'clause'): (() => GridColDef[]) => {
  const LIST_COLUMNS = type === 'document' ? DOCUMENTS_LIST_COLUMNS : CLAUSES_LIST_COLUMNS;

  return () => [
    {
      flex: 2,
      headerName: 'File name',
      field:
        type === 'document' ? DOCUMENTS_LIST_COLUMNS.FILE_NAME : CLAUSES_LIST_COLUMNS.CLAUSE_NAME,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <>
            <FileTypeIcon fileType={mapUploadedFileType(row.FileName)} />
            <DisplayItemName item={row} />
          </>
        );
      },
    },
    {
      flex: 1,
      field: LIST_COLUMNS.STATUS,
      renderCell: ({ row }: GridRenderCellParams) => {
        switch (row.Status) {
          case DocumentStatus.PROCESSING:
            return <PendingStatus mode='uploading' label='Processing in progress' />;
          case DocumentStatus.FAILED:
          case DocumentStatus.DUPLICATE:
            switch (row.FailureReason?.errorCode) {
              case ValidationErrorCode.ClauseDuplicateMetadataExtended:
                return <PendingStatus mode='warning' label='Extended' />;
              default:
                return <PendingStatus mode='error' label='Failed' />;
            }
        }
      },
    },
    {
      flex: 2,
      field: LIST_COLUMNS.FAILURE_REASON,
      cellClassName: 'failure-reason-column',
      renderCell: ({ row }: GridRenderCellParams) => {
        if (row.FailureReason) {
          const clauseId = getDuplicatedClauseId(row.FailureReason.errorDetails);
          const details = mapErrorResponseToErrorMessageDetails([row.FailureReason]);
          return (
            <div
              className={clauseId && 'clickable'}
              onClick={() => clauseId && openNewClauseViewModal(clauseId)}
            >
              {details}
            </div>
          );
        }
      },
    },
  ];
};
