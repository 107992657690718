import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteProps,
  Box,
  InputBase,
} from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { CustomPaper, getLopOptions } from './EditAutocomplete.helpers';
import { getMetadataValuesByColumnName } from './DocumentsGrid.helpers';
import { DOCUMENTS_GRID_COLUMNS } from './DocumentsGrid.types';

export const EditAutocomplete = ({
  id,
  field,
  dictionaries,
  row,
}: GridRenderEditCellParams<MetadataDictionaryValue>) => {
  const apiRef = useGridApiContext();

  const metadataOptions = useMemo(() => {
    if (field === DOCUMENTS_GRID_COLUMNS.PRODUCT_LINE) {
      return getLopOptions(dictionaries, row.Lob);
    }
    return getMetadataValuesByColumnName(dictionaries, field);
  }, [dictionaries, field, row.Lob]);

  const handleChange = React.useCallback<
    NonNullable<AutocompleteProps<MetadataDictionaryValue, false, true, false>['onChange']>
  >(
    async (event, { value }) => {
      await apiRef.current.setEditCellValue({ id, field, value });
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, field, id]
  );

  return (
    <Autocomplete
      sx={{
        height: '100%',
        [`& .${autocompleteClasses.inputRoot}`]: {
          padding: '1px 0',
          height: '100%',
          '& input': {
            padding: '0 16px',
            height: '100%',
          },
        },
      }}
      PaperComponent={CustomPaper}
      value={undefined}
      onChange={handleChange}
      options={metadataOptions}
      autoHighlight
      fullWidth
      open
      disableClearable
      renderOption={(optionProps, option) => (
        <Box component='li' {...optionProps}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <InputBase
            autoFocus
            fullWidth
            id={params.id}
            inputProps={{
              ...params.inputProps,
            }}
            {...params.InputProps}
          />
        );
      }}
    />
  );
};
