import React, { useEffect, useRef, useState } from 'react';
import UploadService from '../../../store/files/upload/upload.service';
import { FileViewerProps } from './FileViewer.types';
import { FileLoader } from './FileLoader';
import { useFileLoader } from './FileLoader.hook';
import './FileViewer.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { searchHighlight } from './HtmlViewer.helpers';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useMarkSwitch, useZoomSwitch } from './FileViewer.helpers';
import { ViewerToolbar } from './ViewerToolbar/ViewerToolbar';
// @ts-ignore
import pdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTextSelectionDispatch } from './FileViewer.hook';

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const PdfViewer = ({ loading, error, url, keyword, method }: FileViewerProps) => {
  const { isLoading, isError, setLoading, setError } = useFileLoader(loading, error);
  const [pages, setPages] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const { scale, handleZoom } = useZoomSwitch(url);
  const { initialize, handleSwitch, switchMark } = useMarkSwitch();

  const handleLoadSuccess = ({ numPages }: PDFDocumentProxy) => setPages(numPages);

  const { handleTextSelectionDispatch } = useTextSelectionDispatch();

  const handleTextSelection = () => {
    const selectionObj = window.getSelection();
    handleTextSelectionDispatch(selectionObj);
  };

  useEffect(() => {
    if (url) {
      setLoading(true);
      setError(false);
      UploadService.preflightDocumentDownload(url)
        .catch(() => setError(true))
        .then(() => setLoading(false));
    }
  }, [setError, setLoading, url]);

  return (
    <div className='file-viewer file-viewer--pdf'>
      {isLoading || isError || !url ? (
        <FileLoader loading={isLoading} error={isError} />
      ) : (
        <>
          <Document
            className='file-viewer--document'
            inputRef={ref}
            file={url}
            loading=''
            onLoadSuccess={handleLoadSuccess}
            onMouseUp={handleTextSelection}
          >
            {Array.from(new Array(pages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
                loading=''
                customTextRenderer={({ str }) => searchHighlight(str, keyword, method)}
                onRenderTextLayerSuccess={() => {
                  initialize(ref.current);
                  switchMark();
                }}
              />
            ))}
          </Document>

          <ViewerToolbar
            handleZoom={handleZoom}
            handleSwitch={keyword ? handleSwitch : undefined}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(PdfViewer);
