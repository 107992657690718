import React, { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { UploadedFileStatus } from '../UploadedFileList.types';
import { listApi, useGetListQuery } from '../../../store/files/upload/list.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { updateSelection } from '../../../store/files/upload/upload.slice';
import { MarsSnackbar } from '../../StaticComponents/MarsSnackbar/MarsSnackbar';
import SubmitService from '../../../store/files/upload/submit.service';
import { SubmitResult } from '../../../store/files/upload/submit.service.types';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import { UploadSubmitDocumentsProps } from './UploadSubmitDocuments.types';
import { UploadConfirmationModal } from '../../StaticComponents/Modals/UploadConfirmationModal/UploadConfirmationModal';
import { showStatus } from '../UploadedFileList.helper';

export const UploadSubmitDocuments: React.FC<UploadSubmitDocumentsProps> = ({
  isSnackbarEnabled,
}) => {
  const dispatch = useAppDispatch();
  const { data: docs } = useGetListQuery();
  const selected = useAppSelector(uploadSelectors.selectedDocs);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState<SubmitResult>();

  useEffect(() => {
    dispatch(updateSelection({ selected: [] }));
  }, [dispatch]);

  const onSubmitUpload = async () => {
    setLoading(true);
    toSubmit.forEach((doc) => dispatch(showStatus(doc, UploadedFileStatus.SUBMITTING)));
    setResult(await SubmitService.submitDocuments(toSubmit));
    setLoading(false);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
    dispatch(updateSelection({ selected: [] }));
  };

  if (!docs) return null;

  const ready = docs
    .filter(({ Status }) => Status === UploadedFileStatus.UPLOAD_COMPLETE)
    .map((doc) => doc.DocumentId);
  const toSubmit = ready.filter((doc) => selected.indexOf(doc) > -1);

  const renderDuplicateItems = result?.duplicatesId.length
    ? docs
        .filter((d) => result.duplicatesId.indexOf(d.DocumentId) > -1)
        .map((d) => <li key={d.DocumentId}> {d.FileName} </li>)
    : [];

  const renderErrorItems = result?.errorsId.length
    ? docs
        .filter((d) => result.errorsId.indexOf(d.DocumentId) > -1)
        .map((d) => <li key={d.DocumentId}> {d.FileName} </li>)
    : [];

  return (
    <>
      <MarsSnackbar
        open={!!ready.length && isSnackbarEnabled}
        message={
          <>
            <DoneOutlinedIcon className='done-icon' />
            <strong>Ready to submit</strong>
            <small>
              ({toSubmit.length}/{ready.length})
            </small>
          </>
        }
        action={
          <LoadingButton
            variant='contained'
            size='small'
            disabled={!toSubmit.length}
            loading={loading}
            onClick={onSubmitUpload}
          >
            Submit upload
          </LoadingButton>
        }
      />
      <UploadConfirmationModal
        open={open}
        onClose={onClose}
        result={result}
        renderDuplicateItems={renderDuplicateItems}
        renderErrorItems={renderErrorItems}
      />
    </>
  );
};
