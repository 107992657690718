import { getRoutePath, RoutePath } from '../../services/route.service';
import { SearchButtonHookReturn } from './SearchButton.types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PENDING_PATHS = [
  getRoutePath(RoutePath.UPLOADED_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_PENDING),
  getRoutePath(RoutePath.UPLOADED_CLAUSES, RoutePath.UPLOADED_CLAUSES_PENDING),
];

const checkPendingPath = (pathname: string) => PENDING_PATHS.includes(pathname);

export const useSearchButtonHook = (): SearchButtonHookReturn => {
  const [showSearch, setShowSearch] = useState(false);
  const { pathname } = useLocation();

  const isPendingTab = checkPendingPath(pathname);

  useEffect(() => {
    if (isPendingTab && showSearch) {
      setShowSearch(false);
    }
  }, [isPendingTab, showSearch]);

  return { showSearch, setShowSearch, isPendingTab };
};
