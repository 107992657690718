import React, { useEffect, useRef } from 'react';
import { FileViewerProps } from './FileViewer.types';
import { FileLoader } from './FileLoader';
import { useMarkSwitch, useZoomSwitch } from './FileViewer.helpers';
import { ViewerToolbar } from './ViewerToolbar/ViewerToolbar';
import { useTextSelectionDispatch } from './FileViewer.hook';
import { useDocumentDownload } from './useDocumentDownload';
import { useTransformDocumentDataToDiplayableUrl } from './useTransformDocumentDataToDiplayableUrl';

export const HtmlViewer = ({ loading, error, url, keyword, method, order }: FileViewerProps) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const { scale, handleZoom } = useZoomSwitch(url);
  const { initialize, handleSwitch } = useMarkSwitch();

  const { documentData, isDownloading, isError } = useDocumentDownload(loading, error, url, order);
  const { documentUrl, isLoading } = useTransformDocumentDataToDiplayableUrl(
    documentData,
    loading,
    error,
    method,
    keyword,
    order
  );

  const { handleTextSelectionDispatch } = useTextSelectionDispatch();

  const handleTextSelection = () => {
    const iframeWindow = ref.current?.contentWindow;
    if (!iframeWindow) return;
    const selectionObj = iframeWindow.getSelection();
    handleTextSelectionDispatch(selectionObj);
  };

  const handleIframeLoad = () => {
    initialize(ref.current?.contentDocument?.body);
    const iframeDoc = ref.current?.contentDocument || ref.current?.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.onmouseup = handleTextSelection;
    }
  };

  useEffect(() => {
    ref.current?.contentDocument?.body.style.setProperty('scale', scale.toString());
  }, [scale]);

  useEffect(() => {
    const currentIframe = ref.current;

    return () => {
      if (currentIframe && currentIframe.contentDocument) {
        currentIframe.contentDocument.onmouseup = null;
      }
    };
  }, []);

  return (
    <div className='file-viewer file-viewer--html'>
      {isDownloading || isLoading || isError ? (
        <FileLoader loading={isDownloading || isLoading} error={isError} />
      ) : (
        <>
          <iframe title='Document view' src={documentUrl} ref={ref} onLoad={handleIframeLoad} />
          <ViewerToolbar
            handleZoom={handleZoom}
            handleSwitch={keyword ? handleSwitch : undefined}
          />
        </>
      )}
    </div>
  );
};
