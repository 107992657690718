import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { Paper } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useCustomPageLeavePrompt } from '../StaticComponents/Modals/ConfirmationModal/CustomPageLeavePrompt.hook';
import { TAB_NAMES } from '../../config/config';
import { RoutePath } from '../../services/route.service';
import { ClausesList } from '../Clauses/ClausesList';
import { DocumentsSearch } from '../Documents/DocumentsSearch/DocumentsSearch';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { SearchButton } from './SearchButton';
import { useSearchButtonHook } from './SearchButton.helpers';
import { PendingItemsListGrid } from './PendingItemsListGrid/PendingItemsListGrid';
import { PendingItemsListGridProps } from './PendingItemsListGrid/PendingItemsListGrid.types';
import { useGetPendingClausesListWithParams } from '../../store/files/clauses/clauses.hooks';
import { COLUMNS_DEFINITION } from './PendingItemsListGrid/PendingItemsListGrid.helpers';
import { useClauseWriteAccess } from '../../contexts/UserAccess';
import { BulkDeleteModal } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal';
import { useBulkDeleteACHClauses } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal.hooks';
import { DocumentsCount } from '../Documents/DocumentsCount';
import { UPLOADED_CLAUSES_CONFIG } from './UploadedClauses.helpers';

export const UploadedClauses = ({
  config: {
    useGetDataListWithParams,
    title,
    basePath,
    menuOptions,
    disablePending,
  } = UPLOADED_CLAUSES_CONFIG,
}) => {
  useClauseWriteAccess();
  const { filters, setFilters } = useGetDataListWithParams();
  const unsavedChanges = useAppSelector(uiSelectors.selectUnsavedChanges);
  useCustomPageLeavePrompt(unsavedChanges);
  const searchButtonProps = useSearchButtonHook();
  const { showSearch, isPendingTab } = searchButtonProps;

  const PendingItemsListGridOptions: PendingItemsListGridProps = {
    dataType: 'Clauses',
    rowIdKey: 'ClauseId',
    columnsDefinition: COLUMNS_DEFINITION('clause'),
    dataHook: useGetPendingClausesListWithParams,
  };

  return (
    <div className='my-files'>
      <Paper elevation={0} className='my-files-header'>
        <span className='my-files-title'>{title}</span>
        <div>
          <SearchButton {...searchButtonProps} />
          <BulkDeleteModal handler={useBulkDeleteACHClauses} disabled={isPendingTab} />
        </div>
      </Paper>

      {showSearch && (
        <DocumentsSearch
          filters={filters}
          onApply={setFilters}
          accessMode={AccessMode.WRITE}
          clauseMode
        />
      )}
      {!isPendingTab && (
        <DocumentsCount useGetDataListWithParams={useGetDataListWithParams} clauseMode />
      )}
      <Tabs
        base={basePath}
        components={[
          {
            label: TAB_NAMES.LIST_VIEW,
            url: RoutePath.UPLOADED_CLAUSES_LIST_VIEW,
            component: (
              <ClausesList
                menuOptions={menuOptions}
                useGetDataListWithParams={useGetDataListWithParams}
              />
            ),
          },
          {
            label: TAB_NAMES.PENDING,
            url: RoutePath.UPLOADED_CLAUSES_PENDING,
            component: (
              <Paper elevation={0} className='documents-list'>
                <PendingItemsListGrid {...PendingItemsListGridOptions} />
              </Paper>
            ),
            disabled: disablePending,
          },
        ]}
      />
    </div>
  );
};
