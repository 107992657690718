import {
  MetadataDictionary,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';
import { DocumentsListParams } from '../../../store/files/documents/documents.list.types';
import { DocumentsListFiltersProps } from '../Documents.types';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { ClausesListParams } from '../../../store/files/clauses/clauses.list.types';

export enum FirstParam {
  NONE = 'None',
  ALL = 'All',
}

export interface DocumentFilterProps {
  multiple?: boolean;
  label: string;
  value?: string | string[];
  onChange: (value?: string | string[]) => void;
  onClose?: (values: string | string[] | undefined) => void;
  onOpen?: () => void;
  values?: MetadataDictionaryValue[];
  isCountry?: boolean;
  firstParam?: FirstParam | null;
  disabled?: boolean;
  entityDictionary?: MetadataDictionary;
  isSearch?: boolean;
}

export type DocumentFilterLabelFunction = (value: string) => string;

export interface DocumentsSearchProps extends DocumentsListFiltersProps {
  onApply: (values: DocumentsListParams | ClausesListParams) => void;
  accessMode: AccessMode;
  clauseMode?: boolean;
  savedSearchHidden?: boolean;
}

export enum MENU_ITEM_GROUP {
  ALL = 'All',
  ALL_GERMAN = 'All German companies',
  NONE = 'None',
}

export type SetValueType = (name: string, value: string | string[] | undefined) => void;
