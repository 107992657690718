import { Controller } from 'react-hook-form';
import { DocumentFilter } from './DocumentFilter';
import React, { useState } from 'react';
import { ClauseSearchFieldsProps } from './SearchFilters.types';
import {
  filterClassificationsValues,
  filterLanguagesValues,
  getFilterPath,
} from './DocumentsSearch.helpers';
import { useGetClauseTagsQuery } from '../../../store/files/clauses/clauses.list.service';
import { onChange, onClose, onOpen } from './DocumentSearchFields.helpers';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { useNarrowDownClauseFilters } from './ClauseSearchFields.hooks';

export const ClauseSearchFields = ({
  dictionaries,
  filtersApplied,
  control,
  setValue,
  withPathPrefix,
}: ClauseSearchFieldsProps) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [lastSelected, setLastSelected] = useState<string | undefined>(undefined);

  const [entitiesValues, setEntitiesValues] = useState(dictionaries?.Entities.values);
  const [countriesValues, setCountriesValues] = useState<MetadataDictionaryValue[] | undefined>(
    () => undefined
  );
  const [lobsValues, setLobsValues] = useState<MetadataDictionaryValue[] | undefined>(undefined);
  const [lopsValues, setLopsValues] = useState<MetadataDictionaryValue[] | undefined>(undefined);
  const [businessTypes, setBusinessTypes] = useState<MetadataDictionaryValue[] | undefined>(
    undefined
  );

  const filteredLanguagesValues = filterLanguagesValues(dictionaries, filtersApplied, setValue);
  const filteredClassificationsValues = filterClassificationsValues(
    dictionaries,
    filtersApplied,
    setValue
  );
  const { data: clauseTags = [] } = useGetClauseTagsQuery();

  const onCloseField = onClose(setLastSelected, setIsDropDownOpen, lastSelected);
  const onOpenField = onOpen(setIsDropDownOpen);
  const onChangeField = onChange(setLastSelected);

  useNarrowDownClauseFilters({
    dictionaries,
    entitiesValues,
    filtersApplied,
    isDropDownOpen,
    lastSelected,
    setBusinessTypes,
    setCountriesValues,
    setEntitiesValues,
    setLobsValues,
    setLopsValues,
  });

  return (
    <>
      <Controller
        control={control}
        name={getFilterPath('Entities', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Company'
            values={entitiesValues}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
            entityDictionary={dictionaries?.Entities}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Countries', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Country'
            values={countriesValues}
            isCountry
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Lobs', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='LoB'
            values={lobsValues}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Lops', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='LoP'
            values={lopsValues}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('BusinessTypes', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Business Type'
            values={businessTypes}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('ClauseTypes', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Clause type'
            values={dictionaries?.ClauseTypes.values}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Classifications', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Classification'
            values={filteredClassificationsValues}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Language', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter {...field} multiple label='Language' values={filteredLanguagesValues} />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('ClauseSources', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Clause source'
            values={dictionaries?.ClauseSources?.values}
            isSearch={true}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('ClauseStatus', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            label='Status'
            values={dictionaries?.ClauseStatus.values}
            multiple
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Tag', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter multiple {...field} label='Tag' values={clauseTags} />
        )}
      />
      <Controller
        control={control}
        name={getFilterPath('Provider', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            multiple
            {...field}
            label='Provider'
            values={dictionaries?.Provider.values}
          />
        )}
      />
    </>
  );
};
