import DOMPurify from 'dompurify';
import { MIME_TYPES } from '../../../config/config';
import { SearchMethod } from '../../../store/files/documentsAndClauses/list.types';
import viewer from '../FileViewer/FileViewer.module.scss';
import { Order } from './useDocumentCompare';

const defaultStyles =
  '<style>span {z-index: -1;} div:empty {z-index: -1;} body { transform-origin: 0 0; }</style>';

enum MARK_STYLE_TYPE {
  DEFAULT = 'default',
  ADDED = 'added',
  REMOVED = 'removed',
}

const getMarkStyles = (type: MARK_STYLE_TYPE) => {
  const markBackgroundColor =
    type === MARK_STYLE_TYPE.DEFAULT
      ? viewer.markBackground
      : type === MARK_STYLE_TYPE.ADDED
      ? viewer.markBackgroundAddedChange
      : viewer.markBackgroundRemovedChange;

  if (type === MARK_STYLE_TYPE.DEFAULT) {
    return `<style>mark {background: ${markBackgroundColor};} mark.current {background: ${viewer.markCurrentBackground}; color: ${viewer.markCurrentColor};}</style>`;
  }
  return `<style>mark {background: ${markBackgroundColor};}</style>`;
};
const scrollWithShadowStyles =
  '<style>div:not(:empty) { overflow: auto; background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%; background-size: 100% 20px, 100% 20px, 100% 15px, 100% 15px; background-attachment: local, local, scroll, scroll; background-repeat: no-repeat; }</style>';

const EMPTY_CLASS = 'empty';
const highlight = '<mark>$1</mark>';

const addEmptyClassToEmptyElements = (node: Element) => {
  if (!node) return;

  if (['DIV', 'SPAN'].includes(node.tagName) && !node.textContent!.trim()) {
    node.classList.add(EMPTY_CLASS);
  } else {
    const children = node.children;
    for (let i = 0; i < children.length; i++) {
      addEmptyClassToEmptyElements(children[i]);
    }
  }
};

export const cleanupHtml = (input: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  addEmptyClassToEmptyElements(doc.documentElement);
  doc.documentElement.querySelectorAll(`.${EMPTY_CLASS}`).forEach((e) => e.remove());

  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};

const matchOutsideHtmlTags = '(?!([^<]+)?>)';

const searchHighlightDefault = (text: string, phrase: string) => {
  const keywords = phrase.split(' ');
  const regex = new RegExp(`(${keywords.join('|')})${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, highlight);
};

const searchHighlightExact = (text: string, phrase: string) => {
  const regex = new RegExp(`(\\b${phrase}\\b)${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, highlight);
};

export const searchHighlight = (text: string, phrase?: string, method?: SearchMethod) =>
  phrase
    ? method === SearchMethod.EXACT
      ? searchHighlightExact(text, phrase)
      : searchHighlightDefault(text, phrase)
    : text;

export const getSanitizedBlob = (
  html: string,
  keyword?: string,
  method?: SearchMethod,
  applyDifferencesOnHtml?: (html: string, order?: Order) => string,
  order?: Order,
  invertedCompare?: boolean
) => {
  const sanitized = DOMPurify.sanitize(html);
  const cleaned = cleanupHtml(sanitized);

  const sanitizedPreviewWithHighlights =
    applyDifferencesOnHtml && !keyword && !method
      ? applyDifferencesOnHtml(cleaned, order)
      : searchHighlight(cleaned, keyword, method);

  const typeOfMarkStyles =
    !applyDifferencesOnHtml || keyword
      ? MARK_STYLE_TYPE.DEFAULT
      : order === 0 && !invertedCompare
      ? MARK_STYLE_TYPE.REMOVED
      : MARK_STYLE_TYPE.ADDED;

  const out =
    sanitizedPreviewWithHighlights +
    defaultStyles +
    getMarkStyles(typeOfMarkStyles) +
    scrollWithShadowStyles;

  return new Blob([out], { type: MIME_TYPES.HTML.mimeType });
};
