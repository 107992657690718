import React from 'react';
import { AchDocumentViewerProps } from './DocumentViewer.types';
import { useGetDocumentFileQuery } from '../../../store/files/documents/documents.list.service';
import PdfViewer from '../../StaticComponents/FileViewer/PdfViewer';
import { HtmlViewer } from '../../StaticComponents/FileViewer/HtmlViewer';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import {
  DocumentFileResponseFields,
  OriginalLanguage,
} from '../../../store/files/documents/documents.list.types';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../store/hooks';

export const AchDocumentViewer = ({
  documentId = '',
  language,
  keyword,
  original,
  method,
  order,
}: AchDocumentViewerProps) => {
  const { data, isFetching, isError } = useGetDocumentFileQuery(documentId, {
    skip: !documentId,
  });
  const keywordOriginal = original === OriginalLanguage.ORIGINAL ? keyword : undefined;

  const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);
  const lockedLanguage = useAppSelector(uiSelectors.selectLockedLanguage);

  const languageToSwitch = isLanguageLocked && order !== undefined ? lockedLanguage : language;

  switch (languageToSwitch) {
    case LanguageToggleSwitchTypes.PDF: {
      const url = data?.[DocumentFileResponseFields.OriginalPdfUrl];
      return (
        <PdfViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keywordOriginal}
          method={method}
        />
      );
    }
    case LanguageToggleSwitchTypes.Original: {
      const url = data?.[DocumentFileResponseFields.OriginalHtmlUrl];
      return (
        <HtmlViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keywordOriginal}
          method={method}
          order={order}
          language={LanguageToggleSwitchTypes.Original}
        />
      );
    }
    case LanguageToggleSwitchTypes.English: {
      const url = data?.[DocumentFileResponseFields.TranslatedHtmlUrl];
      return (
        <HtmlViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={original !== OriginalLanguage.ORIGINAL ? keyword : undefined}
          method={method}
          order={order}
          language={LanguageToggleSwitchTypes.English}
        />
      );
    }
  }
};
