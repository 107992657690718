import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Backdrop, CircularProgress } from '@mui/material';
import common from '../../../assets/common.module.scss';
import { DocumentDownloadButtonProps } from './DocumentDownloadButton.types';
import Button from '@mui/material/Button';
import { useDocumentDownload } from '../DocumentContextMenu/DocumentContextMenuDownload.hooks';
import { DOWNLOAD_LABEL } from '../../../config/config';

export const DocumentDownloadButton = ({ document }: DocumentDownloadButtonProps) => {
  const { handleClick, inProgress } = useDocumentDownload(document);

  return (
    <>
      <Button
        variant='text'
        startIcon={<FileDownloadOutlinedIcon className='document-view-icons' />}
        onClick={handleClick}
      >
        {DOWNLOAD_LABEL}
      </Button>
      <Backdrop sx={{ color: common.white }} open={inProgress}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};
