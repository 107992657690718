import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../services/route.service';

type LocationState = {
  relatedDocuments?: string[];
  parentDocumentId?: string;
  fileName?: string;
};

export const useGetRelatedDocumentsFromLocationState = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const relatedDocuments = (state as LocationState)?.relatedDocuments;
  const parentDocumentId = (state as LocationState)?.parentDocumentId;
  const fileName = (state as LocationState)?.fileName;

  const clearLocationState = () => {
    if (parentDocumentId) {
      navigate(RoutePath.DOCUMENTS, {
        state: null,
      });
    }
  };

  return {
    parentDocumentId,
    relatedDocuments,
    fileName,
    clearLocationState,
  };
};
