import { SearchField } from '../../../StaticComponents/SearchField/SearchField';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from 'react';
import { ReactComponent as UnfoldIcon } from '../../../../assets/icons/unfold.svg';
import { SequentialSearchHookReturn } from './SequentialSearchFields.hook';
import './SequentialSearchFields.scss';

export const SequentialSearchFields = ({
  params: {
    addSearchQuery,
    addSearchQueryActive,
    collapsedItems,
    deleteSearchQuery,
    lastSearchQuery,
    searchQueriesCollapsed,
    selectedSearch,
    sequentialSearches,
    setSelectedSearch,
    setSsearchQueriesCollapsed,
    shouldCollapse,
    updateSearchQuery,
  },
}: {
  params: SequentialSearchHookReturn;
}) => {
  return (
    <div className='sequential-search-fields'>
      {(!shouldCollapse || (shouldCollapse && !searchQueriesCollapsed)) &&
        sequentialSearches.map(({ SearchQuery }, index) => (
          <SearchField
            key={index}
            selected={index === selectedSearch}
            searchQuery={SearchQuery || ''}
            setSearchQuery={(searchQuery) => updateSearchQuery(index, searchQuery)}
            onDelete={lastSearchQuery !== 0 ? () => deleteSearchQuery(index) : undefined}
            onClick={() => setSelectedSearch(index)}
          />
        ))}

      {shouldCollapse && searchQueriesCollapsed && (
        <SearchField
          selected={0 === selectedSearch}
          searchQuery={sequentialSearches[0].SearchQuery ?? ''}
          setSearchQuery={(searchQuery) => updateSearchQuery(0, searchQuery)}
          onDelete={() => deleteSearchQuery(0)}
          onClick={() => setSelectedSearch(0)}
        />
      )}

      {shouldCollapse && searchQueriesCollapsed && (
        <div
          onClick={() => setSsearchQueriesCollapsed(false)}
          className='sequential-search-fields__collapse'
        >
          Show hidden queries ({collapsedItems}) <UnfoldIcon />
        </div>
      )}

      {shouldCollapse && searchQueriesCollapsed && (
        <SearchField
          searchQuery={sequentialSearches[lastSearchQuery].SearchQuery ?? ''}
          setSearchQuery={(searchQuery) => updateSearchQuery(lastSearchQuery, searchQuery)}
          selected={lastSearchQuery === selectedSearch}
          onDelete={() => deleteSearchQuery(lastSearchQuery)}
          onClick={() => setSelectedSearch(lastSearchQuery)}
        />
      )}

      {shouldCollapse && !searchQueriesCollapsed && (
        <div
          onClick={() => setSsearchQueriesCollapsed(true)}
          className='sequential-search-fields__collapse'
        >
          Collapse queries ({collapsedItems}) <UnfoldIcon />
        </div>
      )}

      {addSearchQueryActive && (
        <Button
          size='small'
          variant='text'
          onClick={addSearchQuery}
          className='sequential-search-fields__add'
        >
          <AddCircleOutlineIcon /> Add search query
        </Button>
      )}
    </div>
  );
};
