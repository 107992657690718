import { MenuItem } from '@mui/material';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import { useAppDispatch } from '../../store/hooks';
import { openBookmarkModal } from '../../store/files/bookmarks/bookmarks.slice';
import { DocumentContextMenuItemProps } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import { useParams } from 'react-router-dom';

export const BookmarkContextMenuMove = ({ document, onClose }: DocumentContextMenuItemProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const handleBookmarkClick = () => {
    if (document) {
      dispatch(openBookmarkModal({ bookmark: document, currentBookmarkId: id }));
    }
    onClose();
  };
  return (
    <MenuItem onClick={handleBookmarkClick}>
      <OpenWithOutlinedIcon />
      Move
    </MenuItem>
  );
};
