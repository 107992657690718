import { getRoutePath, RoutePath } from '../../services/route.service';
import { getFilteredPages } from '../../store/ui/ui.helpers';

const documentBarAllowedPages = [
  RoutePath.DOCUMENTS,
  getRoutePath(RoutePath.UPLOADED_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW),
  getRoutePath(RoutePath.REPORTED_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW),
  getRoutePath(
    getRoutePath(RoutePath.BOOKMARKS, RoutePath.BOOKMARK_ID) as RoutePath,
    RoutePath.BOOKMARKS_DOCUMENTS
  ),
];
export const shouldShowSelectedBar = (pathname: string, minimizedDocsLength: number | undefined) =>
  getFilteredPages(documentBarAllowedPages, pathname) && !!minimizedDocsLength;
