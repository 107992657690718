import { Paper } from '@mui/material';
import { ClausesListProps } from './Clauses.types';
import { ClauseViewModal } from './ClauseViewModal/ClauseViewModal';
import { ClausesListGrid } from './ClausesListGrid';
import { ClauseCompareModal } from './ClauseCompareModal/ClauseCompareModal';
import { AchClauseMetadataEditModal } from '../UploadFiles/MetadataEditModal/AchClauseMetadataEditModal';

export const ClausesList = ({ menuOptions, useGetDataListWithParams }: ClausesListProps) => {
  return (
    <Paper elevation={0} className='documents-list'>
      <ClausesListGrid
        menuOptions={menuOptions}
        useGetDataListWithParams={useGetDataListWithParams}
      />
      <AchClauseMetadataEditModal useGetDataListWithParams={useGetDataListWithParams} />
      <ClauseViewModal useGetDataListWithParams={useGetDataListWithParams} />
      <ClauseCompareModal />
    </Paper>
  );
};
