import IconButton from '@mui/material/IconButton';
import { NavigateBefore, NavigateNext, ZoomIn, ZoomOut } from '@mui/icons-material';
import React from 'react';
import { ViewerToolbarProps } from './ViewerToolbar.types';

export const ViewerToolbar = ({ handleZoom, handleSwitch }: ViewerToolbarProps) => {
  return (
    <div className='file-viewer--navigation'>
      {handleZoom ? (
        <>
          <IconButton onClick={() => handleZoom(-1)}>
            <ZoomOut />
          </IconButton>
          <IconButton onClick={() => handleZoom()}>
            <ZoomIn />
          </IconButton>
        </>
      ) : null}

      {handleSwitch ? (
        <>
          <IconButton onClick={() => handleSwitch(-1)}>
            <NavigateBefore />
          </IconButton>
          <IconButton onClick={() => handleSwitch()}>
            <NavigateNext />
          </IconButton>
        </>
      ) : null}
    </div>
  );
};
